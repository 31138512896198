import { Button, MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import ReactJson from "react-json-view";
import { settingsApi } from "../../utils/api";

const JsonEditor = ({ initialData, onSave }) => {
  const [jsonData, setJsonData] = useState(initialData);

  const getJsonSections = (data) => {
    const names = [];
    data.forEach((item) => {
      const n = item.name;
      names.push(n);
    });
    const uniqueNames = Array.from(new Set(names));
    return uniqueNames;
  };

  const sections = getJsonSections(initialData);
  const [section, setSection] = React.useState(sections[0]);
  const [displayedSection, setDisplayedSection] = React.useState(null);
  const handleSectionChange = (event) => {
    setSection(event.target.value);
  };

  React.useEffect(() => {
    const filteredData = jsonData.filter((item) => item.name === section);
    setDisplayedSection(filteredData);
    console.log("filteredData", filteredData);
  }, [section, jsonData]);

  console.log(getJsonSections(initialData));

  const handleEdit = (edit) => {
    console.log("edit", edit);
    if (edit.updated_src) {
      setJsonData(edit.updated_src); // Update the state with the edited JSON
      const newData = edit.updated_src[0].data;
      const config_id = edit.updated_src[0].id;
      settingsApi.updConfig({
        config_id,
        data: newData,
        business: false,
      });
    }
  };

  const handleSave = () => {
    onSave(jsonData); // Pass the edited data to the parent or server
  };

  return (
    <div>
      <div>
        <Select value={section} onChange={handleSectionChange} size="small">
          {sections.map((sec) => (
            <MenuItem key={sec} value={sec}>
              {sec}
            </MenuItem>
          ))}
        </Select>
      </div>
      <ReactJson
        src={displayedSection}
        onEdit={handleEdit}
        onAdd={handleEdit}
        onDelete={handleEdit}
        theme="monokai" // Optional: Choose a theme
        enableClipboard
        displayDataTypes={false}
      />
      {/* <Button variant="contained" onClick={handleSave}>
        Сохранить изменения
      </Button> */}
    </div>
  );
};

export default JsonEditor;
