import { groupsApi } from "../../utils/api";
import notification from "../../utils/notification/notification";
import { addEntity } from "./entitiesTypesActions";

export const getGroups = (data) => ({
  type: "GET_GROUPS",
  payload: data,
  loading: false,
});

export const loadingGroups = () => ({
  type: "LOADING_GROUPS",
  loading: true,
});

export const addGroup = (data) => ({
  type: "ADD_GROUP",
  payload: data,
  loading: false,
});

export const setUpdatedGroup = (data) => ({
  type: "UPDATE_GROUP",
  payload: data,
  loading: false,
});

export const deleteGroup = (id) => ({
  type: "REMOVE_GROUP",
  payload: id,
  loading: false,
});

export const clearGroups = () => ({
  type: "CLEAR_GROUPS",
});

export const fetchGroups = () => (dispatch) => {
  dispatch(loadingGroups());
  return groupsApi.get().then(({ data }) => dispatch(getGroups(data.groups)));
};

export const addNewGroup = (obj) => (dispatch) => {
  dispatch(loadingGroups());
  return groupsApi.add(obj).then(({ data }) => {
    notification.success("Группа добавлена");
    console.log('data.group',data.group);
    dispatch(addGroup(data.group));
    const newGroup = data.group;
    dispatch(addEntity("group", { value: newGroup.id, label: newGroup.short_name }));
  });
};

export const updateGroup = (obj) => (dispatch) => {
  dispatch(loadingGroups());
  return groupsApi.update(obj).then(({ data }) => {
    notification.success("Группа изменена");
    dispatch(setUpdatedGroup(data.group));
  });
};

export const removeGroup = (idObj) => (dispatch) => {
  dispatch(loadingGroups());
  return groupsApi.remove(idObj).then(() => {
    notification.success("Группа удалена");
    dispatch(deleteGroup(idObj.group));
  });
};

//
