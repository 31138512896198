import { Backdrop, CircularProgress } from "@mui/material";
import React from "react";
import { useSelector } from "react-redux";
import CRMTabs from "../../components/TabsComponent/CRMTabs";
import PaymentsPage from "./PaymentsPage";
import CostsPage from "../Costs/CostsPage";

const PaymentsContainer = () => {
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  // TODO remove from PaymentsPage and CostsPage useEffects to this container
  // TODO and pass data to children

  return (
    <>
      {/* <div className="content-component__wrapper"> */}

      {appGlobalState.loading ? (
        <Backdrop open={!appGlobalState.loading} sx={backdropStyles}>
          <CircularProgress />{" "}
        </Backdrop>
      ) : (
        <CRMTabs>
          <PaymentsPage value="1" label="Платежи" key="1" />
          <CostsPage value="2" label="Затраты" key="2" />
        </CRMTabs>
      )}
      {/* </div> */}
    </>
  );
};

export default PaymentsContainer;
