/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";

import { Avatar, Box, TextField, Typography } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { Person } from "@mui/icons-material";
import googleLogo from "../../../assets/img/googleLogo.svg";
import "../../../scss/LoginForm.scss";

const LoginForm = (props) => {
  // eslint-disable-next-line react/destructuring-assignment
  console.log("error", props.errors);
  const {
    values,
    errors,
    touched,
    handleChange,
    handleBlur,
    handleSubmit,
    isSubmitting,
  } = props;

  return (
    <Box
      component="form"
      sx={{
        position: "absolute",
        top: "35%",
        left: "50%",
        transform: "translate(-50%, -50%)",
        maxWidth: 320,
        width: "100%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        boxSizing: "border-box",
        padding: 5,
        border: "1px solid #d9d9d9",
        borderRadius: 2,
        backgroundColor: "#fff",
        "& .MuiTextField-root": { mb: 5 },
      }}
      onSubmit={handleSubmit}
    >
      <Avatar sx={{ bgcolor: "primary.main" }}>
        <Person />
      </Avatar>
      <Typography
        variant="h4"
        component="h4"
        mt={4}
        mb={4}
        textAlign="center"
        fontWeight="bold"
      >
        Вход
      </Typography>

      {window.clientConfig.stage === "v1" && (
        <>
          <TextField
            id="email"
            type="email"
            size="small"
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!(touched.email && errors.email)}
            helperText={
              errors.email ? errors.email : "Введите email своей учетной записи"
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            fullWidth
          >
            Войти
          </LoadingButton>
          <a
            className="google-btn"
            href="https://dev.stemlab.school/api/v1/google_auth"
          >
            <div className="google-icon-wrapper">
              <img className="google-icon" alt="googleIcon" src={googleLogo} />
            </div>
            <p className="btn-text">
              <b>Войти через Google</b>
            </p>
          </a>
        </>
      )}
      {window.clientConfig.stage === "v2" && (
        <>
          <a
            className="google-btn"
            href="https://stemlab.school/api/v2/google_auth"
          >
            <div className="google-icon-wrapper">
              <img className="google-icon" alt="googleIcon" src={googleLogo} />
            </div>
            <p className="btn-text">
              <b>Войти через Google</b>
            </p>
          </a>
        </>
      )}

      {window.clientConfig.stage === "qa" && (
        <>
          <TextField
            id="email"
            type="email"
            size="small"
            label="Email"
            variant="outlined"
            margin="normal"
            fullWidth
            error={!!(touched.email && errors.email)}
            helperText={
              errors.email ? errors.email : "Введите email своей учетной записи"
            }
            onChange={handleChange}
            onBlur={handleBlur}
            value={values.email}
          />
          <LoadingButton
            type="submit"
            variant="contained"
            loading={isSubmitting}
            fullWidth
          >
            Войти
          </LoadingButton>

          <a
            className="google-btn"
            href="https://pp.stemlab.school/api/v2/google_auth"
          >
            <div className="google-icon-wrapper">
              <img className="google-icon" alt="googleIcon" src={googleLogo} />
            </div>
            <p className="btn-text">
              <b>Войти через Google</b>
            </p>
          </a>
        </>
      )}
    </Box>
  );
};

export default LoginForm;
