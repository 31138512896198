/* eslint-disable array-callback-return */
const initialState = {
  payload: [],
  loading: false,
};

const attendanceReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ATTENDANCE":
      return {
        ...state,
        payload: action.payload,
        loading: action.loading,
      };
    case "LOADING_ATTENDANCE":
      return {
        ...state,
        loading: action.loading,
      };

    case "SET_INITIAL_SELECTS_VALUES":
      return {
        ...state,
        group_id: action.payload.group_id,
        lesson_id: action.payload.data[0].lesson_id,
      };
    case "UPDATE_ATTENDANCE":
      return {
        ...state,
        payload: state.payload.map((item) => {
          if (item.group_id === action.payload.group_id) {
            item.data.map((inside) => {
              if (inside.topic === action.payload.data[0].topic) {
                inside.students = [...action.payload.data[0].students];
              }
            });
          }
          return item;
        }),
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default attendanceReducer;
