import { costsApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

export const getCosts = (data) => ({
	type: "GET_COSTS",
	payload: data,
	loading: false,
});

export const addCost = (data) => ({
	type: "ADD_COST",
	payload: data,
	loading: false,
});

export const updCost = (data) => ({
	type: "UPDATE_COST",
	payload: data,
	loading: false,
});
export const deleteCost = (data) => ({
	type: "REMOVE_COST",
	payload: data,
	loading: false,
});

export const loadingCosts = () => ({
	type: "LOADING_COSTS",
	loading: true,
});



export const fetchCosts = () => (dispatch) => {
	dispatch(loadingCosts());
	costsApi.getCosts().then(({ data }) => {
		dispatch(getCosts(data));
	});
};

export const postCost = (costs) => (dispatch) => {
	dispatch(loadingCosts());
	costsApi.addCost(costs).then(({ data }) => {
		dispatch(addCost(data));
		notification.success("Таблица затрат успешно обновлена");
		return data;
	});
};

export const updateCost = (cost) => (dispatch) => {
	dispatch(loadingCosts());
	costsApi.updateCost(cost).then(({ data }) => {
		dispatch(updCost(data.cost));
		notification.success("Таблица затрат успешно обновлена");
		return data;
	});
};

export const removeCost = (costId) => (dispatch) => {
	dispatch(loadingCosts());
	costsApi.removeCost(costId).then(({ data }) => {
		dispatch(deleteCost(costId.cost));
		notification.success("Таблица затрат успешно обновлена");
		return data;
	});
};

