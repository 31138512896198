const initialState = {
  loading: true,
  editableEntities: [],
};

// the application has Reducer entityTypes:
//  {EntitiyTypes:  {category: {value, label}, teachers: {value, label}...., editableEntities: {category: {id,type,value}...},   }

const entitiesTypesReducer = (state = {}, action) => {
  switch (action.type) {
    case "SET_UNIQUE_TYPES":
      return {
        ...state,
        ...action.payload,
        editableEntities: state.editableEntities,
      };
    case "SET_EDITABLE_ENTITIES":
      return {
        ...state,
        editableEntities: action.payload,
      };


    case "ADD_ENTITY":
      console.log('action.payload', action.payload);
      return {
        ...state,
        [action.payload.entityType]: [
          ...state[action.payload.entityType],
          { value: action.payload.entity.id, label: action.payload.entity.value },
        ],
        editableEntities: {
          ...state.editableEntities,
          [action.payload.entityType]: [
            ...(state.editableEntities[action.payload.entityType] || []),
            {
              ...action.payload.entity
            }
          ]

        }
      };

    case "UPDATE_ENTITY":
      console.log('action.payload', action.payload);
      return {
        ...state,
        [action.payload.entityType]: state[action.payload.entityType].map(item =>
          item.value === action.payload.entity.id
            ? { ...item, label: action.payload.entity.value }
            : item
        ),
        editableEntities: {
          ...state.editableEntities,
          [action.payload.entityType]: state.editableEntities[action.payload.entityType].map(item =>
            item.id === action.payload.entity.id
              ? { ...item, value: action.payload.entity.value }
              : item
          ),
        },
      };

    default:
      return state;
  }
};
export default entitiesTypesReducer;
