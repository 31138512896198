import {
  Button,
  ClickAwayListener,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import React from "react";

const CreateConfirmDialog = (props) => {
  const { name, open, setOpen, del, save, error, setError, disableActionBtns, setDisableActionBtns} = props;

  const handleClickAway = (event) => {
    if (open && !event.target.closest('.MuiDialog-root')) {
      setOpen(false);
    };
  };

  const cleanProps = () => {
    setError(false);
  };
  return (
    <ClickAwayListener onClickAway={handleClickAway}>
    <Dialog
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">Создание договора <span style={{float:"right", cursor:"pointer", background:"#c0c0c0" , padding: "0 5px"}} onClick={() => {cleanProps();setOpen(false); setDisableActionBtns(false)}} >Закрыть</span> </DialogTitle>
      {error ? (
        <DialogContent>
          Произошла ошибка. договор на имя {name} не создан.
          <div style={{color: "#FF0000"}} >{error}</div>  
        </DialogContent>
      ) : (
        <DialogContent>договор на имя {name} создан</DialogContent>
      )}
      <DialogActions>
        <Button
         disabled= {Boolean(error) || disableActionBtns}
          color="error"
          variant="contained"
          startIcon={<DeleteIcon />}
          onClick={() => {
            del();
          }}
        >
          Удалить договор
        </Button>
        <Button
         disabled= {Boolean(error) || disableActionBtns}
          variant="contained"
          startIcon={<SaveIcon />}
          onClick={() => {
            save();
          }}
        >
          Скачать договор
        </Button>
      </DialogActions>
    </Dialog>
    </ClickAwayListener>
  );
};

export default CreateConfirmDialog;
