export const loading = () => ({
  type: "LOADING",
});
export const loaded = () => ({
  type: "LOADED",
});
export const hideSidebarAction = (data) => ({
  type: "HIDE_SIDEBAR_ACTION",
  data,
});

export const setTitle = (title) => ({
  type: "SET_PAGE_TITLE",
  payload: title,
  loading: false,
});

export const setSpreadSearch = (tab) => ({
  type: "SET_SPREAD_SEARCH",
  payload: tab,
  loading: false,
});

export const setPageTitle = (title) => (dispatch) => 
   dispatch(setTitle(title))
;
export const setSpreadSearchTab = (tab) => (dispatch) =>
  dispatch(setSpreadSearch(tab));
