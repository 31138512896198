/* eslint-disable import/prefer-default-export */

// finds the label from the entity by ID
export const findLabelByID = (id, entitie) => {
  if (typeof id === "number") {
    id = id.toString();
  }
  if (!id) return "id: пустая строка ";
  if(!entitie) return "нет entitie";
  const user = entitie.find((item) => item.value === id);
  if (!user) return `нет в entities, id: ${id}`;
  return user?.label;
};
