import * as React from "react";
import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { useSelector } from "react-redux";
import "./CRMTabs.scss";

export default function CRMTabs({ children }) {
  // console.log("children", children);
  const [value, setValue] = React.useState("1");

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const global = useSelector(({ globalState }) => globalState);
  React.useEffect(() => {
    if (global.tab === "3") {
      setValue(global.tab);
    }
  }, [global.tab]);
  return (
    <Box sx={{ width: "100%", typography: "body1" }}>
      <TabContext value={value}>
        <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
          <TabList onChange={handleChange} aria-label="lab API tabs example">
            {children.map((child) => (
              <Tab
                defaultValue="1"
                label={child.props?.label}
                value={child.props?.value}
                key={child.props?.value}
              />
            ))}
          </TabList>
        </Box>
        {children.map((child) => (
          <TabPanel value={child.props?.value} key={child.props?.value}>
            {child}{" "}
          </TabPanel>
        ))}
      </TabContext>
    </Box>
  );
}
