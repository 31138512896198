const initialState = {
  payload: [],
  loading: false,
};

const accessReducer = (state = initialState, action) => {
  switch (action.type) {
    case "GET_ACCESS":
      return {
        ...state,
        payload: action.payload,
        loading: action.loading,
      };
    case "LOADING_ACCESS":
      return {
        ...state,
        loading: action.loading,
      };
    case "UPDATE_ACCESS":
      return {
        ...state,
        payload: state.payload.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        loading: action.loading,
      };
    case "ADD_USER":
      return {
        ...state,
        payload: [...state.payload, action.payload],
        loading: action.loading,
      };
    case "DISABLE_USER":
      console.log("DISABLE_USER", action.payload);
      return {
        ...state,
        payload: state.payload.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        loading: action.loading,
      };
    case "ACTIVATE_USER":
      console.log("ACTIVATE_USER", action.payload);
      return {
        ...state,
        payload: state.payload.map((item) =>
          item.id === action.payload.id ? action.payload : item
        ),
        loading: action.loading,
      };
    // case "DISABLE_USER":
    // 	console.log("DISABLE_USER", action.payload);
    // 	return {
    // 		...state,
    // 		payload: state.payload.filter((item) => item.id !== action.payload.id),
    // 		loading: action.loading,
    // 	};
    case "CLEAR_ACCESS":
      return initialState;
    default:
      return state;
  }
};

export default accessReducer;
