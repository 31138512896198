import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import CreatingCustomersView from "../../components/CreatingCustomersView/CreatingCustomersView";
import {
  fetchCustomers,
  postCustomer,
  removeCustomer,
  updateCustomer,
} from "../../redux/actions/creatingCustomersActions";
import "../../scss/index.scss";
import { setPageTitle } from "../../redux/actions/globalActions";

function CreatingCustomers({ loading, getCustomers, title, setTitle, ...props }) {
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  useEffect(() => {
    getCustomers();
  }, []);

  useEffect(() => {
    setTitle(title);
  }, []);
  return (
    <>
      <div className="content-component__wrapper">
        <h4 className="table__title">{title}</h4>

        {loading ? (
          <Backdrop open={!appGlobalState.loading} sx={backdropStyles}>
            <CircularProgress />{" "}
          </Backdrop>
        ) : (
          <CreatingCustomersView {...props} />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.customers.loading,
  data: state.customers.payload,

  globalState: state.globalState,
  entitiesTypes: state.entitiesTypes,
  permissions: {
    add: false,
    modify: false,
    // modify: state.user.permissions.includes("topic.modify"),
    remove: true,
  },
});

const mapDispatchToProps = (dispatch) => ({
  getCustomers: () => {
    dispatch(fetchCustomers());
  },
  addCustomer: async (data) => {
    await dispatch(postCustomer(data));
  },
  updateData: (obj) => {
    dispatch(updateCustomer(obj));
  },
  removeData: (id) => {
    dispatch(removeCustomer(id));
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(CreatingCustomers);
