import React from "react";
import "./MaintenancePage.scss"; // Import the CSS file for styles

const MaintenancePage = () => (
  <div className="maintenance-container">
    <h1 className="maintenance-heading">
      Ведутся работы по техническому обслуживанию
    </h1>
    <p className="maintenance-message">
      В данный момент проводятся технические работы. Пожалуйста проверьте еще
      раз через некоторое время.
    </p>
  </div>
);

export default MaintenancePage;
