import { lessonsApi } from "../../utils/api";
import notification from "../../utils/notification/notification";
import groupAdap from "./adap";

export const setLessons = (data) => ({
  type: "SET_LESSONS",
  payload: data,
  loading: false,
});

export const postLesson = (data) => ({
  type: "POST_LESSON",
  payload: data,
  loading: false,
});

export const updateLesson = (data) => ({
  type: "UPDATE_LESSON",
  payload: data,
  loading: false,
});

export const loadingLessons = () => ({
  type: "LOADING_LESSONS",
  loading: true,
});

export const updateAttachments = (data) => ({
  type: "UPDATE_ATTACHMENTS",
  payload: data,
  loading: false,
});
export const deleteAttachment = (data) => ({
  type: "REMOVE_ATTACHMENT",
  payload: data,
  loading: false,
});

export const setLessonsToState = () => (dispatch) => {
  dispatch(loadingLessons());
  lessonsApi.get().then((data) => {
    dispatch(setLessons(data.data.lesson));
  });
};

export const postSelectedLesson = (obj) => (dispatch) => {
  dispatch(loadingLessons());
  lessonsApi
    .add({ ...obj })
    .then(({ data }) => {
      dispatch(postLesson(data.lesson));
      notification.success("Данные отправлены");
    })
    .catch((err) => alert(err.response.data.message));
};

export const updateSelectedLesson = (obj) => (dispatch) => {
  dispatch(loadingLessons());
  lessonsApi
    .update({ ...obj })
    .then(({ data }) => {
      dispatch(updateLesson(data.lesson));
      notification.success("Данные отправлены");
    })
    .catch((err) => alert(err.response.data.message));
};
