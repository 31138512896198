import React from "react";
import { FormControl, MenuItem, OutlinedInput, Select } from "@mui/material";

function Multiselect({ options, id, field, api, ...props }) {
	const [selectValue, setSelectValue] = React.useState([...props.value]);

	const onChangeHandler = (event) => {
		const {
			target: { value },
		} = event;

		api.setEditCellValue({ id, field, value }, event);
		setSelectValue(
			// On autofill we get a stringified value.
			typeof value === "string" ? value.split(",") : value
		);
	};
	const style = {
		"& .MuiSelect-multiple": {
			padding: "5px 12px",
		},
	};
	return (
		<FormControl fullWidth>
			<Select
				multiple
				sx={style}
				value={selectValue}
				onChange={onChangeHandler}
				input={<OutlinedInput />}
			>
				{options.map(({ value, label }) => (
					<MenuItem key={value} value={value}>
						{label}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
}

export default Multiselect;
