const initialState = {
	payload: [],
	confirm: [],
	loading: false,
	modal: false,
};




// sorted response abjects by unique names
// const sortByGroupName = (source) => {
// 	const uniqueNames = [...new Set(source.map(group => group.name_group))];
// 	const groupsUpd = [
// 		{
// 			id: null,
// 			name: null,
// 			students: []
// 		}
// 	];

// 	for (let i = 0; i < uniqueNames.length; i++) {
// 		for (let g = 0; g < source.length; g++) {
// 			if (uniqueNames[i] === source[g].name_group) {
// 				if (!groupsUpd[i]) {
// 					groupsUpd.push({
// 						id: null,
// 						name: null,
// 						students: []
// 					});
// 				}
// 				const studentInfo = {
// 					id: source[g].id_students,
// 					name: source[g].student_name
// 				};
// 				groupsUpd[i].id = source[g].new_group;
// 				groupsUpd[i].name = source[g].name_group;
// 				groupsUpd[i].students.push(studentInfo);
// 			}
// 		}
// 	}
// 	return groupsUpd;
// };


// sorted response abjects by unique names

// const sortByGroupName = (source) => {
// 	const uniqueNames = [...new Set(source.map(group => group.name_group))];
// 	const groupsUpd = [
// 		{
// 			id: null,
// 			name: null,
// 			students: []
// 		}
// 	];
// 	uniqueNames.map((name, index) => {
// 		source.map((item) => {
// 			if (name === item.name_group) {
// 				if (!groupsUpd[index]) {
// 					groupsUpd.push({
// 						id: null,
// 						name: null,
// 						students: []
// 					});
// 				}

// 				groupsUpd[index].id = item.new_group;
// 				groupsUpd[index].name = item.name_group;
// 				groupsUpd[index].students.push({
// 					id: item.id_students,
// 					name: item.student_name
// 				});

// 			}
// 			return groupsUpd;
// 		});
// 		return groupsUpd;
// 	});
// 	return groupsUpd;
// };


const sortByGroupName = (source) => {
	const uniqueNames = [...new Set(source.map(group => group.name_group))];

	const groupsUpd = [
		{
			id: null,
			name: null,
			students: []
		}
	];

	uniqueNames.map((name, index) => {
		source.map((item) => {
			if (name === item.name_group) {
				if (!groupsUpd[index]) {
					groupsUpd.push({
						id: null,
						name: null,
						students: []
					});
				}

				groupsUpd[index].id = item.new_group;
				groupsUpd[index].name = item.name_group;
				groupsUpd[index].students.push({
					id: item.id_students,
					name: item.student_name
				});

			}
			return groupsUpd;
		});
		return groupsUpd;
	});
	return groupsUpd;
};


const sortIsPupilTransfer = (sortArr, data) => {
	const sorted = [];
	sortArr.map((student) => {
		if (student.old_group !== student.new_group) {
			sorted.push(student);
		}
		return sorted;
	});

	data.forEach((d) => {
		sorted.map((s) => {
			if (d.id === s.old_group) {
				s.old_group_name = d.short_name;
			}
			return sorted;
		});
		return sorted;
	});

	return sorted;
};


const studentsKanbanReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_STUDENTS":
			return {
				...state,
				payload: action.payload,
				loading: action.loading,
			};
		case "LOADING_STUDENTS":
			return {
				...state,
				loading: action.loading,
			};
		case "GET_CONFIRM_DATA":
			return {
				...state,
				confirm: sortByGroupName(action.payload),
				transfer: sortIsPupilTransfer(action.payload, state.payload),
				modal: action.modal,
				loading: action.loading,
			};
		case "MODAL_IS_OPEN":
			return {
				...state,
				modal: true,
			};
		case "MODAL_CLOSE":
			return {
				...state,
				modal: false,
			};
		case "UPDATE_STUDENTS":
			return {
				...state,
				payload: state.payload.map((item) => {
					sortByGroupName(action.payload).map((group) => {
						if (group.id === item.id) { item.students = group.students; }
						return item;
					});
					return item;
				}),
				loading: action.loading,
			};
		default:
			return state;
	}
};

export default studentsKanbanReducer;
