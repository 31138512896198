import axios from "../../config/axiosConfig";

export default {
	get: () => axios.get(`/get/access`),
	post: (data) => axios.post(`/update/access`, data),
	add: (data) => axios.post(`/add/user`, data),
	checkDebts: (id) => axios.get(`/user/obligation?user=${id}`),
	disabled: (id) => axios.post(`/user/disable`, id),
	activate: (id) => axios.get(`/user/activate?user=${id}`, id),
};
