import React from "react";
import "../scss/Sidebar.scss";
import {
  BsChevronDoubleLeft,
  BsChevronDoubleRight,
  BsPlus,
  BsDash,
} from "react-icons/bs";
import { NavLink } from "react-router-dom";
import { useDispatch } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import logo from "../assets/img/logo.png";
import SidebarButton from "./SidebarButton";

import { hideSidebarAction } from "../redux/actions/globalActions";

const Sidebar = React.memo(({ config, prefix }) => {
  const dispatch = useDispatch();
  const dimension = window?.innerWidth;
  const [hideSidebar, setToggle] = React.useState(false);
  const [openGroups, setOpenGroups] = React.useState({});

  const handleHideSidebar = () => {
    setToggle(!hideSidebar);
    dispatch(hideSidebarAction(!hideSidebar));
  };

  const toggleGroup = (groupTitle) => {
    setOpenGroups((prevOpenGroups) => ({
      ...prevOpenGroups,
      [groupTitle]: !prevOpenGroups[groupTitle],
    }));
  };

  const renderNavLink = (item) => (
    <NavLink
      to={`${prefix}${item.path?.replace(/[:].*/, "")}`} // remove route params
      activeClassName="active-navlink"
      className="sidebar__navlink"
      key={`${item.path}_`}
    >
      <SidebarButton
        iconName={item.icon ? item.icon : null}
        key={`${item.title}_`}
      >
        {item.title}
      </SidebarButton>
    </NavLink>
  );

  return (
    <div className={hideSidebar ? "sidebar" : "sidebar show"}>
      <div className="sidebar__chevron" onClick={handleHideSidebar}>
        {hideSidebar ? (
          <BsChevronDoubleRight size={18} />
        ) : (
          <BsChevronDoubleLeft size={18} />
        )}
      </div>

      <div className="sidebar__wrapper">
        <img src={logo} alt="STEMLAB" className="sidebar__logo" />

        <div className="sidebar__wrapper_overflow_layer">
          {config.map((item) =>
            item.children ? (
              <div key={uuidv4()} className="sidebar__group">
                <div
                  onClick={() => toggleGroup(item.title)}
                  className="sidebar__group-title"
                >
                  <SidebarButton iconName={item.icon ? item.icon : null}>
                    {item.title}
                  </SidebarButton>
                  <span className="sidebar__group-icon">
                    {openGroups[item.title] ? (
                      <BsDash fontSize="16px" />
                    ) : (
                      <BsPlus />
                    )}
                  </span>
                </div>
                {openGroups[item.title] && (
                  <div className="sidebar__group-items">
                    {item.children.map((child) => renderNavLink(child))}
                  </div>
                )}
              </div>
            ) : (
              <span key={uuidv4()}>{renderNavLink(item)}</span>
            )
          )}
        </div>
      </div>
    </div>
  );
});

//   return (
//     <div className={hideSidebar ? "sidebar" : "sidebar show"}>
//       <div className="sidebar__chevron" onClick={handleHideSidebar}>
//         {hideSidebar ? (
//           <BsChevronDoubleRight size={18} />
//         ) : (
//           <BsChevronDoubleLeft size={18} />
//         )}
//       </div>

//       <div className="sidebar__wrapper">
//         {window.clientConfig.stage === "pp." ? (
//           <img
//             src={logo}
//             alt="STEMLAB"
//             className="sidebar__logo"
//             style={{
//               filter:
//                 "invert(21%) sepia(94%) saturate(6742%) hue-rotate(358deg) brightness(103%) contrast(110%)",
//               transition: "3s",
//               animationDuration: "2s",
//               animationName: "slidein",
//               animationIterationCount: "3",
//             }}
//           />
//         ) : (
//           <img src={logo} alt="STEMLAB" className="sidebar__logo" />
//         )}
//         <div className="sidebar__wrapper_overflow_layer">
//           {config.map((item) => (
//             <span
//               onClick={dimension < 768 ? handleHideSidebar : undefined}
//               key={uuidv4()}
//             >
//               <NavLink
//                 to={`${prefix}${item.path?.replace(/[:].*/, "")}`} // remove route params
//                 activeClassName="active-navlink"
//                 key={`${item.path}_`}
//               >
//                 <SidebarButton iconName={item.icon} key={`${item.title}_`}>
//                   {item.title}
//                 </SidebarButton>
//               </NavLink>
//             </span>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// });

export default Sidebar;
