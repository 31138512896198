/* eslint-disable consistent-return */
/* eslint-disable eqeqeq */
/* eslint-disable no-shadow */
/* eslint-disable react/no-this-in-sfc, func-names, no-unused-vars */
import PivotTable from "react-pivottable/PivotTable";
// import "gridjs/dist/theme/mermaid.css";
import "../../scss/table.scss";
import React, { useEffect } from "react";
import MonthPicker from "react-month-picker";
import "react-month-picker/scss/month-picker.scss";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import {
  Box,
  Backdrop,
  CircularProgress,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  FormGroup,
} from "@mui/material";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import { setWeek } from "date-fns";

import { LocalDiningRounded } from "@mui/icons-material";
import {
  //   fetchWeekReport,
  fetchLocationReport,
  fetchPayByDateByCityReport,
  fetchCampReport,
  fetchFirstVisitReport,
} from "../../redux/actions/reportActions";
import report from "../../utils/api/report";
import { reportApi } from "../../utils/api";
import ReportAcademicYear from "./ReportAcademicYear";

function ReportsPage({ title }) {
  const dispatch = useDispatch();

  const [settings, setSettings] = React.useState({});

  const fetchFromTab = {
    // 1: fetchWeekReport(),
    2: fetchLocationReport(),
    3: fetchPayByDateByCityReport(),
    // 4: fetchCampReport(),
    // 5: fetchFirstVisitReport(),
  };

  const initialTab = "2";

  const [value, setValue] = React.useState(initialTab);

  const handleChangeTab = (event, newValue) => {
    dispatch(fetchFromTab[newValue]);
    setValue(newValue);
  };

  React.useEffect(() => {
    dispatch(fetchFromTab[initialTab]);
  }, []);

  const weekReportData = useSelector(({ report }) => report.new);
  const locationReportData = useSelector(({ report }) => report.ongoing);
  const payByDateByCityReportData = useSelector(
    ({ report }) => report.payByDayAndByCity
  );
  const campReportData = useSelector(({ report }) => report.camp);
  const firstVisitReportData = useSelector(({ report }) => report.firstVisit);
  const reportsLoading = useSelector(({ report }) => report.loading);

  const weekReportDataCopy = JSON.parse(JSON.stringify(weekReportData));
  console.log(weekReportData);
  const locationReportDataCopy = JSON.parse(JSON.stringify(locationReportData));
  const firstVisitReportDataCopy = JSON.parse(
    JSON.stringify(firstVisitReportData)
  );
  const campReportDataCopy = JSON.parse(JSON.stringify(campReportData));

  // Checkbox controls

  const sortByType = (source, courseType) => {
    if (!source) {
      return;
    }
    console.log("source", source);
    return source.filter((item) => item.course_type === courseType);
  };

  const [weekReportSorted, setWeekReports] = React.useState(null);

  const initCheckboxState = {
    лагерь: false,
    индивидуальные: false,
    учебный_год: false,
  };
  const [state, setState] = React.useState(initCheckboxState);

  const handleChangeCheckBox = (event) => {
    console.log(event.target.checked);
    setState({
      ...initCheckboxState,
      [event.target.name]: event.target.checked,
    });
    // console.log(state);
    // console.log(event.target.name);
    // console.log(event.target.checked);

    if (event.target.name === "лагерь") {
      setWeekReports((old) => sortByType(weekReportDataCopy, "camp"));
      console.log("week report", sortByType(weekReportDataCopy, "camp"));
    }
    if (event.target.name === "индивидуальные") {
      setWeekReports(sortByType(weekReportDataCopy, "individual"));
      console.log("week report", sortByType(weekReportDataCopy, "individual"));
    }
    if (event.target.name === "учебный_год") {
      setWeekReports(sortByType(weekReportDataCopy, "academic-year"));
      console.log(
        "week report",
        sortByType(weekReportDataCopy, "academic-year")
      );
    }
    if (event.target.checked === false) {
      setState({
        ...initCheckboxState,
      });
      setWeekReports(weekReportDataCopy);
    }
  };

  const weekData = (callback) =>
    new Promise((resolve) => {
      if (weekReportData !== undefined) {
        resolve(weekReportData.map((obj) => callback(obj)));
      }
    });

  const locationData = (callback) =>
    new Promise((resolve) => {
      if (locationReportData !== undefined) {
        resolve(locationReportData.map((obj) => callback(obj)));
      }
    });

  const payByDateByCityData = (callback) =>
    new Promise((resolve) => {
      if (payByDateByCityReportData !== undefined) {
        resolve(payByDateByCityReportData.map((obj) => callback(obj)));
      }
    });

  const campData = (callback) =>
    new Promise((resolve) => {
      if (campReportData !== undefined) {
        resolve(campReportData.map((obj) => callback(obj)));
      }
    });

  const firstVisitData = (callback) =>
    new Promise((resolve) => {
      if (firstVisitReportData !== undefined) {
        console.log("done");
        resolve(firstVisitReportData.map((obj) => callback(obj)));
      }
    });

  const countNewAndActiveContracts = function () {
    return function (dataa, rowKey, colKey) {
      return {
        count_new: 0,
        count_paid: 0,
        push(record) {
          this.count_new += record.count_new;
          this.count_paid += record.count_paid;
        },
        value() {
          return `${this.count_new} (${this.count_paid})`;
        },
        format(x) {
          return x;
        },
      };
    };
  };

  const remakeForBoolInTick = function () {
    return function (dataa, rowKey, colKey) {
      return {
        isPresence: 0,
        push(record) {
          this.isPresence = record.isPresence;
          if (this.isPresence !== 0 && this.isPresence !== 1) {
            this.isPresence = "?";
          }
        },
        value() {
          return this.isPresence !== 0 ? "✓" : "";
        },
        format(x) {
          return x;
        },
      };
    };
  };

  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 3,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const courses = useSelector(({ entitiesTypes }) => entitiesTypes.course);
  const [course, setCourse] = React.useState("все курсы");
  const handleChange = (event) => {
    setCourse(event.target.value);
  };

  const groups = useSelector(({ entitiesTypes }) => entitiesTypes.group);
  const [group, setGroups] = React.useState("все группы");
  const handleChangeGroup = (event) => {
    setGroups(event.target.value);
  };

  const blr = useSelector(({ entitiesTypes }) => entitiesTypes.rb);
  const [BLRLocations, setBLRLocations] = React.useState("не выбрано");
  const handleChangeBLRlocations = (event) => {
    console.log("event.target.value", event.target.value);
    setBLRLocations(event.target.value);
  };

  const ge = useSelector(({ entitiesTypes }) => entitiesTypes.georgia);
  const [GELocations, setGELocations] = React.useState("не выбрано");
  const handleChangeGELocation = (event) => {
    console.log("event.target.value", event.target.value);
    setGELocations(event.target.value);
  };

  const allLocations = useSelector(
    ({ entitiesTypes }) => entitiesTypes.location
  );
  const [location, setlocation] = React.useState("все филиалы");

  // const changeLocationsFromIdToValues = (id) => {
  //   // eslint-disable-next-line array-callback-return
  //   const locationName = allLocations.find((item) => {
  //     if (item.value === id) {
  //       return item.label;
  //     }
  //   });
  //   return locationName.label;
  // };

  const handleChangeLocation = (event) => {
    console.log(event);
    setlocation(event.target.value);
  };

  // const paymentsDataCopy = JSON.parse(JSON.stringify(paymentsData));

  // MonthPicker
  const [valueMonthPicker, setMonthPicker] = React.useState({
    year: new Date().getFullYear(),
    month: new Date().getMonth() + 1,
  });
  const monthPickerRef = React.useRef(null);

  const showPicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      console.log(monthPickerRef.current);
      monthPickerRef.current.show();
    }
  };

  const hidePicker = () => {
    if (monthPickerRef && monthPickerRef.current) {
      monthPickerRef.current.dismiss();
    }
  };

  const handlePickerChange = (...args) => {
    console.log(args);
    setMonthPicker({ year: args[0], month: args[1] });
    hidePicker(); // this works!
  };

  const lang = {
    months: [
      "Январь",
      "Февраль",
      "Март",
      "Апрель",
      "Май",
      "Июнь",
      "Июль",
      "Август",
      "Сентябрь",
      "Октябрь",
      "Ноябрь",
      "Декабрь",
    ],
    from: "From",
    to: "To",
  };

  // picker funcctions for pivottables data

  const selectorByLocations = (source) => {
    if (!source) {
      return;
    }
    if (source && location === "все филиалы") {
      return source;
    }
    if (source) {
      return source.filter((item) => item.location_id === location);
    }
  };

  const courseAndDatePicker = (source) => {
    if (!source) {
      return;
    }

    if (course === "все курсы" && location === "все филиалы") {
      // eslint-disable-next-line consistent-return
      return source.filter(
        (item) =>
          item.week?.split("-")[0] == valueMonthPicker.year &&
          item.week?.split("-")[1] == valueMonthPicker.month
      );
    }

    if (course === "все курсы" && location == "только РБ") {
      console.log("hello", location);
      return source.filter(
        (item) =>
          item.location_name != "г. Батуми" &&
          item.week?.split("-")[0] == valueMonthPicker.year &&
          item.week?.split("-")[1] == valueMonthPicker.month
      );
    }

    if (course && location == "только РБ") {
      console.log("hello", location);
      return source.filter(
        (item) =>
          item.course_id?.toString() === course &&
          item.location_name != "г. Батуми" &&
          item.week?.split("-")[0] == valueMonthPicker.year &&
          item.week?.split("-")[1] == valueMonthPicker.month
      );
    }

    if (course === "все курсы" && location !== "все филиалы") {
      return source.filter(
        (item) =>
          item.location_name === location &&
          item.week?.split("-")[0] == valueMonthPicker.year &&
          item.week?.split("-")[1] == valueMonthPicker.month
      );
    }

    if (course != "все курсы" && location !== "все филиалы") {
      return source.filter(
        (item) =>
          item.location_name === location &&
          item.course_id?.toString() === course &&
          item.week?.split("-")[0] == valueMonthPicker.year &&
          item.week?.split("-")[1] == valueMonthPicker.month
      );
    }

    if (source) {
      // eslint-disable-next-line consistent-return, eqeqeq
      return source.filter(
        (item) =>
          item.course_id?.toString() === course &&
          item.week?.split("-")[0] == valueMonthPicker.year &&
          item.week?.split("-")[1] == valueMonthPicker.month
      );
    }
  };

  const courseAndDatePickerFirstVizisit = (source) => {
    if (!source) {
      return;
    }
    if (course === "все курсы") {
      // eslint-disable-next-line consistent-return
      return source.filter(
        (item) =>
          item.date?.split("-")[0] == valueMonthPicker.year &&
          item.date?.split("-")[1] == valueMonthPicker.month
      );
    }
    if (source) {
      // eslint-disable-next-line consistent-return, eqeqeq
      return source.filter(
        (item) =>
          item.course_id?.toString() === course &&
          item.date?.split("-")[0] == valueMonthPicker.year &&
          item.date?.split("-")[1] == valueMonthPicker.month
      );
    }
  };

  const courseAndBlrGeLocationsPicker = (source) => {
    if (!source) {
      return;
    }
    if (
      course === "все курсы" &&
      BLRLocations === "все курсы" &&
      GELocations === "не выбрано"
    ) {
      const reduced = source.reduce(
        // eslint-disable-next-line array-callback-return
        (acc, item, i) => {
          if (item.location_id != ge[0].value) {
            return [...acc, item];
          }
          return acc;
        },
        []
      );
      return reduced;
    }
    if (
      course === "все курсы" &&
      BLRLocations === "не выбрано" &&
      GELocations === "не выбрано"
    ) {
      return source;
    }
    if (course === "все курсы" && BLRLocations && GELocations) {
      return source.filter(
        (item) =>
          item.location_id === BLRLocations || item.location_id === GELocations
      );
    }
    if (
      course === "все курсы" &&
      BLRLocations &&
      GELocations === "не выбрано"
    ) {
      return source.filter((item) => item.location_id === BLRLocations);
    }
    if (
      course === "все курсы" &&
      BLRLocations === "не выбрано" &&
      GELocations
    ) {
      return source.filter((item) => item.location_id === GELocations);
    }
    if (course === "все курсы" && BLRLocations && GELocations) {
      return source.filter(
        (item) =>
          item.location_id === GELocations && item.location_id === BLRLocations
      );
    }
    if (
      course &&
      BLRLocations === "не выбрано" &&
      GELocations === "не выбрано"
    ) {
      return source.filter((item) => item.course_id === course);
    }
    if (course && BLRLocations && GELocations === "не выбрано") {
      return source.filter(
        (item) => item.course_id === course && item.location_id === BLRLocations
      );
    }
    if (course && BLRLocations === "не выбрано" && GELocations) {
      return source.filter(
        (item) => item.course_id === course && item.location_id === GELocations
      );
    }
    if (course && BLRLocations && GELocations) {
      return [];
    }
  };

  const groupPicker = (source) => {
    if (!source) {
      return;
    }
    if (group === "все группы") {
      // eslint-disable-next-line consistent-return
      return source;
    }
    if (source) {
      // eslint-disable-next-line consistent-return
      return source.filter((item) => item.group_id === group);
    }
  };

  // MonthPicker

  // eslint-disable-next-line no-return-assign
  // const commonLoading = Object.values(reportsLoading).forEach((value) => {
  //   let loading = false;
  //   if (value === true) {
  //     console.log(value);
  //     loading = true;
  //   } else loading = false;
  //   return false;
  // });

  // console.log(commonLoading);

  return (
    <div className="content-component__wrapper">
      <h4 className="table__title">{title}</h4>
      {(() => {
        switch (value) {
          case "1":
            return reportsLoading.new ? null : (
              <>
                <div
                  className="location__title"
                  style={{ padding: "5px 30px 0px 30px", fontWeight: "bold" }}
                >
                  Выберите курс, дату, филиал(курсы можно сортировать по типу,
                  все вместе, либо отдельно):
                </div>
                <Box
                  sx={{
                    padding: "0 30px 15px 30px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "start",
                  }}
                >
                  <FormControl
                    sx={{
                      width: "fit-content",
                      minWidth: "150px",
                      marginRight: "30px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">Курс</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={course}
                      label="course"
                      onChange={handleChange}
                    >
                      {courses?.map((item) => (
                        <MenuItem key={uuidv4()} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                      <MenuItem value="все курсы">все курсы</MenuItem>
                    </Select>
                  </FormControl>

                  <MonthPicker
                    lang={lang.months}
                    ref={monthPickerRef}
                    value={valueMonthPicker}
                    onChange={handlePickerChange}
                    years={[2020, 2021, 2022, 2023, 2024, 2025, 2026]}
                  >
                    <span onClick={showPicker}>
                      <TextField
                        id="outlined-read-only-input"
                        label="Дата"
                        value={`год: ${JSON.stringify(
                          valueMonthPicker.year
                        )} месяц: ${JSON.stringify(valueMonthPicker.month)} `}
                        // InputProps={{
                        //   readOnly: true,
                        // }}
                      />{" "}
                    </span>
                  </MonthPicker>

                  <Box sx={{ padding: "0 30px 15px 30px" }}>
                    <FormControl
                      sx={{ width: "fit-content", minWidth: "150px" }}
                    >
                      <InputLabel id="demo-simple-select-label">
                        Филиал
                      </InputLabel>
                      <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={location}
                        label="location"
                        onChange={handleChangeLocation}
                      >
                        {allLocations?.map((item) => (
                          <MenuItem key={uuidv4()} value={item.label}>
                            {item.label}
                          </MenuItem>
                        ))}
                        <MenuItem value="все филиалы">все филиалы</MenuItem>
                        <MenuItem value="только РБ">только РБ</MenuItem>
                      </Select>
                    </FormControl>
                  </Box>
                </Box>
                <FormGroup
                  style={{
                    padding: "0 30px 15px 30px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "noWrap",
                    justifyContent: "start",
                  }}
                >
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.лагерь}
                        onChange={handleChangeCheckBox}
                        inputProps={{ "aria-label": "controlled" }}
                        name="лагерь"
                      />
                    }
                    label="лагерь"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.индивидуальные}
                        onChange={handleChangeCheckBox}
                        inputProps={{ "aria-label": "controlled" }}
                        name="индивидуальные"
                      />
                    }
                    label="индивидуальные"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={state.учебный_год}
                        onChange={handleChangeCheckBox}
                        inputProps={{ "aria-label": "controlled" }}
                        name="учебный_год"
                      />
                    }
                    label="учебный_год"
                  />
                </FormGroup>
              </>
            );
          case "2":
            return (
              <>
                <div
                  className="location__title"
                  style={{ padding: "5px 30px 0px 30px", fontWeight: "bold" }}
                >
                  Выберите курс и филиал( вкладки &quot;РБ&quot; или
                  &quot;Грузия&quot;) : (Если не выбраны страны - отобразятся
                  все курсы по Грузии и РБ)
                </div>
                <Box
                  sx={{
                    padding: "0 30px 15px 30px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "start",
                  }}
                >
                  <FormControl
                    sx={{
                      width: "fit-content",
                      minWidth: "150px",
                      marginRight: "30px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">Курс</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={course}
                      label="course"
                      onChange={handleChange}
                    >
                      {courses?.map((item) => (
                        <MenuItem key={uuidv4()} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                      <MenuItem value="все курсы">все курсы</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl
                    sx={{
                      width: "fit-content",
                      minWidth: "150px",
                      marginRight: "30px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">РБ</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={BLRLocations}
                      label="belarus"
                      onChange={handleChangeBLRlocations}
                    >
                      {blr?.map((item) => (
                        <MenuItem key={uuidv4()} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                      <MenuItem value="не выбрано">не выбрано</MenuItem>
                      <MenuItem value="все курсы">все курсы по РБ</MenuItem>
                    </Select>
                  </FormControl>

                  <FormControl sx={{ width: "fit-content", minWidth: "150px" }}>
                    <InputLabel id="demo-simple-select-label">
                      Грузия
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={GELocations}
                      label="georgia"
                      onChange={handleChangeGELocation}
                    >
                      {ge?.map((item) => (
                        <MenuItem key={uuidv4()} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                      <MenuItem value="не выбрано">не выбрано</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </>
            );
          case "4":
            return (
              <>
                <div
                  className="location__title"
                  style={{ padding: "5px 30px 0px 30px", fontWeight: "bold" }}
                >
                  Выберите группу
                </div>
                <Box
                  sx={{
                    padding: "0 30px 15px 30px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "start",
                  }}
                >
                  <FormControl
                    sx={{
                      width: "fit-content",
                      minWidth: "150px",
                      marginRight: "30px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">
                      Группа
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={group}
                      label="group"
                      onChange={handleChangeGroup}
                    >
                      {groups?.map((item) => (
                        <MenuItem key={uuidv4()} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                      <MenuItem value="все группы">все группы</MenuItem>
                    </Select>
                  </FormControl>
                </Box>
              </>
            );
          case "5":
            return (
              <>
                <div
                  className="location__title"
                  style={{ padding: "5px 30px 0px 30px", fontWeight: "bold" }}
                >
                  Выберите курс и дату:
                </div>
                <Box
                  sx={{
                    padding: "0 30px 15px 30px",
                    display: "flex",
                    flexDirection: "row",
                    flexWrap: "wrap",
                    justifyContent: "start",
                  }}
                >
                  <FormControl
                    sx={{
                      width: "fit-content",
                      minWidth: "150px",
                      marginRight: "30px",
                    }}
                  >
                    <InputLabel id="demo-simple-select-label">Курс</InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={course}
                      label="location"
                      onChange={handleChange}
                    >
                      {courses?.map((item) => (
                        <MenuItem key={uuidv4()} value={item.value}>
                          {item.label}
                        </MenuItem>
                      ))}
                      <MenuItem value="все курсы">все курсы</MenuItem>
                    </Select>
                  </FormControl>

                  <MonthPicker
                    lang={lang.months}
                    ref={monthPickerRef}
                    value={valueMonthPicker}
                    onChange={handlePickerChange}
                    years={[2020, 2021, 2022, 2023, 2024, 2025, 2026]}
                  >
                    <span onClick={showPicker}>
                      <TextField
                        id="outlined-read-only-input"
                        label="Дата"
                        value={`год: ${JSON.stringify(
                          valueMonthPicker.year
                        )} месяц: ${JSON.stringify(valueMonthPicker.month)} `}
                        // InputProps={{
                        //   readOnly: true,
                        // }}
                      />{" "}
                    </span>
                  </MonthPicker>
                </Box>
              </>
            );

          default:
            return "none";
        }
      })()}

      <div className="table__wrapper" style={{ overflowX: "auto" }}>
        <Box>
          <TabContext value={value}>
            <Box>
              <TabList onChange={handleChangeTab}>
                {/* <Tab label="Новые договоры" value="1" /> */}
                <Tab label="Действующие договоры" value="2" />
                <Tab label="Платежи по дням" value="3" />
                {/* <Tab label="Посещение учебного года" value="4" />
                <Tab label="Первое посещение" value="5" /> */}
              </TabList>
            </Box>
            <TabPanel value="1" sx={{ padding: 0, overflowX: "auto" }}>
              {reportsLoading.new ? (
                <Backdrop open={reportsLoading.new} sx={backdropStyles}>
                  <CircularProgress />
                </Backdrop>
              ) : (
                <PivotTable
                  data={courseAndDatePicker(
                    weekReportSorted || weekReportDataCopy
                  )}
                  // data={dataSelector(weekReportDataCopy)}
                  rows={["week"]}
                  cols={["course", "location_name"]}
                  vals={["count"]}
                  onChange={(s) => {
                    console.log(settings);
                    setSettings(s);
                  }}
                  {...settings}
                  aggregators={{
                    myagg: countNewAndActiveContracts,
                  }}
                  aggregatorName="myagg"
                  sorters={{ week: (a, b) => (b > a ? 1 : -1) }}
                />
              )}
            </TabPanel>
            <TabPanel value="2" sx={{ padding: 0, overflowX: "auto" }}>
              {reportsLoading.ongoing ? (
                <Backdrop open={reportsLoading.ongoing} sx={backdropStyles}>
                  <CircularProgress />
                </Backdrop>
              ) : (
                <PivotTable
                  data={courseAndBlrGeLocationsPicker(locationReportDataCopy)}
                  rows={["location"]}
                  cols={["course"]}
                  vals={["count"]}
                  aggregatorName="Integer Sum"
                  sorters={{ week: (a, b) => (b > a ? 1 : -1) }}
                  onChange={(s) => setSettings(s)}
                  // {...pivotData}
                />
              )}
            </TabPanel>
            <TabPanel value="3" sx={{ padding: 0, overflowX: "auto" }}>
              {reportsLoading.payByDayAndByCity ? (
                <Backdrop
                  open={reportsLoading.payByDayAndByCity}
                  sx={backdropStyles}
                >
                  <CircularProgress />
                </Backdrop>
              ) : (
                <PivotTable
                  data={payByDateByCityData}
                  rows={["date"]}
                  cols={["city"]}
                  vals={["amount"]}
                  aggregatorName="Sum"
                  onChange={(s) => setSettings(s)}
                  sorters={{ date: (a, b) => (b > a ? 1 : -1) }}
                />
              )}
            </TabPanel>
            <TabPanel value="4" sx={{ padding: 0, overflowX: "auto" }}>
              {reportsLoading.camp ? (
                <Backdrop open={reportsLoading.camp} sx={backdropStyles}>
                  <CircularProgress />
                </Backdrop>
              ) : (
                <PivotTable
                  data={groupPicker(campReportDataCopy)}
                  rows={["date"]}
                  cols={["group_name", "children_name"]}
                  vals={["isPresence"]}
                  onChange={(s) => setSettings(s)}
                  aggregators={{
                    myagg: remakeForBoolInTick,
                  }}
                  aggregatorName="myagg"
                  sorters={{ date: (a, b) => (b > a ? 1 : -1) }}
                />
              )}
            </TabPanel>
            <TabPanel value="5" sx={{ padding: 0, overflowX: "auto" }}>
              {reportsLoading.firstVisit ? (
                <Backdrop open={reportsLoading.firstVisit} sx={backdropStyles}>
                  <CircularProgress />
                </Backdrop>
              ) : (
                <PivotTable
                  data={courseAndDatePickerFirstVizisit(
                    firstVisitReportDataCopy
                  )}
                  rows={["date"]}
                  cols={["course"]}
                  vals={["count_new"]}
                  onChange={(s) => setSettings(s)}
                  aggregators={{
                    myagg: countNewAndActiveContracts,
                  }}
                  aggregatorName="myagg"
                  sorters={{ date: (a, b) => (b > a ? 1 : -1) }}
                />
              )}
            </TabPanel>
          </TabContext>
        </Box>
      </div>
    </div>
  );
}

export default ReportsPage;
