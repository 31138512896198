const initialState = {
	payload: [],
	loading: false,
};

const workingOutReducer = (state = initialState, action) => {
	switch (action.type) {
		case 'GET_WORKING_OUT':
			return {
				...state,
				payload: action.payload,
				loading: action.loading,
			};
		case 'LOADING_WORKING_OUT':
			return {
				...state,
				loading: action.loading,
			};
		case 'UPDATE_WORKING_OUT':
			return {
				...state,
				payload: state.payload.map((item) =>
					item.id_temporary_arrangement ===
					action.payload.id_temporary_arrangement
						? action.payload
						: item
				),
				loading: action.loading,
			};
		case 'REMOVE_WORKING_OUT':
			console.log('REMOVE_WORKING_OUT', action.payload);
			return {
				...state,
				payload: state.payload.filter(
					(item) =>
						item.id_temporary_arrangement !==
						action.payload.temporary_arrangement
				),
				loading: action.loading,
			};
		case 'CLEAR_WORKING_OUT':
			return initialState;
		default:
			return state;
	}
};

export default workingOutReducer;
