import { Button } from "@mui/material";
import dayjs from "dayjs";
import React from "react";
import { useDispatch } from "react-redux";
import TaskModalSupport from "../TaskModal/TaskModalSupport";
import "./Support.scss";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import { updateTaskStatus } from "../../redux/actions/tasksActions";

const Support = ({ supportHistory, entities, ...props }) => {
  const dispatch = useDispatch();
  const [isModalTaskCreateOpenSupport, setModalTaskCreateOpenSupport] =
    React.useState(false);
  const handleTaskCreateOpenSupport = () => {
    setModalTaskCreateOpenSupport(true);
  };
  const users = entities.user;
  const handleCloseTask = (id) => {
    dispatch(updateTaskStatus({ id_task: id, status: "closed" }));
  };

  const compareItems = (a, b) => {
    // Define the order of statuses
    const statusOrder = {
      new: 0,
      in_work: 1,
      completed: 2,
      closed: 3,
    };

    // Compare statuses first
    const statusComparison = statusOrder[a.status] - statusOrder[b.status];
    if (statusComparison !== 0) {
      return statusComparison;
    }

    // If statuses are the same, compare dates
    return dayjs(b.date_created).unix() - dayjs(a.date_created).unix();
  };

  // Sort the support history array using the custom comparator function
  const sortedSupportHistory = [...supportHistory].sort(compareItems);

  return (
    <div>
      <div className="support__page_wrapper">
        <div className="support__page_header">
          <div className="support__page_title">История обращений</div>
          <div className="support__page_new_btn">
            <Button
              onClick={handleTaskCreateOpenSupport}
              variant="contained"
              size="small"
            >
              Новое обращение
            </Button>
          </div>
        </div>
        <div className="support__page_content">
          {sortedSupportHistory.map((item) => (
            <div className="support__page_card">
              <div className="support__page_card_header">
                {item.status === "new" &&
                  `Обращение от ${dayjs(item.date_created)?.format(
                    "DD-MM-YYYY"
                  )} .менеджер ${" "} ${findLabelByID(
                    item.executor_id,
                    users
                  )} `}
                {item.status === "in_work" &&
                  `Обращение от ${dayjs(item.date_created)?.format(
                    "DD-MM-YYYY"
                  )} менеджер ${" "} ${findLabelByID(
                    item.executor_id,
                    users
                  )} `}
                {item.status === "completed" &&
                  `Обращение от ${dayjs(item.date_created)?.format(
                    "DD-MM-YYYY"
                  )} менеджер ${" "} ${findLabelByID(
                    item.executor_id,
                    users
                  )} `}
                {item.status === "closed" &&
                  `Обращение от ${dayjs(item.date_created)?.format(
                    "DD-MM-YYYY"
                  )} менеджер ${" "} ${findLabelByID(
                    item.executor_id,
                    users
                  )} `}
                {item.status === "denied_execution" &&
                  `Обращение от ${dayjs(item.date_created)?.format(
                    "DD-MM-YYYY"
                  )} менеджер ${" "} ${findLabelByID(
                    item.executor_id,
                    users
                  )} `}
              </div>

              <div className="support__page_card_text">{item.message}</div>

              <div className="support__page_card_footer">
                <div>
                  {item.status === "new" && (
                    <span className="support__page_card_new">
                      передано в работу
                    </span>
                  )}
                  {item.status === "in_work" && (
                    <span className="support__page_card_work">
                      обрабатывается
                    </span>
                  )}
                  {item.status === "completed" && (
                    <span className="support__page_card_completed">
                      обрабатано
                    </span>
                  )}
                  {item.status === "closed" && (
                    <span className="support__page_card_closed">закрыто</span>
                  )}
                  {item.status === "denied_execution" && (
                    <span className="support__page_card_denied_execution">
                      Отказано в выполнении
                    </span>
                  )}
                </div>
                <div className="support__page_close_task">
                  {" "}
                  {item.status === "completed" && (
                    <Button
                      variant="contained"
                      size="small"
                      onClick={() => {
                        handleCloseTask(item.id_task);
                      }}
                    >
                      закрыть
                    </Button>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>

      <TaskModalSupport
        task={[]}
        open={isModalTaskCreateOpenSupport}
        onClose={() => setModalTaskCreateOpenSupport(false)}
      />
    </div>
  );
};

export default Support;
