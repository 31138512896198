import React from "react";
import "./ExtendDialog.scss";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  TextField,
  MenuItem,
} from "@mui/material";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { useDispatch } from "react-redux";
import { contractApi } from "../../utils/api";
import notification from "../../utils/notification/notification";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import downloadFile from "../../utils/helpers/downloadFile";
import { fetchContactHistory } from "../../redux/actions/contactsActions";

const ExtendDialog = ({
  open,
  handleClose,
  contractInfo,
  kindergatens,
  entitiesTypes,
  id_contact,
}) => {
  const [selectedProgram, setSelectedProgram] = React.useState("");
  const [selectedGroup, setSelectedGroup] = React.useState("");
  const [filteredGroups, setFilteredGroups] = React.useState([]);
  const [contractExtendedInfo, setContractExtendedInfo] = React.useState({
    isContractExtended: false,
    contractInfo: null,
  });
  const dispatch = useDispatch();

  const handleProgramChange = (e) => {
    setSelectedProgram(e.target.value);
    const filtered = kindergatens.groups.filter(
      (group) => group.course_id === e.target.value
    );
    setFilteredGroups(filtered);
    setSelectedGroup("");
  };

  const handleExtendContract = () => {
    const confirm = window.confirm("Вы уверены, что хотите продлить договор?");
    if (!confirm) {
      return;
    }
    contractApi
      .contractExtend({
        contract_id: contractInfo.contract_id,
        course_id: selectedProgram,
        group_id: selectedGroup,
      })
      .then((response) => {
        if (response.status === 200 || response.data.file_name) {
          notification.success("Договор успешно продлен");
          setContractExtendedInfo({
            isContractExtended: true,
            contractInfo: response.data,
          });
          if (id_contact) {
            dispatch(fetchContactHistory(id_contact));
          }
        }
        setSelectedProgram("");
        setSelectedGroup("");
      });
  };

  const handleCloseDialog = () => {
    setFilteredGroups([]);
    setSelectedProgram("");
    setSelectedGroup("");
    setContractExtendedInfo({
      isContractExtended: false,
      contractInfo: null,
    });
    handleClose();
  };

  const downloadContratc = () => {
    contractApi
      .save(contractExtendedInfo.contractInfo.file_name)
      .then((response) => {
        downloadFile(response, contractExtendedInfo.contractInfo.file_name);
      });
  };

  if (!kindergatens || !contractInfo)
    return (
      <Dialog open={open} onClose={handleCloseDialog} fullWidth maxWidth="md">
        <DialogTitle>Загрузка...</DialogTitle>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Закрыть
          </Button>
        </DialogActions>
      </Dialog>
    );

  return (
    <Dialog open={open} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        Продлить договор{" "}
        <span
          onClick={handleCloseDialog}
          style={{ float: "right", cursor: "pointer" }}
        >
          X
        </span>{" "}
      </DialogTitle>

      <div
        className="contract__client-info"
        style={{
          margin: "0 0 10px 0",
          padding: "10px",
          border: "1px solid #ccc",
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <div style={{ width: "40%" }}>
          <span style={{ fontWeight: "bold" }}> Клиент: </span>{" "}
          {contractInfo.full_name}
          <br />
          <span style={{ fontWeight: "bold" }}> Телефон: </span>{" "}
          {contractInfo.phone}
          <br />
          <span style={{ fontWeight: "bold" }}> Договор: </span>{" "}
          {contractInfo.number}
          <br />
          <span style={{ fontWeight: "bold" }}> Ребенок: </span>{" "}
          {contractInfo.kid_name}
          <br />
          <span style={{ fontWeight: "bold" }}> Курс: </span>{" "}
          {findLabelByID(contractInfo.course, entitiesTypes.course)}
          <br />
          <span style={{ fontWeight: "bold" }}> Группа: </span>{" "}
          {findLabelByID(contractInfo.group_id, entitiesTypes.group)}
        </div>
        <span>
          <ArrowRightAltIcon />
        </span>
        <div style={{ width: "40%" }}>
          <span style={{ fontWeight: "bold" }}> Клиент: </span>{" "}
          {contractInfo.full_name}
          <br />
          <span style={{ fontWeight: "bold" }}> Телефон: </span>{" "}
          {contractInfo.phone}
          <br />
          <span style={{ fontWeight: "bold" }}> Договор: </span> новый
          <br />
          <span style={{ fontWeight: "bold" }}> Ребенок: </span>{" "}
          {contractInfo.kid_name}
          <br />
          <span style={{ fontWeight: "bold" }}> Курс: </span>{" "}
          {findLabelByID(selectedProgram, entitiesTypes.course)}
          <br />
          <span style={{ fontWeight: "bold" }}> Группа: </span>{" "}
          {findLabelByID(selectedGroup, entitiesTypes.group)}
        </div>
      </div>
      <DialogContent>
        <span style={{ fontWeight: "bold" }}>
          Выберите новый курс и группу для продления договора:
        </span>
        <TextField
          select
          label="Выберите курс"
          fullWidth
          value={selectedProgram}
          onChange={handleProgramChange}
          margin="normal"
          size="small"
        >
          {kindergatens?.courses.map((course) => (
            <MenuItem key={course.id} value={course.id}>
              {course.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          label="Выберите группу"
          fullWidth
          value={selectedGroup}
          onChange={(e) => setSelectedGroup(e.target.value)}
          margin="normal"
          size="small"
        >
          {filteredGroups.map((group) => (
            <MenuItem key={group.id} value={group.id}>
              {group.name}
            </MenuItem>
          ))}
        </TextField>
        <div className="extend__dialog-download-contract">
          {contractExtendedInfo.isContractExtended &&
          !selectedGroup &&
          !selectedProgram ? (
            <div>
              договор {contractExtendedInfo.contractInfo.file_name} создан.
              Можете скачать его{" "}
              <span onClick={() => downloadContratc()}>по ссылке</span>{" "}
            </div>
          ) : null}
        </div>
      </DialogContent>

      <DialogActions>
        <Button onClick={handleCloseDialog} color="primary">
          Закрыть
        </Button>
        <Button
          onClick={handleExtendContract}
          color="primary"
          variant="contained"
          disabled={!selectedProgram || !selectedGroup}
        >
          Продлить
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ExtendDialog;
