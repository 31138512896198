import { appointmentsApi } from "../../utils/api";
import notification from "../../utils/notification/notification";

export const getTeachersPlan = (data) => ({
  type: "GET_TEACHERS_PLAN",
  payload: data,
  loading: false,
});

export const loadingAppointments = () => ({
  type: "LOADING_APPOINTMENTS",
  loading: true,
});

export const addAppointments = (data) => ({
  type: "ADD_APPOINTMENTS",
  payload: data,
  loading: false,
});

export const addSubstitution = (data) => ({
  type: "ADD_SUBSTITUTION",
  payload: data,
  loading: false,
});

export const updateAppointments = (data) => ({
  type: "UPDATE_APPOINTMENTS",
  payload: data,
  loading: false,
});
export const updateSubstitution = (data) => ({
  type: "UPDATE_SUBSTITUTION",
  payload: data,
  loading: false,
});
export const deleteAppointments = (id) => ({
  type: "REMOVE_APPOINTMENTS",
  payload: id,
  loading: false,
});
export const deleteSubstitution = (id) => ({
  type: "REMOVE_SUBSTITUTION",
  payload: id,
  loading: false,
});

export const cancelAppointments = () => ({
  type: "CANCEL_APPOINTMENTS",
  payload: null,
  loading: false,
});

export const fetchTeachersPlan = (params) => (dispatch) => {
  dispatch(loadingAppointments());
  appointmentsApi
    .get(params)
    .then(({ data }) => dispatch(getTeachersPlan(data)));
};

export const fetchTeachersPlanHidden = (params) => (dispatch) => appointmentsApi.get(params).then(({ data }) => {
  dispatch(getTeachersPlan(data));
  return data;
});
export const postAppointments = (obj) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi
    .add(obj)
    .then(({ data }) => {
      console.log("add", data);
      notification.success("Назначение создано");
      dispatch(addAppointments(data));
      return data;
    })
    .catch((err) => {
      alert(err.response.data.message);
      dispatch(cancelAppointments());
    });
};
export const postSubstitution = (obj) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi
    .add(obj)
    .then(({ data }) => {
      console.log("add", data);
      notification.success("Замена назначена");
      dispatch(addSubstitution(data));
      return data;
    })
    .catch((err) => {
      console.error(err);
      console.log("err", err);
      alert(err.response.data.message);
      dispatch(cancelAppointments());
    });
};

export const updAppointments = (obj) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi
    .update(obj)
    .then(({ data }) => {
      notification.success("Данные изменены");
      dispatch(updateAppointments(data));
    })
    .catch((err) => {
      console.error(err);
      console.log("err", err);
      alert(err.response.data.message);
      dispatch(cancelAppointments());
    });
};

export const updSubstitution = (obj) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi
    .update(obj)
    .then(({ data }) => {
      notification.success("Данные изменены");
      dispatch(updateSubstitution(data));
    })
    .catch((err) => {
      console.error(err);
      console.log("err", err);
      alert(err.response.data.message);
      dispatch(cancelAppointments());
    });
};

export const removeAppointments = (id) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi.remove(id).then(() => {
    notification.success("Назначение удалено");
    console.log("id", id);
    dispatch(deleteAppointments(id));
  });
};

export const removeAppointmentsWithoutReaload = (id) => (dispatch) =>
  appointmentsApi.remove(id).then((response) => {
    if (response.status === 200) {
      notification.success("Назначение удалено");
      dispatch(deleteAppointments(id));
    } else {
      notification.error("Назначение не удалено");
    }
    return response;
  });

export const removeSubstitute = (id) => (dispatch) => {
  dispatch(loadingAppointments());
  return appointmentsApi.remove(id).then(() => {
    notification.success("Замена удалена");
    console.log("id", id);
    dispatch(deleteSubstitution(id));
  });
};

// we can use this function to update appointments in classScheduleView
//  that the calendar with schedule does not reload and we handle the update in the background
export const updAppointmentInSchedule = (obj) => (dispatch) =>
  appointmentsApi
    .update(obj)
    .then(({ data }) => {
      notification.success("Данные расписания изменены");
      dispatch(updateAppointments(data));
      return data; // for using in classScheduleView
    })
    .catch((err) => {
      console.error(err);
      console.log("err", err);
      alert(err.response.data.message);
      dispatch(cancelAppointments());
      return null;
    });

export const postAppointmentsInSchedule = (obj) => (dispatch) =>
  appointmentsApi
    .add(obj)
    .then(({ data }) => {
      console.log("add", data);
      notification.success("Назначение создано");
      dispatch(addAppointments(data));
      return data; // for using in classScheduleView
    })
    .catch((err) => {
      alert(err.response.data.message);
      dispatch(cancelAppointments());
      return null;
    });


export const undoAction = () => (dispatch, getState) => {
  const state = getState().appointmentsAndSubstitute;

  if (state.history.length === 0) return;

  const lastAction = state.history[state.history.length - 1];
  const updatedHistory = state.history.slice(0, -1);
  if (lastAction.type === "add") {
    dispatch({
      type: "UNDO_ADD",
      payload: { lessonId: lastAction.lesson.id, updatedHistory },
    });
    appointmentsApi
      .remove({ appointment: lastAction.lesson.id })
      .then(() => {
        notification.success("Добавление отменено");
      })
      .catch((error) => {
        console.error("Error undoing add:", error);
        notification.error("Не удалось отменить добавление");
      });
  } else if (lastAction.type === "edit") {
    dispatch({
      type: "UNDO_EDIT",
      payload: {
        updatedLesson: lastAction.originalLesson,
        updatedHistory,
      },
    });
    appointmentsApi
      .update({ appointment: lastAction.originalLesson })
      .then(() => {
        notification.success("Изменение отменено");
      })
      .catch((error) => {
        console.error("Error undoing edit:", error);
        notification.error("Не удалось отменить изменение");
      });
  } else if (lastAction.type === "remove") {
    const { date, duration, group_id, subject, user_id } = lastAction.lesson;

    appointmentsApi
      .add({
        appointment: { date, duration, group_id, subject, user_id },
      })
      .then((response) => {
        notification.success("Назначение восстановлено");
        const serverLesson = response.data;
        dispatch({
          type: "UNDO_REMOVE",
          payload: {
            serverLesson,
            updatedHistory,
          },
        });
      })
      .catch((error) => {
        console.error("Error undoing remove:", error);
        notification.error("Не удалось восстановить назначение");
      });
  }
};

export const redoAction = () => (dispatch, getState) => {
  const state = getState().appointmentsAndSubstitute;
  if (state.redoStack.length === 0) return;

  const lastUndoneAction = state.redoStack[state.redoStack.length - 1];
  const updatedRedoStack = state.redoStack.slice(0, -1);

  if (lastUndoneAction.type === "add") {
    const { date, duration, group_id, subject, user_id } = lastUndoneAction.lesson;

    appointmentsApi
      .add({
        appointment: { date, duration, group_id, subject, user_id },
      })
      .then((response) => {
        notification.success("Назначение повторно добавлено");
        const serverLesson = response.data;
        dispatch({
          type: "REDO_ADD",
          payload: {
            serverLesson,
            updatedRedoStack,
          },
        });
      })
      .catch((error) => {
        console.error("Error redoing add:", error);
        notification.error("Не удалось повторно добавить назначение");
      });
  } else if (lastUndoneAction.type === "edit") {
    dispatch({
      type: "REDO_EDIT",
      payload: {
        updatedLesson: lastUndoneAction.updatedLesson,
        updatedRedoStack,
      },
    });
    appointmentsApi
      .update({ appointment: lastUndoneAction.updatedLesson })
      .then(() => {
        notification.success("Изменение повторно применено");
      })
      .catch((error) => {
        console.error("Error redoing edit:", error);
        notification.error("Не удалось повторно применить изменение");
      });
  } else if (lastUndoneAction.type === "remove") {
    appointmentsApi
      .remove({ appointment: lastUndoneAction.lesson.id })
      .then(() => {
        notification.success("Назначение повторно удалено");
        dispatch({
          type: "REDO_REMOVE",
          payload: {
            lessonId: lastUndoneAction.lesson.id,
            updatedRedoStack,
          },
        });
      })
      .catch((error) => {
        console.error("Error redoing remove:", error);
        notification.error("Не удалось повторно удалить назначение");
      });
  }
};
