import axios from "../../config/axiosConfig";
import { entitiesApi } from "../../utils/api";
import { loading, loaded } from "./globalActions";

export const setUniqueTypes = (data) => ({
  type: "SET_UNIQUE_TYPES",
  payload: data,
});

export const setEditableEntities = (data) => ({
  type: "SET_EDITABLE_ENTITIES",
  payload: data,
});

export const addEntity = (entityType, entity) => ({
  type: "ADD_ENTITY",
  payload: { entityType, entity },
});

export const updateEntity = (entityType, entity) => ({
  type: "UPDATE_ENTITY",
  payload: { entityType, entity },
});

export const getUniqueEntities = () => (dispatch) => {
  dispatch(loading());
  axios.get("/entities-types/get").then((response) => {
    dispatch(setUniqueTypes(response.data));
    dispatch(loaded());
  });
};

export const getUniqueEntitiesHidden = () => (dispatch) => {
  axios.get("/entities-types/get").then((response) => {
    dispatch(setUniqueTypes(response.data));
  });
};

export const getEditableEntities = () => (dispatch) => {
  entitiesApi.get().then((response) => {
    dispatch(setEditableEntities(response.data));
    console.log("responseGET", response);
    return response;
  });
};

export const addNewEntity = (entitie) => (dispatch) => {
  entitiesApi.add(entitie).then((response) => {
    dispatch(addEntity(response.data.type, response.data));
    console.log("responseADD", response);
  });
};

export const updEntity = (entitie) => (dispatch) => {
  entitiesApi.update(entitie).then((response) => {
    const ent = { ...Object.values(response.data)[0] };
    dispatch((updateEntity(ent.type, ent)));

    console.log("entitie", entitie);
  });
  // const ent = { ...Object.values(entitie)[0] };
  // console.log("{ ...Object.values(entitie) }", { ...Object.values(entitie)[0] });
  // dispatch((updateEntity(ent.type, ent)));
};

export const removeEntity = (entitie) => (dispatch) => {
  entitiesApi.remove(entitie).then((response) => {
    // dispatch(addEntity(response.data.type, response.data));
    console.log("responseRemove", response);
  });
};
