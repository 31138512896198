import React from "react";
import { makeStyles } from "@mui/styles";
import { SnackbarProvider } from "notistack";
import CloseIcon from "@mui/icons-material/Close";

const useStyles = makeStyles(() => ({
  error: {
    backgroundColor: "#fbedec !important",
    color: "#5f2120 !important",
    border: "1px solid #ef5350",
    boxShadow: "none !important",
    height: "30px !important",
    "& svg": {
      color: "#ef5350",
    },
  },
  success: {
    backgroundColor: "#edf7ec !important",
    color: "#1e4620 !important",
    border: "1px solid #4caf50",
    boxShadow: "none !important",
    height: "30px !important",
    "& svg": {
      color: "#4caf50",
    },
  },
  warning: {
    backgroundColor: "#fef4e4 !important",
    color: "#663c00 !important",
    border: "1px solid #ff9800",
    boxShadow: "none !important",
    height: "30px !important",
    "& svg": {
      color: "#ff9800",
    },
  },
  info: {
    backgroundColor: "#e5f6fd !important",
    color: "#014361 !important",
    border: "1px solid #03a9f4",
    boxShadow: "none !important",
    height: "30px !important",
    "& svg": {
      color: "#03a9f4",
    },
  },
}));

const notistackRef = React.createRef();
const onClickDismiss = (key) => () => {
  notistackRef.current.closeSnackbar(key);
};

const SnackProvider = ({ children }) => {
  const classes = useStyles();
  return (
    <SnackbarProvider
      ref={notistackRef}
      action={(key) => (
        <CloseIcon
          fontSize="small"
          autohideduration={3000}
          onClick={onClickDismiss(key)}
          sx={{
            ":hover": {
              cursor: "pointer",
            },
          }}
        />
      )}
      classes={{
        variantSuccess: classes.success,
        variantError: classes.error,
        variantWarning: classes.warning,
        variantInfo: classes.info,
      }}
    >
      {children}
    </SnackbarProvider>
  );
};
export default SnackProvider;
