import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect } from "react";
import { connect } from "react-redux";
import "../../scss/index.scss";
import { fetchCosts, removeCost, updateCost } from "../../redux/actions/costsActions";
import CostsView from "../../components/Costs/CostsView";
import { setPageTitle } from "../../redux/actions/globalActions";

const CostsPage = ({ ...props }) => {
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const { loading, getCosts, title, setTitle } = props;

  useEffect(() => {
    getCosts();
  }, []);
  useEffect(() => {
    setTitle(title);
  }, []);

  return (
    <>
      <div className="content-component__wrapper">
        <h4 className="table__title">{title}</h4>

        {loading ? (
          <Backdrop open={loading} sx={backdropStyles}>
            <CircularProgress />{" "}
          </Backdrop>
        ) : (
          <CostsView {...props} />
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  loading: state.costs.loading,
  data: state.costs.payload,

  globalState: state.globalState,
  entitiesTypes: state.entitiesTypes,
  permissions: {
    add: false,
    modify: true,
    remove: true,
  },
});

const mapDispatchToProps = (dispatch) => ({
  getCosts: () => {
    dispatch(fetchCosts());
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
  updateData: (data) => {
    dispatch(updateCost(data));
  },
  removeData: (data) => {
    dispatch(removeCost(data));
  }
});
export default connect(mapStateToProps, mapDispatchToProps)(CostsPage);
