/* eslint-disable array-callback-return */
import React from "react";
import { useDispatch, connect } from "react-redux";
import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import SearchAutocomplete from "../../components/SearchAutocomplete";
import DataGrid from "../../components/DataGrid/DataGrid";
import {
  dateSetterFunc,
  dateGetterFunc,
  dateFormatterFunc,
} from "../../utils/helpers/dataGridFormatters";
import {
  addPayment,
  fetchPayments,
  sendPayment,
  sendUpdatedPayment,
} from "../../redux/actions/paymentsActions";
import useDebounce from "../../utils/helpers/debounceHook";
import { contractApi } from "../../utils/api";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import { setPageTitle } from "../../redux/actions/globalActions";

// required names for actions
const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(fetchPayments()),
  addData: (obj) => dispatch(addPayment(obj)),
  updateData: (obj) => {
    delete obj.payment.status;
    delete obj.payment.id_location;
    dispatch(sendUpdatedPayment(obj));
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

// required structure and names of props
const mapStateToProps = (state) => ({
  globalState: state.globalState,
  data: state.payments.payload,
  loading: state.payments.loading,
  entitiesTypes: state.entitiesTypes,
  permissions: {
    add: false,
    modify: state.user.permissions.includes("payment.modify"),
    // remove: state.user.permissions.includes("payment.remove"),
    // need to do this method on redux and mapDispatchToProps
    remove: false,
  },
});

const styles = {
  subtitle: {
    paddingBottom: "15px",
  },
  span: {
    fontWeight: 400,
  },
};

function PaymensPage({ title, entitiesTypes, setTitle, ...props }) {
  const [valueClientInfoSearch, setValueClientInfoSearch] =
    React.useState(null);
  // const [valueCellSearch, setValueCellSearch] = React.useState(null);

  const [inputValueClientInfoSearch, setInputValueClientInfoSearch] =
    React.useState("");
  const [inputValueCellSearch, setInputValueCellSearch] = React.useState("");

  const [options, setOptions] = React.useState([]);
  const [searchLoadingClientInfo, setSearchLoadingClientInfo] =
    React.useState(false);
  const [searchLoadingCell, setSearchLoadingCell] = React.useState(false);
  const [contractInfo, setContractInfo] = React.useState(null);
  const debounceClientInfo = useDebounce(inputValueClientInfoSearch, 1000);
  const debounceCell = useDebounce(inputValueCellSearch, 1000);
  const locations = entitiesTypes.location;

  const dispatch = useDispatch();

  const onChangeHandlerClientInfo = (_event, newValue) => {
    setValueClientInfoSearch(newValue);
    if (newValue) {
      setContractInfo(newValue);
    }
  };

  // const onChangeHandlerCell = (_event, newValue) => {
  //   setValueCellSearch(newValue);
  //   if (newValue) {
  //     setContractInfo(newValue);
  //   }
  // };

  const onInputHandlerClientInfo = (_event, newInputValue) => {
    setInputValueClientInfoSearch(newInputValue);
  };

  // const onInputHandlerCell = (_event, newInputValue) => {
  //   setInputValueCellSearch(newInputValue);
  // };

  const searchClientInfo = (val) => {
    setSearchLoadingClientInfo(true);
    contractApi.get(val).then((response) => {
      const searchResult = Object.entries(response.data).map((item) => ({
        phone: item[1].phone,
        status: item[1].status,
        contract_id: item[1].contract_id,
        full_name: item[1].full_name,
        number: item[1].number,
        kid_name: item[1].kid_name,
      }));
      setSearchLoadingClientInfo(false);
      setOptions([...searchResult]);
    });
  };

  const searchCell = (val) => {
    setSearchLoadingCell(true);
    contractApi.get(val).then((response) => {
      const searchResult = Object.entries(response.data).map((item) => ({
        contract_id: item[0],
        full_name: item[1].full_name,
        number: item[1].number,
        status: item[1].status,
        kid_name: item[1].kid_name,
      }));
      setSearchLoadingCell(false);
      setOptions([...searchResult]);
    });
  };

  React.useEffect(() => {
    setTitle(title);
  }, []);

  React.useEffect(() => {
    if (debounceClientInfo) {
      searchClientInfo(debounceClientInfo);
    } else {
      setOptions([]);
    }
  }, [debounceClientInfo]);

  React.useEffect(() => {
    if (debounceCell) {
      searchCell(debounceCell);
    } else {
      setOptions([]);
    }
  }, [debounceCell]);

  const numberSetterFunc = (params) => {
    if (params.value) {
      return {
        ...params.row,
        contract_id: contractInfo.contract_id,
        number: contractInfo.number,
      };
    }
    return "";
  };

  const amountFormatterFunc = (params) => {
    if (!params.value) {
      return "-";
    }
    return `${params.value}р.`;
  };

  const nameGetterFunc = (params) => {
    if (!params.value) {
      return "unknown";
    }
    return params.value;
  };

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "number",
      headerName: "№ Договора",
      editable: true,
      editableOnlyUnknown: true,
      defaultValue: true,
      flex: 0.1,
      valueSetter: (params) => numberSetterFunc(params),
    },
    {
      field: "date",
      headerName: "Дата платежа",
      type: "date",
      editable: true,
      flex: 0.1,
      valueGetter: dateGetterFunc,
      valueSetter: (params) => dateSetterFunc(params, "date"),
      valueFormatter: dateFormatterFunc,
    },
    {
      field: "amount",
      headerName: "Сумма",
      editable: true,
      flex: 0.1,
      valueFormatter: (params) => amountFormatterFunc(params),
    },
    {
      field: "full_name",
      headerName: "Заказчик",
      editable: false,
      flex: 0.4,
      valueGetter: (params) => nameGetterFunc(params),
    },
    {
      field: "kid_name",
      headerName: "Слушатель",
      editable: false,
      flex: 0.3,
      valueGetter: (params) => nameGetterFunc(params),
    },
    {
      field: "id_location",
      headerName: "Локация",
      editable: false,
      flex: 0.3,
      valueGetter: (params) => findLabelByID(params.value, locations),
    },
  ];

  const [inpuDatetError, setinputDateError] = React.useState(false);
  const [inpuAmountError, setinputAmountError] = React.useState(false);
  const initialPaymentValues = {
    contract_id: "",
    date: "",
    amount: "",
  };

  const [newPaymentState, setPaymentState] =
    React.useState(initialPaymentValues);

  const handlePaymentInput = (e) => {
    setPaymentState({
      ...newPaymentState,
      [e.target.name]: e.target.value.toString(),
    });
    if (e.target.value) {
      setinputDateError(false);
      setinputAmountError(false);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newPaymentState.date) {
      setinputDateError(true);
      return;
    }
    if (!newPaymentState.amount) {
      setinputAmountError(true);
      return;
    }
    const formData = new FormData(e.target);
    const paymentData = {
      payment: {},
    };
    paymentData.payment.date = formData.get("date");
    paymentData.payment.amount = formData.get("amount");
    if (contractInfo) {
      paymentData.payment.contract_id = contractInfo.contract_id;
      paymentData.payment.number = contractInfo.number.toString();
    } else {
      paymentData.payment.contract_id = "unknown";
      paymentData.payment.number = "unknown";
    }
    setPaymentState(initialPaymentValues);
    setContractInfo(null);
    setInputValueCellSearch("");
    setInputValueClientInfoSearch("");
    // setValueCellSearch(null);
    setValueClientInfoSearch(null);
    dispatch(sendPayment(paymentData));
  };

  return (
    <div className="content-component__wrapper">
      <Typography variant="h4" component="h4" className="page__title">
        {title}
      </Typography>
      <Box sx={{ padding: "0 30px 0 30px" }}>
        <Stack>
          <Typography variant="h6" component="span" sx={styles.subtitle}>
            Номер договора:{" "}
            <span style={styles.span}>{contractInfo?.number}</span>
          </Typography>
          <Typography variant="h6" component="span" sx={styles.subtitle}>
            Клиент: <span style={styles.span}>{contractInfo?.full_name}</span>{" "}
            Ребенок: <span style={styles.span}>{contractInfo?.kid_name}</span>
          </Typography>
          <Typography variant="h6" component="span" sx={styles.subtitle}>
            Статус: <span style={styles.span}>{contractInfo?.status}</span>
          </Typography>
        </Stack>
      </Box>
      <div className="payment-form">
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start-end",
            flexWrap: "wrap",
            margin: "0 25px 10px 25px",
          }}
          component="form"
          onSubmit={handleSubmit}
          noValidate
        >
          <SearchAutocomplete
            label="Номер договора / Фамилия / Телефон"
            value={valueClientInfoSearch}
            inputValue={inputValueClientInfoSearch}
            name="number"
            size="small"
            setValue={onChangeHandlerClientInfo}
            setInputValue={onInputHandlerClientInfo}
            options={options}
            getOptionLabel={(option) =>
              `${option.phone} ${option.number} ${option.full_name}, Ребенок: ${option.kid_name}`
            }
            loading={searchLoadingClientInfo}
            loadingText="Поиск..."
            blurOnSelect
            clearOnBlur
            sx={{
              width: "330px",
              margin: "5px",
            }}
          />
          <TextField
            id="outlined-data"
            label="Дата"
            name="date"
            size="small"
            type="date"
            onChange={handlePaymentInput}
            value={newPaymentState.date}
            error={inpuDatetError}
            helperText={inpuDatetError ? "Введите дату" : null}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              margin: "5px",
            }}
          />

          <TextField
            id="outlined-amount"
            label="Сумма"
            name="amount"
            size="small"
            type="text"
            onChange={handlePaymentInput}
            value={newPaymentState.amount}
            error={inpuAmountError}
            helperText={inpuAmountError ? "Введите сумму" : null}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: "150px",
              margin: "5px",
            }}
          />

          <Button
            disabled={
              contractInfo?.status === "cancelled" ||
              contractInfo?.status === "closed"
            }
            variant="contained"
            type="submit"
            sx={{
              aligSelf: "flex-end",
              margin: "5px",
              height: "40px",
            }}
          >
            Внести платеж
          </Button>
        </Box>
      </div>
      <DataGrid
        columns={columns}
        entityName="payment"
        dataGridName="payments"
        initialState={{
          sorting: {
            sortModel: [
              {
                field: "date",
                sort: "desc",
              },
            ],
          },
        }}
        {...props}
      />
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymensPage);
