/* eslint-disable no-nested-ternary */
/* eslint-disable eqeqeq */
/* eslint-disable no-restricted-globals */
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import dayjs from "dayjs";
import {
  MaskedInput,
  ReusableAutocomplete,
  ReusableTextField,
} from "../ReusableInputs/ReusableInputs";
import ConfirmDialog from "./ConfirmDialog";
import {
  addNewLead,
  updLeadByUpdContact,
  updateLead,
  updateLeadCreateContact,
  updateManager,
} from "../../redux/actions/leadsActions";
import "./CreateLeadDialog.scss";
import CreateContractWrapper from "../CreatingContractView/CreateContractWrapper";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import hintCities from "./helpersForModals/hintCitiesForAutocomlete";
// import ContactDialog from "./ContactDialog";
import { contactsApi, leadsApi } from "../../utils/api";
import { setDataForDialog } from "../../redux/actions/contactsActions";
import SimpleConfirmModal from "../ReusableSimpleConfirmatin/SimpleConfirmModal";
import { loaded, loading } from "../../redux/actions/globalActions";
import { log } from "../../utils/helpers/tracker";
// notice: this dialog we use when user press "создать лид" and create new lead, and when user do
// double click on leadCard (then we transfer lead as props and use dialog for update lead)

const CreateLeadDialog = ({ open, onClose, lead, callback, ...props }) => {
  // React.useEffect(() => {
  //   if (lead?.length === 0) {
  //     return;
  //   }
  //   console.log("ЛИД", lead);
  // }, [open]);

  const Allleads = useSelector(({ leads }) => leads.payload);
  const dispatch = useDispatch();
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const [localLoading, setLocalLoading] = React.useState(false);

  const initialInputsState = {
    id_lead: "",
    name: "",
    city: "",
    year_of_birth: "",
    phone: "",
    email: "",
    manager: "",
    interested: "",
    utm_source: "",
    utm_campaign: "",
    utm_term: "",
    comment: "",
    new_comment: "",
    date_due: "",
  };

  const errorInputsState = {
    name: "",
    city: "",
    new_comment: "",
    phone: "",
    date_due: "",
    utm_source: "",
  };

  const [inputs, setInputs] = React.useState(initialInputsState);
  const [error, setError] = React.useState(errorInputsState);
  const regNumbers = /^[0-9,Backspace]+$/;
  const [disqualifiedLid, setDisqualified] = React.useState(false);
  const [closingConfirm, setClosingConfirm] = React.useState(false);
  const [executor, setExecutor] = React.useState(null);
  const [rejectValue, setReject] = React.useState("");
  const [isValid, setIsValid] = React.useState(true);
  const manager = useSelector(({ entitiesTypes }) => entitiesTypes.user);
  const activeManagers = useSelector(
    ({ entitiesTypes }) => entitiesTypes.active_users
  );
  const programs = useSelector(({ entitiesTypes }) => entitiesTypes.course);
  const defaultUser = useSelector(({ user }) => user.info);
  const reject_reasons = useSelector(
    ({ entitiesTypes }) => entitiesTypes.reasons_cancelled
  );
  const sources = useSelector(({ entitiesTypes }) => entitiesTypes.utm_source);
  const [openCreateContract, setOpenCreateContract] = React.useState(false);
  const handleCreateContractOpen = () => {
    setOpenCreateContract(true);
  };
  const [updLead, setUpdLead] = React.useState(false);
  const [duplicateConfirm, setDuplicateConfirm] = React.useState(false);

  const initialChangeTrackerState = {
    changedInputs: "",
    isInputsChange: false,
  };
  const [changeTracker, setChangeTracker] = React.useState(
    initialChangeTrackerState
  );

  function calculateAge(yearOfBirth) {
    const currentYear = new Date().getFullYear();
    const birth = new Date(yearOfBirth).getFullYear();
    return currentYear - birth;
  }

  const clearInputs = () => {
    setInputs(initialInputsState);
    setReject("");
    setDisqualified(false);
    setExecutor(null);
    setError(errorInputsState);
    setChangeTracker(initialChangeTrackerState);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    clearInputs();
    onClose();
    setIsValid(true);
  };

  function removeDuplicates(string) {
    const words = string.split(",");
    const uniqueWords = Array.from(new Set(words));
    const output = uniqueWords.join(",");
    return output;
  }

  const handleCloseWithConfirm = () => {
    if (changeTracker.isInputsChange === true) {
      setClosingConfirm(true);
      setIsValid(true);
    }
    if (changeTracker.isInputsChange === false) {
      // add log to tracker
      log(
        "Lead Closed without changes",
        {
          modified_lead: inputs,
          manager: inputs.manager,
          viewTime: new Date().toISOString(),
        },
        { type: "Lead Interaction" }
      );
      console.log(
        "(Lead Closed without changes) lead:",
        inputs,
        "viewTime:",
        new Date().toISOString()
      );
      clearInputs();
      setIsValid(true);
      onClose();
    }
  };

  const handleOpenDuplicateConfirm = () => {
    setDuplicateConfirm(true);
  };

  const handleUpdateLeadAsDuplicate = () => {
    dispatch(updateLead({ ...inputs, contact: false, duplicate: true }));
    handleClose();
    clearInputs();
  };

  const [isLinksWindowOpen, setIsLinksWindowOpen] = React.useState(false);

  const openLinksWindow = () => {
    setIsLinksWindowOpen(true);
  };
  const closeLinksWindow = () => {
    setIsLinksWindowOpen(false);
  };

  const handleLinksConfirm = () => {
    closeLinksWindow();
  };

  const [currentContact, setCurrentContact] = React.useState(null);
  const handleGoToContactDialog = () => {
    callback();
    console.log("КОНТАКТ", currentContact);
  };

  const handleInputsChange = (event) => {
    const { name, value } = event.target;

    setInputs((prevInputsState) => ({
      ...prevInputsState,
      [name]: value,
    }));
    setError((prevInputsState) => ({
      ...prevInputsState,
      [name]: value,
    }));
    if (name === "date_due") {
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        date_due: `${value?.split("T").join(" ")}:00`,
      }));
      setError((prevInputsState) => ({
        ...prevInputsState,
        date_due: `${value?.split("T").join(" ")}:00`,
      }));
    }
    setChangeTracker((prevCheckerState) => ({
      ...prevCheckerState,
      changedInputs: removeDuplicates(
        `${prevCheckerState.changedInputs},${name}`
      ),
      isInputsChange: true,
    }));
  };

  const openContact = (id) => {
    if (!id) {
      dispatch(loaded());
      return;
    }
    dispatch(loading());
    contactsApi.getHistory(id).then((data) => {
      dispatch(
        setDataForDialog({
          currentHistory: data.data,
          currentContact: data.data.contact,
        })
      );
      setCurrentContact(data.data.contact);
      // lead.id_contact = data.data.contact.id_contact.toString();
      handleGoToContactDialog();
      dispatch(loaded());
    });
  };

  // open contact without reloading the page
  const openContactWithoutReload = (id) => {
    if (!id) {
      setLocalLoading(false);
      return;
    }
    setLocalLoading(true);
    contactsApi.getHistory(id).then((data) => {
      dispatch(
        setDataForDialog({
          currentHistory: data.data,
          currentContact: data.data.contact,
        })
      );
      setCurrentContact(data.data.contact);
      lead.id_contact = data.data.contact.id_contact.toString();
      handleGoToContactDialog();
      setLocalLoading(false);
    });
  };

  const handleSubmitLead = () => {
    if (
      inputs.city &&
      inputs.date_due &&
      inputs.name &&
      inputs.phone &&
      inputs.new_comment &&
      inputs.utm_source
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
      return;
    }
    const rejectBoolean = Boolean(rejectValue);
    if (rejectBoolean) {
      dispatch(
        addNewLead({ ...inputs, rejected: rejectValue, contact: false })
      );
      // if contact:true we create new contact using lead data
    }
    if (!disqualifiedLid && !rejectBoolean) {
      dispatch(addNewLead({ ...inputs, contact: false }));
    }
    handleClose();
    clearInputs();
  };

  const handleUpdateLead = (event) => {
    if (
      inputs.city &&
      inputs.date_due &&
      inputs.name &&
      inputs.phone &&
      inputs.new_comment &&
      inputs.utm_source
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
      return;
    }
    // add log to tracker
    log(
      "Lead Closed",
      {
        modified_lead: inputs,
        manager: inputs.manager,
        viewTime: new Date().toISOString(),
      },
      { type: "Lead Interaction" }
    );
    console.log(
      "(Lead Closed).modified_lead:",
      inputs,
      "viewTime:",
      new Date().toISOString()
    );
    const rejectBoolean = Boolean(rejectValue);
    if (rejectBoolean) {
      dispatch(
        updateLead({ ...inputs, rejected: rejectValue, contact: false })
      );
    }
    if (!disqualifiedLid && !rejectBoolean && inputs?.id_contact !== "0")
      dispatch(updateLead({ ...inputs, contact: false }));
    if (
      !disqualifiedLid &&
      !rejectBoolean &&
      inputs?.id_contact === "0" &&
      event.target.id === "upd_lead"
    ) {
      dispatch(updateLead({ ...inputs, contact: false }));
    }
    if (
      !disqualifiedLid &&
      !rejectBoolean &&
      inputs?.id_contact === "0" &&
      event?.target?.id !== "upd_lead" // if we create new contact only !!!!
      // "создать контакт" в карточке лида
    ) {
      setLocalLoading(true);
      dispatch(updateLeadCreateContact({ ...inputs, contact: true })).then(
        (response) => {
          setCurrentContact(response);
          setLocalLoading(false);
          openContactWithoutReload(response.id_contact);
        }
      );
    }
    handleClose();
    clearInputs();
  };

  const handleLeadAndContactCreate = () => {
    if (
      inputs.city &&
      inputs.date_due &&
      inputs.name &&
      inputs.phone &&
      inputs.new_comment &&
      inputs.utm_source
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
      return;
    }
    // add log to tracker
    log(
      "Lead Closed and Contact Created",
      {
        modified_lead: inputs,
        manager: inputs.manager.toString(),
        viewTime: new Date().toISOString(),
      },
      { type: "Lead Interaction" }
    );
    console.log(
      "(Lead Closed and Contact Created) modified_lead:",
      inputs,
      "viewTime:",
      new Date().toISOString()
    );
    // if contact:true we create new contact using lead data
    // "создать лид и контакт" в карточке лидов в шапке сайта
    setLocalLoading(true);
    dispatch(addNewLead({ ...inputs, contact: true })).then((response) => {
      if (!response.contact) {
        alert("Такой контакт уже существует.Вы можете создать только лид");
        setLocalLoading(false);
        return;
      }
      setCurrentContact(response.contact);
      setLocalLoading(false);
      openContact(response.contact.id_contact);
    });
    handleClose();
    clearInputs();
  };

  const handleAddDisqualified = () => {
    setError({
      name: inputs.name,
      new_comment: inputs.new_comment,
    });
    if (inputs.name && inputs.new_comment) {
      setIsValid(true);
    } else {
      setIsValid(false);
      return;
    }
    setDisqualified(!disqualifiedLid);
  };

  const confirmDisqualified = () => {
    if (inputs.name && inputs.new_comment) {
      setIsValid(true);
    } else {
      setIsValid(false);
      return;
    }
    // add log to tracker
    log(
      "Lead Disqualified",
      {
        modified_lead: inputs,
        manager: inputs.manager.toString(),
        viewTime: new Date().toISOString(),
      },
      { type: "Lead Interaction" }
    );
    console.log(
      "(Lead Disqualified) modified_lead:",
      inputs,
      "viewTime:",
      new Date().toISOString()
    );
    updLead
      ? dispatch(updateLead({ ...inputs, disqualified: true, contact: false }))
      : dispatch(addNewLead({ ...inputs, disqualified: true, contact: false }));
    handleClose();
    clearInputs();
  };

  const [numeric, setNumeric] = React.useState(true);
  React.useEffect(() => {
    if (lead) {
      let commentString = "";
      typeof lead.comment === "string"
        ? (commentString = "")
        : (commentString = JSON.stringify(lead.comment));
      setUpdLead(true);
      // old leads from Bitrix have an unsupported format. We look, if this is an old utm,
      // then we erase it, if it is already in the required format, we take it from the lead
      const isNumeric = !isNaN(Number(lead.utm_source));
      setNumeric(isNumeric);
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        ...lead,
        comment: commentString,
        utm_source: lead.utm_source,
      }));
      setError((prevInputsState) => ({
        ...prevInputsState,
        ...lead,
        utm_source: lead.utm_source,
      }));
      setIsValid(true);
    }
  }, [open, lead, lead?.name, lead?.city, lead?.email, lead?.phone]);

  React.useEffect(() => {
    if (!defaultUser) {
      setExecutor({
        value: "40",
        label: "Татьяна Николаевна",
      });
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        manager: "40",
      }));
      setError((prevInputsState) => ({
        ...prevInputsState,
      }));
    }
    if (!updLead) {
      setExecutor({
        value: defaultUser.id.toString(),
        label: defaultUser.short_name,
      });
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        manager: defaultUser.id.toString(),
      }));
      setError((prevInputsState) => ({
        ...prevInputsState,
      }));
    } else {
      setExecutor({
        value: lead.manager.toString(),
        label: findLabelByID(lead.manager, manager),
      });
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        manager: lead.manager.toString(),
      }));
    }
  }, [lead, open]);

  const [duplicate, setDuplicate] = React.useState(false);

  React.useEffect(() => {
    if (!updLead || !lead.id_lead) {
      return;
    }
    leadsApi.checkDuplicate(lead.id_lead).then((response) => {
      setDuplicate(response.data.duplicate);
    });
  }, [open]);

  React.useEffect(() => {
    if (!lead || !lead.id_contact) return;
    if (lead.id_contact !== "0") {
      contactsApi.getHistory(lead.id_contact).then((data) => {
        // setHistory(data.data);
        dispatch(
          setDataForDialog({
            currentHistory: data.data,
            currentContact: data.data.contact,
          })
        );
        setCurrentContact(data.data.contact);
      });
    }
  }, [open, lead, updateLead]);

  React.useEffect(() => {
    const updated = Allleads.find((item) => item?.id_lead == lead?.id_lead);
    if (!updated) {
      return;
    }
    let commentString = "";
    typeof updated.comment === "string"
      ? (commentString = "")
      : (commentString = JSON.stringify(updated.comment));
    setUpdLead(true);
    // old leads from Bitrix have an unsupported format. We look, if this is an old utm,
    // then we erase it, if it is already in the required format, we take it from the lead
    const isNumeric = !isNaN(Number(updated.utm_source));
    setNumeric(isNumeric);
    setInputs((prevInputsState) => ({
      ...prevInputsState,
      ...updated,
      comment: commentString,
      utm_source: updated.utm_source,
    }));
    setError((prevInputsState) => ({
      ...prevInputsState,
      ...updated,
      utm_source: updated.utm_source,
    }));
    setIsValid(true);
  }, [props.forceUpd]);

  return (
    <div>
      <Backdrop open={localLoading} sx={{ color: "#fff", zIndex: 1400 }}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle
          style={{
            background: "#e5f4ff",
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <span>
            {updLead ? `Лид создан ${lead?.date_created}` : `Новый лид`}
          </span>
          <Button
            onClick={handleOpenDuplicateConfirm}
            disabled={!duplicate}
            color="warning"
            size="small"
            variant="contained"
          >
            это дубликат
          </Button>
        </DialogTitle>

        <DialogContent style={{ background: "#e5f4ff" }}>
          <DialogContentText component="span">
            <span
              className="create__lead_wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                background: "rgba(82,92,105,.15)",
                padding: "5px 30px 30px 30px",
                borderRadius: "10px",
                width: "90%",
              }}
            >
              <div style={{ textAlign: "end" }}>
                {inputs.year_of_birth.length < 4 ||
                inputs.year_of_birth.length > 10 ? (
                  <span style={{ opacity: 0 }}>age</span>
                ) : (
                  `Возраст ребенка: ${calculateAge(inputs.year_of_birth)} ${
                    calculateAge(inputs.year_of_birth) % 10 === 1
                      ? "год"
                      : calculateAge(inputs.year_of_birth) % 10 === 2 ||
                        calculateAge(inputs.year_of_birth) % 10 === 3 ||
                        calculateAge(inputs.year_of_birth) % 10 === 4
                      ? "года"
                      : "лет"
                  }`
                )}
              </div>

              <ReusableTextField
                type="text"
                label="Имя"
                value={inputs.name}
                name="name"
                onChange={handleInputsChange}
                inputProps={
                  lead && lead?.id_contact !== "0" && lead.status !== "new"
                    ? {
                        readOnly: true,
                        style: { backgroundColor: "#EEEEEE" },
                      }
                    : {}
                }
              />
              {!isValid && error.name === "" && (
                <div className="text-error">Это поле не должно быть пустым</div>
              )}
              <ReusableTextField
                type="number"
                label="Tелефон"
                value={inputs.phone}
                name="phone"
                onChange={handleInputsChange}
                inputProps={
                  lead && lead?.id_contact !== "0"
                    ? {
                        readOnly: true,
                        style: { backgroundColor: "#EEEEEE" },
                      }
                    : {}
                }
                onKeyDown={(event) => {
                  if (!regNumbers.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {!isValid && error.phone === "" && (
                <div className="text-error">
                  Это поле не должно быть пустым, вводите только цифры
                </div>
              )}
              {/* -----not reusable autocomlete with default cities + what user write ------ */}
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "5px",
                }}
              >
                <div
                  style={{
                    marginRight: "5px",
                    minWidth: "120px",
                    fontSize: "14px",
                  }}
                >
                  Город
                </div>
                <Autocomplete
                  freeSolo
                  autoSelect
                  size="small"
                  fullWidth
                  options={hintCities}
                  value={inputs.city}
                  onChange={(event, newValue) => {
                    setInputs((prevInputsState) => ({
                      ...prevInputsState,
                      city: newValue || "",
                    }));
                    setError((prevInputsState) => ({
                      ...prevInputsState,
                      city: newValue || "",
                    }));
                    setChangeTracker((prevCheckerState) => ({
                      ...prevCheckerState,
                      changedInputs: removeDuplicates(
                        `${prevCheckerState.changedInputs},${"city"}`
                      ),
                      isInputsChange: true,
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{
                    backgroundColor:
                      lead && lead?.id_contact !== "0" ? "#EEEEEE" : "#fff",
                  }}
                  readOnly={
                    lead && lead?.id_contact !== "0" && lead.status !== "new"
                  }
                />
                {!isValid && error.city === "" && (
                  <div className="text-error">
                    Это поле не должно быть пустым
                  </div>
                )}
              </div>
              <MaskedInput
                value={inputs.year_of_birth}
                label="Год рождения ребенка(ГГГГ- или ГГГГ-ММ-ДД)"
                onChange={handleInputsChange}
                formatChars={{
                  y: "[0-2]",
                  9: "[0-9]",
                  n: "[0-1]",
                  m: "[0-9]",
                  z: "[0-3]",
                }}
                type="tel"
                name="year_of_birth"
                placeholder="ГГГГ- или ГГГГ-ММ-ДД"
              />

              <ReusableTextField
                type="email"
                label="email"
                value={inputs.email}
                name="email"
                onChange={handleInputsChange}
                inputProps={
                  lead && lead?.id_contact !== "0" && lead.status !== "new"
                    ? {
                        readOnly: true,
                        style: { backgroundColor: "#EEEEEE" },
                      }
                    : {}
                }
              />
              <ReusableAutocomplete
                label="Ответственный"
                freeSolo={false}
                size="small"
                name="manager"
                value={executor}
                onChange={(event, newValue) => {
                  setExecutor(newValue);
                  setInputs((prevInputsState) => ({
                    ...prevInputsState,
                    manager: newValue ? newValue.value.toString() : "",
                  }));
                  setError((prevInputsState) => ({
                    ...prevInputsState,
                    manager: newValue ? newValue.value.toString() : "",
                  }));
                  if (updLead && newValue) {
                    dispatch(
                      updateManager({
                        ...inputs,
                        comment: JSON.parse(inputs.comment),
                        manager: newValue.value.toString(),
                      })
                    );
                  }
                }}
                options={activeManagers}
                // getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => <TextField {...params} />}
              />
              <ReusableTextField
                select
                label="Интересующие программы"
                value={inputs.interested}
                name="interested"
                onChange={handleInputsChange}
                // onChange={(event) => {
                //   setInputs((prevInputsState) => ({
                //     ...prevInputsState,
                //     interested: event.target ? event.target.value : "",
                //   }));
                //   setError((prevInputsState) => ({
                //     ...prevInputsState,
                //     interested: event.target ? event.target.value : "",
                //   }));
                // }}
                options={programs}
              />
              <ReusableTextField
                freeSolo={!numeric}
                select={numeric}
                readOnly={!numeric}
                label="Источники"
                name="utm_source"
                value={inputs.utm_source}
                onChange={handleInputsChange}
                options={sources}
                inputProps={{
                  readOnly: !numeric,
                  style: { backgroundColor: "#EEEEEE" },
                }}
              />
              {!isValid && error.utm_source === "" && (
                <div className="text-error">Это поле не должно быть пустым</div>
              )}
              <div className="input__label">Дата следующего контакта</div>
              <input
                className="input__datepicker"
                label="Дата следующего контакта"
                type="datetime-local"
                name="date_due"
                min={new Date().toISOString().slice(0, 16)}
                value={inputs.date_due}
                onChange={handleInputsChange}
              />
              {!isValid && error.date_due === "" && (
                <div className="text-error">Это поле не должно быть пустым</div>
              )}
              {inputs.comment === "" || inputs.comment === undefined ? null : (
                <>
                  <span>комментарии: </span>
                  {JSON.parse(inputs.comment).map((comment) => (
                    <div key={uuidv4()}>
                      {comment.date} : {comment.comment}{" "}
                    </div>
                  ))}
                </>
              )}
              <ReusableTextField
                type="text"
                multiline
                label="Добавить комментарий"
                value={inputs.new_comment}
                name="new_comment"
                onChange={handleInputsChange}
              />
              {!isValid && error.new_comment === "" && (
                <div className="text-error">Это поле не должно быть пустым</div>
              )}
              <Divider textAlign="left" sx={{ margin: "20px" }}>
                в некачественные/отказ{" "}
              </Divider>
              <div style={{ display: "flex" }}>
                <div style={{ width: "45%" }}>
                  <TextField
                    size="small"
                    fullWidth
                    className="reject__lead_select"
                    sx={{
                      "& label": {
                        color: "#fff",
                        fontFamily: "Nunito Sans",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "1.75",
                        alignItems: "center",
                        textTransform: "uppercase",
                      },
                      backgroundColor: "#2999DD",
                      boxShadow:
                        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                    }}
                    select
                    label="Лид с отказом"
                    name="reject"
                    value={rejectValue || ""}
                    onChange={(event) => {
                      // if(event.target.value === "") {
                      //   document.querySelector('.MuiSelect-input').blur();
                      // }
                      setReject(event.target.value);
                    }}
                  >
                    {reject_reasons &&
                      reject_reasons.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
                <div style={{ width: "45%", marginLeft: "20px" }}>
                  <Tooltip
                    title="Отметить как некачественный"
                    placement="top-start"
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={Boolean(rejectValue)}
                      onClick={handleAddDisqualified}
                      sx={{
                        backgroundColor: "#2999DD",
                      }}
                      className="disqualified__lead_btn"
                    >
                      Некачественный
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </span>
          </DialogContentText>{" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
              marginTop: "10px",
            }}
          >
            {updLead && (
              <Button
                onClick={handleUpdateLead}
                variant="contained"
                id="upd_lead"
                className="update__lead_btn"
              >
                {" "}
                Обновить лид{" "}
              </Button>
            )}
            {updLead && inputs.id_contact !== "0" ? (
              <Button
                onClick={handleCreateContractOpen}
                variant="contained"
                className="create__contact_btn"
              >
                {" "}
                Создать договор{" "}
              </Button>
            ) : null}
            {!inputs.id_lead ? (
              <Button
                onClick={handleLeadAndContactCreate}
                variant="contained"
                className="create__lead_and_contact_btn"
                // disabled={updLead}
              >
                {" "}
                Создать лид и контакт{" "}
              </Button>
            ) : null}
            {updLead && inputs.id_contact === "0" ? (
              <Button
                onClick={handleUpdateLead}
                variant="contained"
                className="create__lead_and_contact_btn"
                // disabled={updLead}
              >
                {" "}
                Создать контакт{" "}
              </Button>
            ) : null}
            <Button
              variant="contained"
              onClick={handleGoToContactDialog}
              className="to_contact_btn"
              disabled={
                inputs.id_contact === "0" ||
                !lead ||
                props.type === "disable_goContact_btn"
              }
            >
              {" "}
              В контакт{" "}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithConfirm}>Закрыть</Button>
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={disqualifiedLid}
        setOpen={setDisqualified}
        onConfirm={confirmDisqualified}
        closeAction={() => {}}
        title="Подтвердите действие"
      >
        Отметить лид как некачественный?
      </ConfirmDialog>
      <ConfirmDialog
        open={Boolean(rejectValue)}
        setOpen={setReject}
        onConfirm={updLead ? handleUpdateLead : handleSubmitLead}
        closeAction={() => setReject("")}
        title="Подтвердите действие"
      >
        Сохранить Лид с отказом? {findLabelByID(rejectValue, reject_reasons)}
      </ConfirmDialog>
      <ConfirmDialog
        open={closingConfirm}
        setOpen={setClosingConfirm}
        onConfirm={updLead ? handleUpdateLead : handleSubmitLead}
        closeAction={() => {
          clearInputs();
          onClose();
        }}
        title="Лид не сохранен"
      >
        Вы не сохранили изменения. Сохранить лид перед закрытием?
        <p style={{ fontSize: "14px" }}>(Esc - вернуться к лиду)</p>
      </ConfirmDialog>
      <ConfirmDialog
        open={Boolean(duplicateConfirm)}
        setOpen={setDuplicateConfirm}
        onConfirm={handleUpdateLeadAsDuplicate}
        closeAction={() => {
          setDuplicateConfirm(false);
          clearInputs();
          onClose();
        }}
        title="Подтвердите действие"
      >
        Отметить Лид как дубликат?
        <p style={{ fontSize: "14px" }}>(Esc - вернуться к лиду)</p>
      </ConfirmDialog>
      <CreateContractWrapper
        open={openCreateContract}
        lead={lead}
        setOpen={setOpenCreateContract}
      />
      <SimpleConfirmModal
        isOpen={isLinksWindowOpen}
        onClose={closeLinksWindow}
        onConfirm={handleLinksConfirm}
        content={JSON.stringify({ лид: lead })}
      />
    </div>
  );
};

export default CreateLeadDialog;
