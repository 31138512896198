/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */

import React from "react";
import "./CreatingCustomersView.scss";
import "../../scss/index.scss";
import { v4 as uuidv4 } from "uuid";
import CloseIcon from "@mui/icons-material/Close";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
} from "@mui/material";

const CustomersDialog = ({
  customers,
  open,
  addCustomer,
  openDialogCallback,
}) => {
  const callback = () => {
    openDialogCallback(false);
  };

  const [openDialog, setOpenDialog] = React.useState(false);
  const closeDialog = () => {
    setOpenDialog(false);
    callback();
  };

  React.useEffect(() => {
    setOpenDialog(open);
  }, [open]);

  const handleSubmitCustomers = () => {
    if (customers.length === 0) {
      return;
    }
    const submitData = {
      customers,
    };
    console.log(submitData);
    addCustomer(submitData).then((response) => console.log("DATA", response));
  };

  return (
    <>
      <Dialog
        className="customers__dialog"
        open={openDialog}
        onClose={closeDialog}
        scroll="paper"
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
        maxWidth="md"
        PaperProps={{
          sx: {
            width: "60%",
            maxHeight: 300,
          },
        }}
      >
        <DialogTitle style={{display: "flex", flexDirection: "row", justifyContent: "space-between"}} >
        <div className="customers__title">Добавить Пользователя:</div>
          <div
            style={{ float: "right", cursor: "pointer" }}
            title="зыкрыть"
            onClick={closeDialog}
          >
            <CloseIcon />
          </div>
        </DialogTitle>
        <DialogContent dividers>
          <DialogContentText>
            <div className="customers__info">
              {customers?.map((item) => (
                <div key={uuidv4()} className="customers__info_container">
                  {Object.keys(item).map((key) => {
                    if (key === "contract_id") {
                      return;
                    }
                    if (key === "role" && item[key].includes("11111")) {
                      return (
                        <div className="customers__info_item">
                          <div className="customers__field_name">Роль:</div>
                          <div className="customers__person_data">Ребенок</div>
                        </div>
                      );
                    }
                    if (key === "role" && item[key].includes("8000")) {
                      return (
                        <div className="customers__info_item">
                          <div className="customers__field_name">Роль:</div>
                          <div className="customers__person_data">Родитель</div>
                        </div>
                      );
                    }
                    if (key === "full_name" ) {
                      return (
                        <div className="customers__info_item">
                          <div className="customers__field_name">ФИО:</div>
                          <div className="customers__person_data">{item[key]}</div>
                        </div>
                      );
                    }
                    if (key === "email" ) {
                      return (
                        <div className="customers__info_item">
                          <div className="customers__field_name">Email:</div>
                          <div className="customers__person_data">{item[key]}</div>
                        </div>
                      );
                    }
                    if (key === "number" ) {
                      return (
                        <div className="customers__info_item">
                          <div className="customers__field_name">№ Договора:</div>
                          <div className="customers__person_data">{item[key]}</div>
                        </div>
                      );
                    }
                  })}
                </div>
              ))}
            </div>
          </DialogContentText>
        </DialogContent>
        <Divider />
        <DialogActions>
          <div className="confirm__buttons">
            <Button onClick={closeDialog} variant="contained" color="grey">
              Отменить
            </Button>
            <Button
              variant="contained"
              style={
                customers.length > 0
                  ? {
                      opacity: 1,
                      transition: "all 0.7s ease",
                      marginLeft: "1%",
                    }
                  : {
                      opacity: 0,
                      transition: "all 0.7s ease",
                      marginLeft: "1%",
                    }
              }
              onClick={handleSubmitCustomers}
            >
              ОК
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
};
export default CustomersDialog;
