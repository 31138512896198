import { MenuItem } from "@mui/material";
import React from "react";
import { v4 as uuidv4 } from "uuid";

const activeUsersSelection = (allUsers, activeUsers) => {
  if (!allUsers) {
    return (
      <MenuItem key={uuidv4()} value="none">
        none
      </MenuItem>
    );
  }

  // Sort allUsers, activeUsers alphabetically by label
  const sortedAllUsers = allUsers.sort((a, b) => (a.label > b.label ? 1 : -1));
  const sortedActiveUsers = activeUsers?.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );
  const inactiveUsers = sortedAllUsers.filter(
    (item) => !sortedActiveUsers?.find((active) => active.value === item.value)
  );
  const activeUsersComponents = sortedActiveUsers.map((activeUser) => {
    const activeUserItem = sortedAllUsers.find(
      (user) => user.value === activeUser.value
    );
    return (
      <MenuItem key={activeUser.value} value={activeUser.value}>
        {activeUserItem.label}
      </MenuItem>
    );
  });

  const menuItems = [
    ...activeUsersComponents,
    ...inactiveUsers.map((item) => (
      <MenuItem key={item.value} disabled value={item.value}>
        {item.label}
      </MenuItem>
    )),
  ];

  return menuItems;
};

export default activeUsersSelection;
