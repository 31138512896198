import { paymentsApi } from '../../utils/api';
import notification from '../../utils/notification/notification';

export const setPayments = (data) => ({
  type: 'SET_PAYMENTS',
  payload: data,
  loading: false,
});

export const loadingPayments = () => ({
  type: "LOADING_PAYMENTS",
  loading: true,
});

export const addPayment = (data) => ({
  type: 'ADD_PAYMENT',
  payload: data,
  loading: false,
});

export const updatePayment = (data) => ({
  type: 'UPDATE_PAYMENT',
  payload: data,
  loading: false,
});

export const clearPayments = () => ({
  type: 'CLEAR_PAYMENTS',
});

export const fetchPayments = () => (dispatch) => {
  dispatch(loadingPayments());
  paymentsApi.get()
    .then(({ data }) => dispatch(setPayments(data.payments)));
};

export const sendPayment = (obj) => (dispatch) => {
  dispatch(loadingPayments());
  paymentsApi.add({ ...obj })
    .then(({ data }) => {
      notification.success('Платеж добавлен');
      dispatch(addPayment(data.payment));
    });
};
export const sendUpdatedPayment = (obj) => (dispatch) => {
  dispatch(loadingPayments());
  paymentsApi.update({ ...obj })
    .then(({ data }) => {
      notification.success('Платеж обновлен');
      dispatch(updatePayment(data.payment));
    });
};
