import React from "react";
import { useSelector } from "react-redux";

const Content = React.memo(({ children }) => {
  const hideSidebar = useSelector(({ globalState }) => globalState.hideSidebar);

  return (
    <div className={`content ${hideSidebar ? "fullsize" : null}`}>
      <div className="container">{children}</div>
    </div>
  );
});
export default Content;
