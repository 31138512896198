/* eslint-disable max-len */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import "./CreatingContractMain.scss";
import { TextField, MenuItem, Button } from "@material-ui/core";
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CreateConfirmDialog from "./CreateConfirmDialog";
import { checkEmptyFields } from "./CreatingAcademicYear";
import notification from "../../utils/notification/notification";
import { contractApi } from "../../utils/api";
import { updContactFromCreateContract } from "../../redux/actions/contactsActions";
import { fetchFakeLeads, updLead } from "../../redux/actions/leadsActions";
import filterArray from "../../utils/helpers/forCreateContract";
import ContractTemplates from "../ContractTemplates/ContractTemplates";

function CreatingCamp({ data, entities, lead, contact, lastLeadId }) {
  const { location, requisites, nutrition, schedule } = entities;
  const { courses, groups } = data;
  const today = new Date().toISOString().split("T")[0];
  const coursesOnlyCamp = courses
    ?.slice()
    .filter((course) => course.type === "camp_program");
  const defaultUser = useSelector(({ user }) => user.info);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [disableActionBtns, setDisableActionBtns] = React.useState(false);

  const dispatch = useDispatch();

  // console.log('contact', contact);
  // console.log('lead', lead);
  // console.log('lastLeadId', lastLeadId);

  const [filteredGroups, setFilteredGroups] = React.useState(groups);

  const campStateInitial = {
    lessons_type: "camp",
    id_lead: lead?.id_lead?.toString() || lastLeadId || "",
    full_name: contact?.name || "",
    kid_name: contact?.kid_name || "",
    year_of_birth:
      contact?.year_of_birth.toString() ||
      lead?.year_of_birth?.toString() ||
      "",
    passport_contact: contact?.passport_contact || "",
    registration_contact: contact?.registration_contact || "",
    city: contact?.city || "",
    location: "",
    "camp-program": "",
    requisite: "",
    group: "",
    nutrition: "",
    schedule: "",
    campaign_start: today,
    campaign_end: today,
    first_visit: today,
    last_visit: today,
    day_price: "",
    total_days: "",
    full_price_camp: "",
    prepayment: "",
    user_id: defaultUser.id.toString() || "",
    contact_id:
      contact?.id_contact.toString() || lead?.id_contact.toString() || "",
    payments: [
      { date: today, amount: "11", count_week: "11" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
      { date: "", amount: "", count_week: "" },
    ],
    utm_source: lead?.utm_source || "",
    utm_campaign: "",
    utm_term: "",
  };

  const [inputs, setInputs] = React.useState(campStateInitial);

  const [tableData, setTableData] = useState([
    { date: today, amount: "11", count_week: "11" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
    { date: "", amount: "", count_week: "" },
  ]);

  const handleDataChange = (index, field, value) => {
    const updatedData = [...tableData];
    updatedData[index][field] = value;
    setTableData(updatedData);
    // eslint-disable-next-line no-return-assign
    setInputs((prevInputsState) => ({
      ...prevInputsState,
      payments: updatedData,
      // ...prevInputsState.payments[index][field] = value
    }));
  };

  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    setInputs((prevInputsState) => ({
      ...prevInputsState,
      [name]: value,
    }));
    if (name === "location") {
      const filteredArray = filterArray(groups, inputs["camp-program"], value);
      setFilteredGroups(() => [...filteredArray]);
      setInputs((prevInputsState) => ({
        ...prevInputsState,
      }));
    }
    if (name === "camp-program") {
      setInputs((prevInputsState) => ({
        ...prevInputsState,
      }));
      const filteredArray = filterArray(groups, value, inputs.location);
      setFilteredGroups([...filteredArray]);
      // console.log(inputs);
    }
  };

  const [contractIinfo, setContractInfo] = React.useState("");
  const [createError, setCreateError] = React.useState(false);

  // TODO: вынести запросы, переписать через апишку все(вл всех 3х компонентах Camp, AcademicYear, Individual)
  const handleSubmit = (event) => {
    event.preventDefault();
    if (checkEmptyFields(inputs)) {
      notification.info(`вы не заполнили ${checkEmptyFields(inputs)}`);
      return;
    }
    contractApi
      .create({ contract: inputs })
      .then((response) => {
        setContractInfo(response.data);
        notification.success("Договор создан");
        dispatch(
          updContactFromCreateContract({
            ...inputs,
            id_contact: inputs.contact_id,
            name: inputs.full_name,
          })
        );
        notification.success("Данные контакта обновлены");
        // dispatch(
        //   updLead({
        //     ...inputs,
        //     id_contact: inputs.contact_id,
        //     name: inputs.full_name,
        //   })
        // );
        dispatch(fetchFakeLeads());
        notification.success("Данные Лидов обновлены");
      })
      .catch((e) => {
        setCreateError(e.message);
        notification.error("Не удалось создать договор");
      });
    setOpenConfirmation(true);
  };

  const deleteContract = () => {
    contractApi.remove({ contract: contractIinfo.contract_id }).then((resp) => {
      if (resp.data.status === "OK") {
        notification.success("Договор удален");
        setDisableActionBtns(true);
      }
    });
    setOpenConfirmation(true);
  };

  const saveContract = () => {
    contractApi.save(contractIinfo.file_name).then((resp) => {
      const href = URL.createObjectURL(resp.data);
      const link = document.createElement("a");
      // create "a" HTML element with href to file & click
      // const link = document.createElement('a');
      link.href = href;
      link.setAttribute("download", `${contractIinfo.file_name}`);
      document.body.appendChild(link);
      // clean up "a" element & remove ObjectURL
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      notification.success("Договор сохранен");
      setOpenConfirmation(true);
    });
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            marginRight: "50px",
          }}
        >
          <TextField
            margin="dense"
            size="small"
            name="full_name"
            label="ФИО Родителя"
            value={inputs.full_name}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="ФИО Ребенка"
            name="kid_name"
            value={inputs.kid_name}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Год рождения ребенка"
            name="year_of_birth"
            value={inputs.year_of_birth}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Паспортные данные"
            name="passport_contact"
            value={inputs.passport_contact}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Данные о регистрации"
            name="registration_contact"
            value={inputs.registration_contact}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Локация"
            fullWidth
            select
            name="location"
            value={inputs.location}
            onChange={(event) => handleInputsChange(event)}
          >
            {location.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Реквизиты"
            fullWidth
            select
            name="requisite"
            value={inputs.requisite}
            onChange={(event) => handleInputsChange(event)}
          >
            {requisites.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Название программы"
            fullWidth
            select
            name="camp-program"
            value={inputs["camp-program"]}
            onChange={(event) => handleInputsChange(event)}
          >
            {coursesOnlyCamp.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Учебная группа"
            fullWidth
            select
            name="group"
            value={inputs.group}
            onChange={(event) => handleInputsChange(event)}
          >
            {filteredGroups.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.short_name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Питание"
            fullWidth
            select
            name="nutrition"
            value={inputs.nutrition}
            onChange={(event) => handleInputsChange(event)}
          >
            {nutrition.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Время пребывания ребенка"
            fullWidth
            select
            name="schedule"
            value={inputs.schedule}
            onChange={(event) => handleInputsChange(event)}
          >
            {schedule.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            disabled
            margin="dense"
            type="date"
            size="small"
            label="Начало акции"
            fullWidth
            InputLabelProps={{ shrink: true }}
            name="first_visit"
            value={inputs.campaign_start}
            onChange={(event) => handleInputsChange(event)}
          />
          <TextField
            disabled
            margin="dense"
            type="date"
            size="small"
            label="Окончание акции"
            fullWidth
            InputLabelProps={{ shrink: true }}
            name="last_visit"
            value={inputs.campaign_end}
            onChange={(event) => handleInputsChange(event)}
          />
          <TextField
            margin="dense"
            type="date"
            size="small"
            label="Первый день посещения"
            fullWidth
            InputLabelProps={{ shrink: true }}
            name="first_visit"
            value={inputs.first_visit}
            onChange={(event) => handleInputsChange(event)}
          />
          <TextField
            margin="dense"
            type="date"
            size="small"
            label="Последний день посещения"
            fullWidth
            InputLabelProps={{ shrink: true }}
            name="last_visit"
            value={inputs.last_visit}
            onChange={(event) => handleInputsChange(event)}
          />
          <TextField
            type="number"
            margin="dense"
            size="small"
            label="Стоимость дня"
            name="day_price"
            value={inputs.day_price}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            type="number"
            margin="dense"
            size="small"
            label="Итоговое количество дней"
            name="total_days"
            value={inputs.total_days}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            type="number"
            margin="dense"
            size="small"
            label="Полная стоимость"
            name="full_price_camp"
            value={inputs.full_price_camp}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            type="number"
            margin="dense"
            size="small"
            label="Предоплата"
            name="prepayment"
            value={inputs.prepayment}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
        </div>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            paddingTop: "10px",
          }}
        >
          <Table size="small" className="contract__payment_table">
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Сумма</TableCell>
                <TableCell>Кол-во недель</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {tableData.map((row, index) => (
                // eslint-disable-next-line react/no-array-index-key
                <TableRow key={index}>
                  <TableCell>
                    <TextField
                      InputProps={{
                        style: { borderRadius: "0" },
                      }}
                      size="small"
                      value={row.date}
                      type="date"
                      onChange={(e) =>
                        handleDataChange(index, "date", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      InputProps={{
                        style: { borderRadius: "0" },
                      }}
                      type="number"
                      size="small"
                      value={row.amount}
                      onChange={(e) =>
                        handleDataChange(index, "amount", e.target.value)
                      }
                    />
                  </TableCell>
                  <TableCell>
                    <TextField
                      InputProps={{
                        style: { borderRadius: "0" },
                      }}
                      type="number"
                      size="small"
                      value={row.count_week}
                      onChange={(e) =>
                        handleDataChange(index, "count_week", e.target.value)
                      }
                    />
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
          <div className="contract__actions">
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Создать договор
            </Button>
            <Button disabled variant="contained" color="primary">
              action
            </Button>
            <Button disabled variant="contained" color="primary">
              action
            </Button>
          </div>
        </div>
        <CreateConfirmDialog
          name={inputs?.full_name}
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          setError={setCreateError}
          error={createError}
          disableActionBtns={disableActionBtns}
          setDisableActionBtns={setDisableActionBtns}
          del={() => {
            deleteContract();
          }}
          save={() => {
            saveContract();
          }}
        />
      </div>
      <pre>{JSON.stringify(inputs, null, 2)}</pre>
    </>
  );
}

export default CreatingCamp;
