/* eslint-disable import/no-cycle */
import axios from "axios";
import { leadsApi } from "../../utils/api";
import notification from "../../utils/notification/notification";
import { addContact } from "./contactsActions";
import { log } from "../../utils/helpers/tracker";

export const getLeads = (data) => ({
  type: "GET_LEADS",
  payload: data,
  loading: false,
});

export const setPersonalLeads = (data) => ({
  type: "SET_PERSONAL_LEADS",
  payload: data,
  loading: false,
});

export const addLead = (data) => ({
  type: "ADD_LEAD",
  payload: data,
  loading: false,
});

export const updLead = (data) => ({
  type: "UPD_LEAD",
  payload: data,
  loading: false,
});

export const updLeadByUpdContact = (data) => ({
  type: "UPD_LEAD_BY_UPD_CONTACT",
  payload: data,
  loading: false,
});

export const updLeadStatus = (data) => ({
  type: "UPD_LEAD_STATUS",
  payload: data,
  loading: false,
});

export const updManager = (data) => ({
  type: "UPD_MANAGER",
  payload: data,
  loading: false,
});

export const loadingLeads = () => ({
  type: "LOADING_LEADS",
  loading: true,
});

export const setError = (error) => ({
  type: "SET_ERROR",
  payload: error,
  loading: false,
});

export const fetchLeads = () => (dispatch) => {
  dispatch(loadingLeads());
  return leadsApi
    .getLeads()
    .then(({ data }) => dispatch(getLeads(data.leads)))
    .catch((error) => {
      dispatch(setError(error));
    });
};

export const fetchFakeLeads = () => (dispatch) =>
  leadsApi
    .getLeads()
    .then(({ data }) => dispatch(getLeads(data.leads)))
    .catch((error) => {
      dispatch(setError(error));
    });

export const setUserPersonalLeads = () => (dispatch) => {
  axios.get("/notification/get").then((response) => {
    dispatch(setPersonalLeads(response.data.leads));
  });
};

// export const updByContact = (lead) => (dispatch) => {
// 	dispatch(loadingLeads());
// 	return leadsApi.addLead(lead).then(({ data }) => {
// 	  notification.success("Лид создан");
// 	  console.log("ЛИД добавлен", data);
// 	  dispatch(addLead(data.lead));
// 	  if (data.contact) {
// 		dispatch(addContact(data.contact));
// 		notification.success("Контакт создан");
// 	  }
// 	  // window.location.reload();
// 	});
//   };

export const addNewLead = (lead) => (dispatch) => {
  dispatch(loadingLeads());
  return leadsApi.addLead(lead).then(({ data }) => {
    notification.success("Лид создан");
    console.log("ЛИД добавлен", data);
    dispatch(addLead(data.lead));
    if (data.contact) {
      dispatch(addContact(data.contact));
      notification.success("Контакт создан");
    }
    return data; // we use data in createLeadDialog
  });
};

export const updateLead = (lead) => (dispatch) => {
  dispatch(loadingLeads());
  return leadsApi
    .updLead(lead)
    .then(({ data }) => {
      notification.success("Лид обновлен");
      if (data.contact === true) {
        dispatch(addContact(data));
        notification.success("Контакт создан");
      }
      dispatch(updLead(data));
      return data; // We use data in createLeadDialog as new created lead
    })
    .catch((error) => {
      log("Lead update", { error, lead });
      notification.error("Ошибка обновления лида");
    });
};

// eslint-disable-next-line consistent-return
export const updateLeadCreateContact = (lead) => async (dispatch) => {
  try {
    const response = await leadsApi.updLead(lead);
    const { data } = response;
    // Ensure data is not mutated by creating a deep copy
    const dataCopy = JSON.parse(JSON.stringify(data));
    notification.success("Лид обновлен");
    if (dataCopy.contact === true) {
      dispatch(addContact(dataCopy));
      notification.success("Контакт создан");
    }
    dispatch(updLead(dataCopy));
    return data; // We use dataCopy in createLeadDialog as new created contact
  } catch (error) {
    log("Lead update", { error, lead });
    notification.error("Ошибка обновления лида");
  }
};

export const updateManager = (lead) => (dispatch) =>
  // dispatch(loadingLeads());
  leadsApi.updManager(lead).then(({ data }) => {
    notification.success("Менеджер изменен");
    dispatch(updManager(data));
  });

export const updateLeadStatus = (lead) => (dispatch) =>
  // dispatch(loadingLeads());
  leadsApi.updLeadStatus(lead).then(({ data }) => {
    notification.success("Статус лида изменен");
    dispatch(updLeadStatus(data));
  });
