import { Button, TextField, Tooltip } from "@mui/material";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import InsertCommentIcon from "@mui/icons-material/InsertComment";
import HandshakeIcon from "@mui/icons-material/Handshake";
import dayjs from "dayjs";
import EditIcon from "@mui/icons-material/Edit";
import DoneOutlineTwoToneIcon from "@mui/icons-material/DoneOutlineTwoTone";
import { contractApi } from "../../utils/api";
import notification from "../../utils/notification/notification";
import CreateLeadDialog from "./CreateLeadDialog";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import "./CommentBody.scss";
import { editPost } from "../../redux/actions/contactsActions";
import ExtendDialog from "../../pages/Contracts/ExtendDialog";
import { getContractToExtend } from "../../redux/actions/contractActions";

const CommentBody = ({
  contactHistoryForRender,
  isEditMode,
  currentCommentId,
  handleEditClick,
  handleSaveClick,
  id_contact,
}) => {
  const allLeads = useSelector(({ leads }) => leads.payload);
  const allManagers = useSelector(({ entitiesTypes }) => entitiesTypes.user);
  const entities = useSelector(({ entitiesTypes }) => entitiesTypes);
  const [editableComment, setEditableComment] = React.useState("");
  const [editableInputValue, setEditableInputValue] = React.useState("");
  const [leadFromComments, setLead] = React.useState([]);
  const dispatch = useDispatch();

  const handleCommentChange = (e) => {
    setEditableInputValue(e.target.value);
  };

  const handleLocalEditClick = (comment) => {
    setEditableInputValue(comment.comment);
    setEditableComment(comment);
    handleEditClick(comment.id_comment);
  };

  // we use async function to handle the submit, to be able to call then() after dispatch
  const handlePostEditSubmit = async (data) => {
    const response = await dispatch(editPost(data));
    return response;
  };
  const handleLocalSaveClick = () => {
    const newComment = { ...editableComment, comment: editableInputValue };
    handlePostEditSubmit(newComment).then((resp) => {
      if (!resp?.id_comment) {
        handleSaveClick();
        return;
      }
      contactHistoryForRender.comment = editableInputValue;
      contactHistoryForRender.date_update = resp.date_update;
      contactHistoryForRender.autor_id = resp.autor_id;
      // forceUpdate();
      handleSaveClick();
    });
  };

  const [LeadModalOpen, setLeadModalOpen] = React.useState(false);
  const handleLeadOpen = () => {
    setLeadModalOpen(true);
  };
  const handleLeadClose = () => {
    setLeadModalOpen(false);
  };

  const openUpdateLead = (commonLead) => {
    if (commonLead) {
      setLead(commonLead);
      handleLeadOpen();
    } else {
      notification.info(
        `невозможно перейти в данный лид ${commonLead?.id_lead
        } ${typeof commonLead?.id_lead} `
      );
    }
  };

  const saveContract = (name) => {
    if (!name) {
      notification.info("невозможно скачать, договор удален");
      return;
    }
    contractApi.save(name).then((resp) => {
      const href = URL.createObjectURL(resp.data);
      const link = document.createElement("a");
      // create "a" HTML element with href to file & click
      // const link = document.createElement('a');
      link.href = href;
      link.setAttribute("download", `${name}`);
      document.body.appendChild(link);
      // clean up "a" element & remove ObjectURL
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      notification.success("Договор сохранен");
    });
  };

  const findLeadByID = (id, leads) => {
    if (id) {
      const finded = leads.find((item) => item.id_lead === id);
      return finded;
    }
    return "нет лида";
  };

  const translateStatus = (status) => {
    switch (status) {
      case "new":
        return "новый";
      case "rejected":
        return "отклонен";
      case "in_work":
        return "в работе";
      case "done":
        return "обработан";
      case "disqualified":
        return "некачественный";
      default:
        return "";
    }
  };

  const translateContractStatus = (status) => {
    switch (status) {
      case "new":
        return "новый";
      case "cancelled":
        return "отменен";
      case "active":
        return "активный";
      default:
        return "";
    }
  };

  const [openExtendDialog, setOpenExtendDialog] = React.useState(false);
  const [kindergatens, setKindergartens] = React.useState(null);

  const handleCloseExtendDialog = () => {
    setOpenExtendDialog(false);
  };

  const [contractInfo, setContractInfo] = React.useState(null);

  const handleOpenExtendDialog = (id) => {
    if (id) {
      contractApi.getKG().then((response) => {
        setKindergartens(response.data);
        console.log("response", response);
      });

      dispatch(getContractToExtend(id)).then((dataInfo) => {
        if (!dataInfo) {
          return;
        }
        setContractInfo({ ...dataInfo[0] });
      });
      setOpenExtendDialog(true);
    }
  };

  return (
    <div>
      <div className="card__client_header">
        {contactHistoryForRender?.comment && (
          <div className="card__client_comment">
            {" "}
            <InsertCommentIcon color="action" />{" "}
            {dayjs(contactHistoryForRender?.date_created).format(
              "YYYY/MM/DD HH:mm"
            )}{" "}
            {contactHistoryForRender?.date_update &&
              contactHistoryForRender.date_update !== "None" && (
                <span>
                  ,изменен:
                  {dayjs(contactHistoryForRender?.comment?.date_update).format(
                    "YYYY/MM/DD HH:mm"
                  )}
                </span>
              )}
            {" :"}
            {findLabelByID(contactHistoryForRender?.autor_id, allManagers)}
          </div>
        )}
        {contactHistoryForRender?.comments && (
          <div className="card__client_lead">
            <div>
              {" "}
              <HandshakeIcon color="secondary" /> Лид создан :
              {dayjs(contactHistoryForRender?.date_created).format(
                "YYYY-MM-DD HH:mm"
              )}{" "}
            </div>
          </div>
        )}
      </div>
      <div className="card__client_history">
        <div
          className="comment_body"
          style={{ display: "flex", justifyContent: "space-between" }}
        >
          {contactHistoryForRender?.comment &&
            (contactHistoryForRender.id_comment === currentCommentId &&
              isEditMode ? (
              <div className="edit-mode">
                <TextField
                  style={{ border: "none" }}
                  sx={{
                    "& fieldset": { border: "none", fontSize: "18px" },
                    "& textarea": {
                      lineHeight: "1.2",
                      padding: "0!important",
                    },
                  }}
                  InputProps={{
                    style: { padding: "5px" },
                  }}
                  fullWidth
                  multiline
                  autoFocus
                  className="editable-textarea"
                  value={editableInputValue}
                  onChange={handleCommentChange}
                  size="small"
                />
                <Tooltip title="Сохранить">
                  <span className="save-button">
                    <DoneOutlineTwoToneIcon
                      color="success"
                      type="button"
                      className="save-button"
                      onClick={handleLocalSaveClick}
                    />
                  </span>
                </Tooltip>
              </div>
            ) : (
              <div className="non-editable">
                {contactHistoryForRender.comment}
                <Tooltip title="Редактировать">
                  <span className="edit-button">
                    <EditIcon
                      cursor="pointer"
                      type="button"
                      onClick={() =>
                        handleLocalEditClick(contactHistoryForRender)
                      }
                    />
                  </span>
                </Tooltip>
              </div>
            ))}

          {contactHistoryForRender?.comments && (
            <>
              <div>
                <span
                  style={{
                    fontSize: "13px",
                    lineHeight: "17px",
                    color: "#2660c0",
                  }}
                >
                  {findLabelByID(
                    contactHistoryForRender?.comments.at(-1).creator,
                    allManagers
                  )}
                  :
                </span>
                <div>{contactHistoryForRender?.comments.at(-1).comment}</div>
                <div style={{ fontSize: "14px", color: "#777" }}>
                  {contactHistoryForRender?.comments.at(-1).date}
                </div>
                <div style={{ fontSize: "14px", color: "#777" }}>
                  {" "}
                  договор{" "}
                  <span className="comment__body_contract_number">
                    {contactHistoryForRender?.contract_number}
                  </span>{" "}
                  создан: {contactHistoryForRender?.date_created_contract}{" "}
                  <span className={`card__client_contract_status_${contactHistoryForRender?.status_contract} `}>({translateContractStatus(contactHistoryForRender?.status_contract)})</span>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <div className="card_contract_actions">
                  <Button
                    size="small"
                    disabled={contactHistoryForRender.doc_name === ""}
                    onClick={() => {
                      saveContract(contactHistoryForRender.doc_name);
                    }}
                  >
                    {contactHistoryForRender.doc_name === ""
                      ? "договор закрыт"
                      : "скачать договор"}
                  </Button>
                  {contactHistoryForRender?.children_club && (
                    <Button
                      size="small"
                      disabled={contactHistoryForRender.doc_name === ""}
                      onClick={() => {
                        handleOpenExtendDialog(
                          contactHistoryForRender?.contract_id
                        );
                      }}
                    >
                      продлить договор
                    </Button>
                  )}
                </div>

                <div
                  className="card__lead_actions"
                  style={{ display: "flex", flexDirection: "column" }}
                >
                  <Button
                    size="small"
                    onClick={() => {
                      openUpdateLead(
                        findLeadByID(
                          contactHistoryForRender?.id_lead.toString(),
                          allLeads
                        )
                      );
                    }}
                  >
                    открыть Лид
                  </Button>
                  <div
                    className={`card__client_lead_status_${contactHistoryForRender.status} `}
                  >
                    {translateStatus(contactHistoryForRender.status)}{" "}
                  </div>
                </div>
              </div>
            </>
          )}
        </div>
      </div>
      <CreateLeadDialog
        open={LeadModalOpen}
        onClose={handleLeadClose}
        lead={leadFromComments}
        type="disable_goContact_btn"
      />
      <ExtendDialog
        open={openExtendDialog}
        handleClose={handleCloseExtendDialog}
        contractInfo={contractInfo}
        kindergatens={kindergatens}
        entitiesTypes={entities}
        id_contact={id_contact || null}
      />
    </div>
  );
};

export default CommentBody;
