import React from "react";
import { Redirect, useLocation, useRouteMatch } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import Backdrop from "@mui/material/Backdrop";
import { CircularProgress } from "@mui/material";
import isLoggedIn from "../../utils/helpers/isLoggedIn";
import getAllowedRoutes from "../../utils/allowedRoutes";
import routesConfig from "../../config/routesConfig";
import MapAllowedRoutes from "../../routes/MapAllowedRoutes";
import Header from "../../components/Header";
import Sidebar from "../../components/Sidebar";
import Content from "../../components/Content";
import { getUniqueEntities } from "../../redux/actions/entitiesTypesActions";
import { login, logoutAction } from "../../redux/actions/loginActions";
import { flush, log } from "../../utils/helpers/tracker";

function Home() {
  const dispatch = useDispatch();

  // ----- User Auth
  // const b64_to_utf8 = (str) => atob(str);

  const b64DecodeUnicode = (str) =>
    // Going backwards: from bytestream, to percent-encoding, to original string.
    decodeURIComponent(
      atob(str)
        .split("")
        .map((c) => `%${`00${c.charCodeAt(0).toString(16)}`.slice(-2)}`)
        .join("")
    );
  const googleAuth = () => {
    const urlSearchParams = new URLSearchParams(window.location);
    const params = Object.fromEntries(urlSearchParams.entries());
    if (params.pathname === "/app/google") {
      const userB64Data = params.search.slice(1).toString(); // get last path(b64 code) from url
      const decodedUserData = b64DecodeUnicode(userB64Data).replace(/'/g, '"'); // decoded user and permissons, prepare for json parse
      const userAuthObject = JSON.parse(decodedUserData);
      if (userAuthObject.user.email === "guest@stemlab.by") {
        dispatch(logoutAction);
        <Redirect to="app/login" />;
      } else dispatch(login(userAuthObject));
    }
  };

  googleAuth();

  // ----- User Auth

  React.useEffect(() => {
    dispatch(getUniqueEntities());
  }, []);

  // logging client events
  React.useEffect(() => {
    flush();
  }, []);

  const match = useRouteMatch("/app");
  const location = useLocation();
  const screenWidth = window.screen.width;
  const showBar = useSelector(({ globalState }) => globalState.isBarShow);
  const userName = useSelector(({ user }) => user.info.short_name);
  const userEmail = useSelector(({ user }) => user.info.email);
  const permissions = useSelector(({ user }) => user.permissions);
  let allowRoutes = [];

  const appGlobalState = useSelector(({ globalState }) => globalState);

  if (isLoggedIn()) {
    allowRoutes = getAllowedRoutes(routesConfig, permissions);
  } else {
    return (
      <Redirect
        to={{
          pathname: "/login",
          state: { from: location },
        }}
      />
    );
  }

  <Redirect to="app/login" />;

  return (
    <>
      {/* <Backdrop open={appGlobalState.loading} sx={{ color: "#fff", zIndex: 4 }}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <div className="wrapper">
        <Header user={{ userName, userEmail }} key="header" />
        <Sidebar config={allowRoutes} prefix={match.path} key="sidebar" />
        <Backdrop
          open={showBar && screenWidth < 991}
          sx={{ color: "#fff", zIndex: 2 }}
        />
        <Content>
          <MapAllowedRoutes routes={allowRoutes} basePath="/app" />
        </Content>
      </div>
    </>
  );
}
export default Home;
