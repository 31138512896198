/* eslint-disable consistent-return */
const initialState = {
  payload: [],
  loading: true,
  days: {},
  isMethodist: true,
  badRequest: false,
  teacherPage: true,
};

const groupByDateDifferentGroups = (sour) => {
  const sorted = {};
  // eslint-disable-next-line array-callback-return
  sour.map((item) => {
    sorted[item.date] = [...item.group_id];
  });
  return sorted;
};

const groupByDate = (arr) => arr.reduce((acc, curr) => {
    (acc[curr.date] = acc[curr.date] || []).push(curr);
    return acc;
  }, {});


const scheduleViewReducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_SCHEDULES":
      return {
        ...state,
        payload:groupByDate(action.payload),
        loading: action.loading,
        days: groupByDateDifferentGroups(action.payload),
      };

    case "GET_TOPICS":
      return {
        ...state,
        payload:groupByDate(action.payload),
        loading: action.loading,
        topics: action.payload,
      };

    case "LOADING_SCHEDULES":
      return {
        ...state,
        loading: action.loading,
      };

    case "IS_METHODIST":
      return {
        ...state,
        isMethodist: action.payload,
      };
    case "VIEW_TEACHER_PAGE":
      return {
        ...state,
        teacherPage: action.payload,
      };
    case "ADD_TOPIC":
      return {
        ...state,
        topics: [...state.topics, action.payload],
        loading: action.loading,
      };
    case "UPDATE_TOPIC":
      return {
        ...state,
        topics: state.topics.map((topic) =>
          // eslint-disable-next-line eqeqeq
          topic.id == action.payload.id ? action.payload : topic
        ),
        loading: action.loading,
      };
    case "REMOVE_TOPIC":
      return {
        ...state,
        topics: state.topics.filter((topic) => topic.id !== action.payload),
        loading: action.loading,
      };
    case "BAD_REQUEST":
      return {
        ...state,
        days: {},
        loading: false,
        badRequest: true,
      };
    case "GOOD_REQUEST":
      return {
        ...state,
        loading: false,
        badRequest: false,
      };
    case "CLEAR_SCHEDULES":
      return {
        ...state,
        days: {},
        payload: [],
      };
    case "ERROR":
      return {
        ...state,
        loading: action.loading,
      };
    default:
      return state;
  }
};

export default scheduleViewReducer;
