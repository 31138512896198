const initialState = {
	payload: [],
	loading: false,
};

const groupsReducer = (state = initialState, action) => {
	switch (action.type) {
		case "GET_GROUPS":
			return {
				...state,
				payload: action.payload,
				loading: false
			};
		case "LOADING_GROUPS":
			return {
				...state,
				loading: action.loading,
			};
		case "ADD_GROUP":
			return {
				...state,
				payload: [...state.payload, action.payload],
				loading: action.loading,
			};
		case "UPDATE_GROUP":
			return {
				...state,
				payload: state.payload.map((group) =>
					group.id === action.payload.id ? action.payload : group
				),
				loading: action.loading,
			};
		case "REMOVE_GROUP":
			return {
				...state,
				payload: state.payload.filter((item) => item.id !== action.payload),
				loading: action.loading,
			};
		case "CLEAR_GROUPS":
			return initialState;
		default:
			return state;
	}
};

export default groupsReducer;
