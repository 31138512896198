const hintCities = [
  "",
  "не выбрано",
  "г. Минск",
  "г. Барановичи",
  "г.Борисов",
  "г.Батуми",
  "г.Брест",
  "г.Гродно",
  "г.Гомель",
  "г.Витебск",
  "г.Могилев",
  "г.Бобруйск",
  "г. Лепель",
  "г. Пинск",
  "г. Полоцк",
  "Минский р-н",
  "Каменецкий р-н",
  "аг-г Соколово Брестская обл",
  "Щучин",
  "Орша",
  "Речица",
  "Дзержинск",
  "аг. Новый Быхов",
  "Бельчицы",
  "Рогочев",
  "Светлогорск",
  "Быхов",
  "Жлобин",
  "Солигорск",
  "Молодечно",
  "Марьина Горка",
  "Хойники",
  "Мозырь",
  "Столин",
  "Крупки",
  "Давыд город",
  "Ивацевичи",
  "Молорита",
  "Белоозёрск",
  "Дрогичин",
  "Столбцы",
  "п.Ленинский (Брест. обл)",
  "Островец",
  "Смолевичи",
  "Ошмяны",
  "волковыск",
  "Осиповичи",
  "Пружаны",
  "Толочин Витебская область",
  "Новополоцк",
  "Сморгонь",
  "Гродненская область",
  "Чаусы",
  "Поставы",
  "Пинский р-н",
  "Гомельский р-н",
  "Жабинка",
  "Лида",
  "Кобрин",
  "Высокое",
  "Вилейка",
  "Нарочь",
  "Мядель",
  "Ганцевичи",
  "Слуцк",
  "Новогрудок",
  "Жодино",
  "Белыничи",
  "Костюковичи",
  "Дарево",
  "Воложинский р-н",
  "Логойск",
  "Клецк",
  "Радашковичи",
  "Лунинец",
  "Горки",
  "Береза",
  "Воложинский район, д.Довбени, Усадьба «Березовый двор»",
  "Тбилиси",
  "ქ. ბათუმი",
  "Брестская область, Барановичский район, д. Павлиново, Леснянский сельсовет, ул. Озёрная, 2 «Санаторий Чабарок»",
];

export default hintCities;
