import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import StudentsKanban from "../../components/StudentsKanban/StudentsKanban";

import {
  fetchStudents,
  getConfirmData,
  updateStudentsAfterConfirm,
} from "../../redux/actions/studentsKanbanAction";
import "../../scss/index.scss";
import { setPageTitle } from "../../redux/actions/globalActions";

const mapStateToProps = (state) => ({
  loading: state.studentsKanban.loading,
  groups: state.studentsKanban.payload,
  confirmData: state.studentsKanban.confirm,
  confirmDataTransfer: state.studentsKanban.transfer,
  modal: state.studentsKanban.modal,
});

const mapDispatchToProps = (dispatch) => ({
  getGroupsKanbanData: async (data) => {
    await dispatch(fetchStudents(data));
  },
  getConfirmData: async (data) => {
    await dispatch(getConfirmData(data));
  },
  updStudents: (data) => {
    dispatch(updateStudentsAfterConfirm(data));
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

function StudentsKanbanPage({ ...props }) {
  const { loading, getGroupsKanbanData, title, setTitle } = props;
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  useEffect(() => {
    getGroupsKanbanData();
  }, []);

  useEffect(() => {
    setTitle(title);
  }, []);

  return (
    <>
      <div className="content-component__wrapper">
        <h4 className="table__title">{title}</h4>
        {loading ? (
          <Backdrop open={!appGlobalState.loading} sx={backdropStyles}>
            <CircularProgress />
          </Backdrop>
        ) : (
          <StudentsKanban {...props} />
        )}
      </div>
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentsKanbanPage);
