import React from "react";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { CircularProgress } from "@mui/material";

function SearchAutocomplete({
  label,
  options,
  value,
  setValue,
  inputValue,
  setInputValue,
  loading,
  renderOption,
  ...props
}) {
  return (
    <Autocomplete
      freeSolo
      value={value}
      onChange={setValue}
      inputValue={inputValue}
      onInputChange={setInputValue}
      options={options}
      loading={loading}
      renderOption={props.renderOption}
      filterOptions={props.filterOptions}
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </>
            ),
          }}
        />
      )}
      {...props}
    />
  );
}

export default SearchAutocomplete;
