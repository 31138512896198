/* eslint-disable max-len */
/* eslint-disable no-restricted-syntax */
/* eslint-disable no-prototype-builtins */
/* eslint-disable react/no-array-index-key */
/* eslint-disable react/button-has-type */
import React from "react";

import "./CreatingContractMain.scss";
import { TextField, MenuItem, Button } from "@material-ui/core";
import { useDispatch, useSelector } from "react-redux";
import CreateConfirmDialog from "./CreateConfirmDialog";
import { contractApi } from "../../utils/api";
import notification from "../../utils/notification/notification";
import {
  fetchContactsFake,
} from "../../redux/actions/contactsActions";
import { fetchFakeLeads, updLead } from "../../redux/actions/leadsActions";
import filterArray from "../../utils/helpers/forCreateContract";

// проверка на пустые свойства в объекте штзгеы
// TODO:  сделать нормальную валидацию
export const checkEmptyFields = (obj) => {
  for (const key in obj) {
    if (
      obj.hasOwnProperty(key) &&
      obj[key] === "" &&
      key !== "utm_campaign" &&
      key !== "utm_term" &&
      key !== "utm_source" &&
      key !== "payments" &&
      key !== "city"
    ) {
      return key; // Если найдено пустое поле, возвращаем true
    }
  }
  return false; // Если пустых полей не найдено, возвращаем false
};

function CreatingIndividual({ data, entities, lead, contact, lastLeadId }) {
  const { location, requisites } = entities;
  const { courses, groups } = data;
  // const today = new Date().toISOString().split("T")[0];
  const defaultUser = useSelector(({ user }) => user.info);
  const [openConfirmation, setOpenConfirmation] = React.useState(false);
  const [disableActionBtns, setDisableActionBtns] = React.useState(false);

  const dispatch = useDispatch();

  // console.log('contact', contact);
  // console.log('lead', lead);
  // console.log('lastLeadId', lastLeadId);

  const onlyIndividual = courses
    ?.slice()
    .filter((course) => course.type === "individual");
  const [filteredGroups, setFilteredGroups] = React.useState(groups);

  // console.log(location, course, requisites, groups, nutrition);

  const academicStateInit = {
    lessons_type: "individual",
    id_lead: lead?.id_lead?.toString() || lastLeadId || "",
    full_name: contact?.name || "",
    kid_name: contact?.kid_name || "",
    year_of_birth:
      contact?.year_of_birth?.toString() ||
      lead?.year_of_birth?.toString() ||
      "",
    passport_contact: contact?.passport_contact || "",
    registration_contact: contact?.registration_contact || "",
    city: contact?.city || "",
    location: "",
    course: "",
    requisite: "",
    group: "",
    lesson_price: "",
    lesson_duration: "",
    user_id: defaultUser.id.toString() || "",
    contact_id:
      contact?.id_contact.toString() || lead?.id_contact.toString() || "",
    utm_source: lead?.utm_source || "",
    utm_campaign: "",
    utm_term: "",
  };

  const [inputs, setInputs] = React.useState(academicStateInit);

  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    console.log(name, value);
    setInputs((prevInputsState) => ({
      ...prevInputsState,
      [name]: value,
    }));
    // filtered groups, when user choose course('название программы').
    // We have course_id in groups array
    if (name === "course") {
      setInputs((prevInputsState) => ({
        ...prevInputsState,
      }));
      const filteredArray = filterArray(
        groups,
        value,
        inputs.location
      );
      setFilteredGroups([...filteredArray]);
    }
    if (name === "location") {
      const filteredArray = filterArray(groups, inputs.course, value );
      setFilteredGroups(() => [...filteredArray]);
      setInputs((prevInputsState) => ({
        ...prevInputsState,
      }));
    }
    // console.log(inputs);
  };

  const [contractIinfo, setContractInfo] = React.useState("");
  const [createError, setCreateError] = React.useState(false);

  // TODO: вынести запросы, переписать через апишку все(вл всех 3х компонентах Camp, AcademicYear, Individual)
  const handleSubmit = (event) => {
    event.preventDefault();
    if (checkEmptyFields(inputs)) {
      notification.info(`вы не заполнили ${checkEmptyFields(inputs)}`);
      return;
    }
    contractApi
      .create({ contract: inputs })
      .then((response) => {
        setContractInfo(response.data);
        notification.success("Договор создан");
        // dispatch(fetchContactsFake());
        dispatch(fetchFakeLeads());
        // dispatch(
        //   updContactFromCreateContract({
        //     ...inputs,
        //     id_contact: inputs.contact_id,
        //     name: inputs.full_name,
        //     phone: contact?.phone
        //   })
        // );
        notification.success("Данные контакта обновлены");
        dispatch(
          updLead({
            ...inputs,
            id_contact: inputs.contact_id,
            name: inputs.full_name,
          })
        );
        notification.success("Данные Лидов обновлены");
      })
      .catch((e) => {
        setCreateError(`${e.message} ${e.response?.data?.message}`);
        notification.error("Не удалось создать договор");
      });
    setOpenConfirmation(true);
  };

  const deleteContract = () => {
    contractApi.remove({ contract: contractIinfo.contract_id }).then((resp) => {
      if (resp.data.status === "OK") {
        notification.success("Договор удален");
        setDisableActionBtns(true);
      }
    });
    setOpenConfirmation(true);
  };

  const saveContract = () => {
    contractApi.save(contractIinfo.file_name).then((resp) => {
      const href = URL.createObjectURL(resp.data);
      const link = document.createElement("a");
      // create "a" HTML element with href to file & click
      // const link = document.createElement('a');
      link.href = href;
      link.setAttribute("download", `${contractIinfo.file_name}`);
      document.body.appendChild(link);
      // clean up "a" element & remove ObjectURL
      link.click();
      document.body.removeChild(link);
      URL.revokeObjectURL(href);
      notification.success("Договор сохранен");
      setOpenConfirmation(true);
    });
  };

  return (
    <>
      <div style={{ display: "flex", flexDirection: "row" }}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            marginRight: "50px",
          }}
        >
          <TextField
            margin="dense"
            size="small"
            name="full_name"
            label="ФИО Родителя"
            value={inputs.full_name}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="ФИО Ребенка"
            name="kid_name"
            value={inputs.kid_name}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Год рождения ребенка"
            name="year_of_birth"
            value={inputs.year_of_birth}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Паспортные данные"
            name="passport_contact"
            value={inputs.passport_contact}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Данные о регистрации"
            name="registration_contact"
            value={inputs.registration_contact}
            onChange={(event) => handleInputsChange(event)}
            fullWidth
          />
          <TextField
            margin="dense"
            size="small"
            label="Локация"
            fullWidth
            select
            name="location"
            value={inputs.location}
            onChange={(event) => handleInputsChange(event)}
          >
            {location.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Реквизиты"
            fullWidth
            select
            name="requisite"
            value={inputs.requisite}
            onChange={(event) => handleInputsChange(event)}
          >
            {requisites.map((item) => (
              <MenuItem key={item.value} value={item.value}>
                {item.label}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Название программы"
            fullWidth
            select
            name="course"
            value={inputs.course}
            onChange={(event) => handleInputsChange(event)}
          >
            {onlyIndividual.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.name}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="dense"
            size="small"
            label="Учебная группа"
            fullWidth
            select
            name="group"
            value={inputs.group}
            onChange={(event) => handleInputsChange(event)}
          >
            {filteredGroups.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.short_name}
              </MenuItem>
            ))}
          </TextField>

          <TextField
            margin="dense"
            select
            size="small"
            label="Продолжительность занятия"
            fullWidth
            name="lesson_duration"
            value={inputs.lesson_duration}
            onChange={(event) => handleInputsChange(event)}
          >
            <MenuItem value="30">30</MenuItem>
            <MenuItem value="45">45</MenuItem>
            <MenuItem value="60">60</MenuItem>
            <MenuItem value="90">90</MenuItem>
          </TextField>

          <TextField
            margin="dense"
            size="small"
            label="Стоимость занятия"
            fullWidth
            name="lesson_price"
            value={inputs.lesson_price}
            onChange={(event) => handleInputsChange(event)}
          />
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "45%",
            paddingTop: "10px",
          }}
        >
          <div className="contract__actions">
            <Button onClick={handleSubmit} variant="contained" color="primary">
              Создать договор
            </Button>
            <Button disabled variant="contained" color="primary">
              action
            </Button>
            <Button disabled variant="contained" color="primary">
              action
            </Button>
          </div>
        </div>
        <CreateConfirmDialog
          name={inputs?.full_name}
          open={openConfirmation}
          setOpen={setOpenConfirmation}
          setError={setCreateError}
          error={createError}
          disableActionBtns={disableActionBtns}
          setDisableActionBtns={setDisableActionBtns}
          del={() => {
            deleteContract();
          }}
          save={() => {
            saveContract();
          }}
        />
      </div>
      <pre>{JSON.stringify(inputs, null, 2)}</pre>
    </>
  );
}

export default CreatingIndividual;
