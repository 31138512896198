import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import React from "react";
import { useDispatch } from "react-redux";
import ContractTypeSelector from "./ContractTypeSelector";
import { fetchContactHistory } from "../../redux/actions/contactsActions";

const CreateContractWrapper = ({
  open,
  setOpen,
  lead,
  contactFromContactDialog,
}) => {
  const dispatch = useDispatch();
  return (
    <Dialog
      fullWidth
      maxWidth="xl"
      open={open}
      onClose={() => setOpen(false)}
      aria-labelledby="confirm-dialog"
    >
      <DialogTitle id="confirm-dialog">
        Создание договора {lead?.name || contactFromContactDialog?.name}{" "}
      </DialogTitle>
      <DialogContent style={{ height: "100vh", backgroundColor: "#f5f5f5" }}>
        <ContractTypeSelector
          lead={lead}
          contactFromContactDialog={contactFromContactDialog}
        />
      </DialogContent>
      {/* <pre>{JSON.stringify(lead, null, 4)}</pre> */}
      <DialogActions>
        <Button
          variant="contained"
          onClick={() => {
            setOpen(false);
            dispatch(
              fetchContactHistory(
                lead?.id_contact || contactFromContactDialog?.id_contact
              )
            );
          }}
        >
          закрыть
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CreateContractWrapper;
