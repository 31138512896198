import { paymentsSchedule } from "../../utils/api";

export const setPaymentsSchedule = (data) => ({
	type: "SET_PAYMENTS_SCHEDULE",
	payload: data,
	loading: false,
});

export const loadingPaymentsSchedule = () => ({
	type: "LOADING_PAYMENTS_SCHEDULE",
	loading: true,
});

export const fetchPaymentsSchedule = () => (dispatch) => {
	dispatch(loadingPaymentsSchedule());
	paymentsSchedule
		.get()
		.then(({ data }) => dispatch(setPaymentsSchedule(data.receivables)));
};
