import axios from "../../config/axiosConfig";

export default {
  get: () => axios.get(`/get/attendance?group=''&teacher=''&subject=''`),
  getReport: (inputs) =>
    axios.get(
      `/get/attendance?group=${inputs.groups}&teacher=${inputs.user_id}&subject=${inputs.subjects}`
    ),
  post: (data) => axios.post(`/update/attendance`, data),
};
