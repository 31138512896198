import { reportApi } from "../../utils/api";

export const setNewReport = (data) => ({
	type: "SET_NEW_REPORT",
	payload: data,
	loading: false,
});

export const setOngoingReport = (data) => ({
	type: "SET_ONGOING_REPORT",
	payload: data,
	loading: false,
});

export const setPayByDateByCityReport = (data) => ({
	type: "SET_PAY_BY_DATE_BY_CITY_REPORT",
	payload: data,
	loading: false,
});

export const setCampReport = (data) => ({
	type: "SET_CAMP_REPORT",
	payload: data,
	loading: false,
});

export const setFirstVisitReport = (data) => ({
	type: "SET_FIRST_VISIT_REPORT",
	payload: data,
	loading: false,
});

export const loadingNewReport = () => ({
	type: "LOADING_NEW_REPORT",
	loading: true,
});

export const loadingOngoingReport = () => ({
	type: "LOADING_ONGOING_REPORT",
	loading: true,
});

export const loadingPayByDateByCityReport = () => ({
	type: "LOADING_PAY_BY_DATE_BY_CITY_REPORT",
	loading: true,
});

export const loadingCampReport = () => ({
	type: "LOADING_CAMP_REPORT",
	loading: true,
});

export const loadingFirstVisitReport = () => ({
	type: "LOADING_FIRST_VISIT_REPORT",
	loading: true,
});

export const fetchWeekReport = () => (dispatch) => {
	dispatch(loadingNewReport());
	reportApi.new.get().then(({ data }) => dispatch(setNewReport(data.report)));
};
export const fetchLocationReport = () => (dispatch) => {
	dispatch(loadingOngoingReport());
	reportApi.ongoing
		.get()
		.then(({ data }) => dispatch(setOngoingReport(data.report)));
};

export const fetchPayByDateByCityReport = () => (dispatch) => {
	dispatch(loadingPayByDateByCityReport());
	reportApi.payByDayAndByCity
		.get()
		.then(({ data }) => dispatch(setPayByDateByCityReport(data.report)));
};

export const fetchCampReport = () => (dispatch) => {
	dispatch(loadingCampReport());
	reportApi.camp.get().then(({ data }) => dispatch(setCampReport(data.report)));
};

export const fetchFirstVisitReport = () => (dispatch) => {
	dispatch(loadingFirstVisitReport());
	reportApi.firstVisit
		.get()
		.then(({ data }) => dispatch(setFirstVisitReport(data.report)));
};
