/* eslint-disable no-nested-ternary */
/* eslint-disable no-undef */
import {
  Autocomplete,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  MenuItem,
  TextField,
  Tooltip,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import React from "react";
import {
  MaskedInput,
  ReusableAutocomplete,
  ReusableTextField,
} from "../ReusableInputs/ReusableInputs";
import { addNewLead } from "../../redux/actions/leadsActions";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import { fetchContactHistory } from "../../redux/actions/contactsActions";
import hintCities from "./helpersForModals/hintCitiesForAutocomlete";
import ConfirmDialog from "./ConfirmDialog";

// notice: this dialog we use when user press "создать лид" from contact modal window
const CreateLeadFromContact = ({ open, onClose, contact }) => {
  // console.log("props CreateLeadFromContact", contact);

  const dispatch = useDispatch();
  const initialInputsState = {
    id_lead: "",
    id_contact: "",
    name: "",
    city: "",
    year_of_birth: "",
    phone: "",
    email: "",
    manager: "",
    interested: "",
    utm_source: "",
    utm_campaign: "",
    utm_term: "",
    comment: "",
    new_comment: "",
    date_due: "",
  };

  const errorInputsState = {
    name: "",
    city: "",
    new_comment: "",
    phone: "",
    date_due: "",
    utm_source: "",
  };

  const [inputs, setInputs] = React.useState(initialInputsState);
  const [error, setError] = React.useState(errorInputsState);
  const [disqualifiedLid, setDisqualified] = React.useState(false);
  const [closingConfirm, setClosingConfirm] = React.useState(false);
  const [executor, setExecutor] = React.useState(null);
  const [rejectValue, setReject] = React.useState("");
  const [isValid, setIsValid] = React.useState(true);
  const regNumbers = /^[0-9,Backspace]+$/;
  const managers = useSelector(({ entitiesTypes }) => entitiesTypes.user);
  const activeManagers = useSelector(
    ({ entitiesTypes }) => entitiesTypes.active_users
  );
  const programs = useSelector(({ entitiesTypes }) => entitiesTypes.course);
  const reject_reasons = useSelector(
    ({ entitiesTypes }) => entitiesTypes.reasons_cancelled
  );
  const defaultUser = useSelector(({ user }) => user.info);
  const sources = useSelector(({ entitiesTypes }) => entitiesTypes.utm_source);

  const clearInputs = () => {
    setInputs(initialInputsState);
    setReject("");
    setDisqualified(false);
    setExecutor(null);
    // setIsValid(true);
    setError(errorInputsState);
  };

  function calculateAge(yearOfBirth) {
    const currentYear = new Date().getFullYear();
    const birth = new Date(yearOfBirth).getFullYear();
    return currentYear - birth;
  }

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick") {
      return;
    }
    clearInputs();
    onClose(); // Invoke the onClose callback function to close the modal
    setIsValid(true);
  };

  const handleCloseWithConfirm = () => {
    setClosingConfirm(true);
    setIsValid(true);
  };

  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    if (name === "date_due") {
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        date_due: `${value?.split("T").join(" ")}:00`,
      }));
      setError((prevInputsState) => ({
        ...prevInputsState,
        date_due: `${value?.split("T").join(" ")}:00`,
      }));
    } else {
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        [name]: value,
      }));
      setError((prevInputsState) => ({
        ...prevInputsState,
        [name]: value,
      }));
    }
  };

  const handleSubmitLead = () => {
    if (
      inputs.city &&
      inputs.date_due &&
      inputs.name &&
      inputs.phone &&
      inputs.new_comment &&
      inputs.utm_source
    ) {
      setIsValid(true);
    } else {
      setIsValid(false);
      return;
    }
    const rejectBoolean = Boolean(rejectValue);
    if (rejectBoolean) {
      dispatch(
        addNewLead({ ...inputs, rejected: rejectValue, contact: false })
      );
      setTimeout(() => dispatch(fetchContactHistory(contact.id_contact)), 2000);
    }
    if (!disqualifiedLid && !rejectBoolean)
      dispatch(addNewLead({ ...inputs, contact: false }));
    setTimeout(() => dispatch(fetchContactHistory(contact.id_contact)), 2000);
    handleClose();
    clearInputs();
  };

  const handleAddDisqualified = () => {
    setError({
      name: inputs.name,
      new_comment: inputs.new_comment,
    });
    if (inputs.name && inputs.new_comment) {
      setIsValid(true);
    } else {
      setIsValid(false);
      return;
    }
    setDisqualified(!disqualifiedLid);
  };

  const confirmDisqualified = () => {
    if (inputs.name && inputs.new_comment) {
      setIsValid(true);
    } else {
      setIsValid(false);
      return;
    }
    dispatch(addNewLead({ ...inputs, disqualified: true, contact: false }));
    handleClose();
  };
  React.useEffect(() => {
    if (contact) {
      console.log("contact >>>", contact);
      const myKeys = Object.keys(initialInputsState);
      // eslint-disable-next-line array-callback-return
      myKeys.map((key) => {
        if (contact[key]) {
          setInputs((prevInputsState) => ({
            ...prevInputsState,
            [key]: contact[key],
            utm_source: "",
          }));
          setError((prevInputsState) => ({
            ...prevInputsState,
            [key]: contact[key],
            utm_source: "",
          }));
        }
      });
      // console.log("INPUTS", inputs);
      setIsValid(true);
    }
  }, [contact, open]);

  React.useEffect(() => {
    if (!defaultUser) {
      setExecutor({
        value: "40",
        label: "Татьяна Николаевна",
        test: "test",
      });
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        manager: "40",
      }));
      setError((prevInputsState) => ({
        ...prevInputsState,
      }));
    }
    if (defaultUser) {
      setExecutor({
        value: defaultUser.id.toString(),
        label: defaultUser.short_name,
        test: "test",
      });
      setInputs((prevInputsState) => ({
        ...prevInputsState,
        manager: defaultUser.id.toString(),
      }));
      setError((prevInputsState) => ({
        ...prevInputsState,
      }));
    }
  }, [contact, open]);

  return (
    <div>
      <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
        <DialogTitle style={{ background: "#e5f4ff" }}> Новый лид</DialogTitle>

        <DialogContent style={{ background: "#e5f4ff" }}>
          <DialogContentText component="span">
            <span
              className="create__lead_wrapper"
              style={{
                display: "flex",
                flexDirection: "column",
                background: "rgba(82,92,105,.15)",
                padding: "5px 30px 30px 30px",
                borderRadius: "10px",
                width: "90%",
              }}
            >
              <div style={{ textAlign: "end" }}>
                {inputs.year_of_birth.length < 4 ? (
                  <span style={{ opacity: 0 }}>age</span>
                ) : (
                  `Возраст ребенка: ${calculateAge(inputs.year_of_birth)} ${
                    calculateAge(inputs.year_of_birth) % 10 === 1
                      ? "год"
                      : calculateAge(inputs.year_of_birth) % 10 === 2 ||
                        calculateAge(inputs.year_of_birth) % 10 === 3 ||
                        calculateAge(inputs.year_of_birth) % 10 === 4
                      ? "года"
                      : "лет"
                  }`
                )}
              </div>
              <ReusableTextField
                type="text"
                label="Имя"
                value={inputs.name}
                name="name"
                onChange={handleInputsChange}
              />
              {!isValid && error.name === "" && (
                <div className="text-error">Это поле не должно быть пустым</div>
              )}
              <ReusableTextField
                type="number"
                label="Tелефон"
                value={inputs.phone}
                name="phone"
                onChange={handleInputsChange}
                onKeyDown={(event) => {
                  if (!regNumbers.test(event.key)) {
                    event.preventDefault();
                  }
                }}
              />
              {!isValid && error.phone === "" && (
                <div className="text-error">
                  Это поле не должно быть пустым, вводите только цифры
                </div>
              )}
              {/* -----not reusable autocomlete with default cities + what user write ------ */}

              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "flex-start",
                  marginTop: "5px",
                }}
              >
                <div
                  style={{
                    marginRight: "5px",
                    minWidth: "120px",
                    fontSize: "14px",
                  }}
                >
                  Город
                </div>
                <Autocomplete
                  freeSolo
                  autoSelect
                  size="small"
                  fullWidth
                  options={hintCities}
                  value={inputs.city}
                  onChange={(event, newValue) => {
                    // console.log(event, newValue);
                    setInputs((prevInputsState) => ({
                      ...prevInputsState,
                      city: newValue || "",
                    }));
                    setError((prevInputsState) => ({
                      ...prevInputsState,
                      city: newValue || "",
                    }));
                  }}
                  renderInput={(params) => <TextField {...params} />}
                  sx={{
                    backgroundColor: "#fff",
                  }}
                />
                {!isValid && error.city === "" && (
                  <div className="text-error">
                    Это поле не должно быть пустым
                  </div>
                )}
              </div>
              {/* <ReusableTextField
								type="number"
								label="Год рождения ребенка"
								placeholder="ГГГГ"
								value={inputs.year_of_birth}
								name="year_of_birth"
								onChange={handleInputsChange}
								onKeyDown={(event) => {
									if (!regNumbers.test(event.key)) {
										event.preventDefault();
									}
								}}
							/> */}
              <MaskedInput
                value={inputs.year_of_birth}
                label="Год рождения ребенка(ГГГГ- или ГГГГ-ММ-ДД)"
                onChange={handleInputsChange}
                formatChars={{
                  y: "[0-2]",
                  9: "[0-9]",
                  n: "[0-1]",
                  m: "[0-9]",
                  z: "[0-3]",
                }}
                type="tel"
                name="year_of_birth"
                placeholder="ГГГГ- или ГГГГ-ММ-ДД"
              />
              <ReusableTextField
                type="email"
                label="email"
                value={inputs.email}
                name="email"
                onChange={handleInputsChange}
              />
              <ReusableAutocomplete
                label="Ответственный"
                size="small"
                name="manager"
                value={executor}
                onChange={(event, newValue) => {
                  setExecutor(newValue);
                  setInputs((prevInputsState) => ({
                    ...prevInputsState,
                    manager: newValue ? newValue.value.toString() : "",
                  }));
                  setError((prevInputsState) => ({
                    ...prevInputsState,
                    manager: newValue ? newValue.value : "",
                  }));
                }}
                options={activeManagers}
                getOptionLabel={(option) => option.label || ""}
                renderInput={(params) => <TextField {...params} />}
              />
              <ReusableTextField
                select
                label="Интересующие программы"
                value={inputs.interested}
                onChange={(event) => {
                  setInputs((prevInputsState) => ({
                    ...prevInputsState,
                    interested: event.target ? event.target.value : "",
                  }));
                  setError((prevInputsState) => ({
                    ...prevInputsState,
                    interested: event.target ? event.target.value : "",
                  }));
                }}
                options={programs}
              />
              <ReusableTextField
                select
                label="Источники"
                value={inputs.utm_source}
                onChange={(event) => {
                  setInputs((prevInputsState) => ({
                    ...prevInputsState,
                    utm_source: event.target ? event.target.value : "",
                  }));
                  setError((prevInputsState) => ({
                    ...prevInputsState,
                    utm_source: event.target ? event.target.value : "",
                  }));
                }}
                options={sources}
              />
              {!isValid && error.utm_source === "" && (
                <div className="text-error">Это поле не должно быть пустым</div>
              )}
              <div className="input__label">Дата следующего контакта</div>
              <input
                className="input__datepicker"
                label="Дата следующего контакта"
                type="datetime-local"
                name="date_due"
                min={new Date().toISOString().slice(0, 16)}
                value={inputs.date_due}
                onChange={handleInputsChange}
              />
              {!isValid && error.date_due === "" && (
                <div className="text-error">Это поле не должно быть пустым</div>
              )}
              <div>{inputs.comment}</div>
              <ReusableTextField
                type="text"
                multiline
                label="Добавить комментарий"
                value={inputs.new_comment}
                name="new_comment"
                onChange={handleInputsChange}
              />
              {!isValid && error.new_comment === "" && (
                <div className="text-error">Это поле не должно быть пустым</div>
              )}
              <Divider textAlign="left" sx={{ margin: "20px" }}>
                в некачественные/отказ{" "}
              </Divider>
              <div style={{ display: "flex" }}>
                <div style={{ width: "45%" }}>
                  <TextField
                    size="small"
                    fullWidth
                    sx={{
                      "& label": {
                        color: "#fff",
                        fontFamily: "Nunito Sans",
                        fontWeight: "500",
                        fontSize: "14px",
                        lineHeight: "1.75",
                        alignItems: "center",
                        textTransform: "uppercase",
                      },
                      backgroundColor: "#2999DD",
                      boxShadow:
                        "0px 3px 1px -2px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 1px 5px 0px rgba(0,0,0,0.12)",
                    }}
                    select
                    label="Лид с отказом"
                    name="reject"
                    value={rejectValue || ""}
                    onChange={(event) => {
                      setReject(event.target.value);
                    }}
                  >
                    {reject_reasons &&
                      reject_reasons.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                  </TextField>
                </div>
                <div style={{ width: "45%", marginLeft: "20px" }}>
                  <Tooltip
                    title="Отметить как некачественный"
                    placement="top-start"
                  >
                    <Button
                      fullWidth
                      variant="contained"
                      disabled={Boolean(rejectValue)}
                      onClick={handleAddDisqualified}
                      sx={{
                        backgroundColor: "#2999DD",
                      }}
                    >
                      Некачественный
                    </Button>
                  </Tooltip>
                </div>
              </div>
            </span>
          </DialogContentText>{" "}
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button onClick={handleSubmitLead} variant="contained">
              {" "}
              Создать лид{" "}
            </Button>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseWithConfirm}>Закрыть</Button>
        </DialogActions>
      </Dialog>
      <ConfirmDialog
        open={disqualifiedLid}
        setOpen={setDisqualified}
        onConfirm={confirmDisqualified}
        closeAction={() => {}}
        title="Подтвердите действие"
      >
        Отметить лид как некачественный?
      </ConfirmDialog>
      <ConfirmDialog
        open={Boolean(rejectValue)}
        setOpen={setReject}
        onConfirm={handleSubmitLead}
        closeAction={() => setReject("")}
        title="Подтвердите действие"
      >
        Сохранить Лид с отказом? {findLabelByID(rejectValue, reject_reasons)}
      </ConfirmDialog>
      <ConfirmDialog
        open={closingConfirm}
        setOpen={setClosingConfirm}
        onConfirm={handleSubmitLead}
        closeAction={() => {
          clearInputs();
          onClose();
        }}
        title="Лид не сохранен"
      >
        Вы не сохранили изменения. Сохранить лид перед закрытием?
        <p style={{ fontSize: "14px" }}>(Esc - вернуться к лиду)</p>
      </ConfirmDialog>
    </div>
  );
};

export default React.memo(CreateLeadFromContact);
