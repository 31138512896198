import axios from "axios";
import { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { getDefaultUser, getUserToken } from "./getDefaultUser";
import trackerURL from "./trackerURL";

let eventQueue = [];
const url = trackerURL();
const currentUser = getDefaultUser();
const userInfo = {
  id: currentUser?.id || "",
  name: currentUser?.short_name || "",
  email: currentUser?.email || "",
};
const userToken = getUserToken();

export const log = (
  eventName,
  eventValue = {},
  eventType = {},
  user = { ...userInfo }
) => {
  const currentTime = new Date().toISOString();
  eventQueue = [
    ...eventQueue,
    {
      time: currentTime,
      eventName,
      eventValue,
      eventType,
      user,
      token: userToken,
    },
  ];
  localStorage.setItem("eventQueue", JSON.stringify(eventQueue));
};

// Function to send events to the server using Axios
const sendToServer = (events) => {
  axios
    .post(url, { events })
    .then((response) => {
      console.log("Events sent successfully:", response.data);
      localStorage.removeItem("eventQueue");
    })
    .catch((error) => {
      console.error("Tracker Error, event was not sent to the server:", error);
    });
};

export const flush = () => {
  const eventsToSend = JSON.parse(localStorage.getItem("eventQueue")) || [];
  if (!eventsToSend || eventsToSend.length === 0) {
    return;
  }
  sendToServer(eventsToSend);
  eventQueue = [];
};

export const RouteChangeLogger = () => {
  const history = useHistory();

  useEffect(() => {
    const unlisten = history.listen((location) => {
      log("Route Change", { path: location.pathname });
    });

    return () => {
      unlisten(); // Cleanup the listener when component unmounts
    };
  }, [history]);

  return null;
};

export const sendLessonData = (event) => {
  const sendEvent = {
    ...event,
    user_id: userInfo.id,
    user_name: userInfo.name,
    email: userInfo.email,
    date: new Date().toISOString()
  };
  axios
    .post(url, {
      reviewSubmitted: sendEvent,
    })
    .then((response) => {
      console.log("Event sent successfully:", sendEvent);
    })
    .catch((error) => {
      console.error("Tracker Error, event was not sent to the server:", error);
    });
};
