import { Backdrop, CircularProgress } from "@mui/material";
import { React, useEffect } from "react";
import { connect, useSelector } from "react-redux";
import TopicsAndDatesView from "../../components/TopicsAndDatesView/TopicsAndDatesView";
import {
  fetchTopics,
  removeTopic,
  setSchedulesView,
  updateTopic,
} from "../../redux/actions/topicsAndDatesActions";
import "../../scss/index.scss";
import { setPageTitle } from "../../redux/actions/globalActions";

function TopicsAndDatesPage({ ...props }) {
  const appGlobalState = useSelector(({ globalState }) => globalState);
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const { loading, getTopics, title, setTitle } = props;
  console.log(props);

  useEffect(() => {
    getTopics();
  }, []);
  useEffect(() => {
    setTitle(title);
  }, []);

  return (
    <>
      <div className="content-component__wrapper">
        <h4 className="table__title">{title}</h4>

        {loading ? (
          <Backdrop open={!appGlobalState.loading} sx={backdropStyles}>
            <CircularProgress />{" "}
          </Backdrop>
        ) : (
          <TopicsAndDatesView {...props} />
        )}
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.topicsAndDates.loading,
  schedules: state.topicsAndDates.payload,
  days: state.topicsAndDates.days,
  isMethodist: state.topicsAndDates.isMethodist,
  data: state.topicsAndDates.topics,

  globalState: state.globalState,
  entitiesTypes: state.entitiesTypes,
  permissions: {
    add: state.user.permissions.includes("topic.add"),
    modify: state.user.permissions.includes("topic.modify"),
    remove: state.user.permissions.includes("topic.remove"),
  },
});

const mapDispatchToProps = (dispatch) => ({
  getSchedules: async (data) => {
    await dispatch(setSchedulesView(data));
  },
  getTopics: () => {
    dispatch(fetchTopics());
  },
  updateData: (data) => {
    dispatch(updateTopic(data));
  },
  removeData: (id) => {
    dispatch(removeTopic(id));
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});
export default connect(mapStateToProps, mapDispatchToProps)(TopicsAndDatesPage);
