/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from "react";
import "./ConfigsView.scss";
import EditIcon from "@mui/icons-material/Edit";
import {
  Button,
  MenuItem,
  Select,
  Typography,
  Box,
  Divider,
  IconButton,
  TextField,
} from "@mui/material";
import { Add, Delete } from "@mui/icons-material";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import { settingsApi } from "../../utils/api";
import renderConfigDetails from "./ConfigDetails";
import RenderHolidaysConfigs from "./RenderHolidaysConfigs";
import activeUsersSelection from "../../utils/helpers/activeUserSelection";

const configNamesInRussian = {
  administration: "Администраторы",
  responsibility: "Ответственный",
  category: "Категории и операции",
  holidays: "Праздники",
  default_teacher: "Пользователь по умолчанию",
};

const ConfigsView = ({ entities, configs, ...props }) => {
  const {
    user: users,
    location: locations,
    operation: operations,
    category: categories,
    active_users: activeUsers,
  } = entities;
  console.log("props", props);

  const [configList, setConfigList] = useState(configs);
  const [editMode, setEditMode] = useState(null);
  const [expandedConfig, setExpandedConfig] = useState(null);
  const [editableCategory, setEditableCategory] = useState(null);
  const [selectedOperation, setSelectedOperation] = useState(null);
  const responsabilityConfigs = configList.filter(
    (item) => item.name === "responsibility"
  );
  const supportConfigs = configList.filter((item) => item.name === "support");
  const holidaysConfigs = configList.filter((item) => item.name === "holidays");
  // console.log("holidaysConfig", holidaysConfigs);

  const handleToggleExpand = (configId) => {
    setExpandedConfig((prev) => (prev === configId ? null : configId));
  };

  const [expandResponsabilities, setExpandResponsabilities] =
    React.useState(false);
  const handleToggleExpandResponsabilities = () => {
    setExpandResponsabilities(!expandResponsabilities);
  };
  const [expandSupports, setExpandSupports] = React.useState(false);
  const handleToggleExpandSupports = () => {
    setExpandSupports(!expandSupports);
  };

  const handleEditToggle = (configId) => {
    setEditMode((prev) => (prev === configId ? null : configId));
    // const sendingDrata = configList.find((con) => con.id === configId);
    // settingsApi.updConfig({
    //   config_id: configId,
    //   data: sendingDrata.data,
    //   business: false,
    // });
  };

  const handleConfigChange = (configId, key, newValue) => {
    console.log("CHANGE:", configId, key, newValue);
    console.log("newValue", newValue);
    console.log("CONFIGS:", configs);
    if (configId === "simple_config") {
      setConfigList((prevConfigs) =>
        prevConfigs.map((config) =>
          config.name === "default_teacher"
            ? { ...config, data: { ...config.data, user: newValue } }
            : config
        )
      );
      return;
    }
    if (key === "holidays") {
      setConfigList((prevConfigs) =>
        //
        prevConfigs.map((config) =>
          config.id === configId
            ? {
              ...config,
              data: {
                ...config.data,
                [key]: newValue,
              },
            }
            : config
        )
      );
    }

    setConfigList((prevConfigs) =>
      //
      prevConfigs.map((config) =>
        config.id === configId
          ? {
            ...config,
            data: { ...config.data, [key]: newValue },
          }
          : config
      )
    );
  };

  const handleEditCategory = (category) => {
    setEditableCategory(category);
  };

  const handleSaveCategory = (configId) => {
    setEditableCategory(null);
    console.log("Saved Category", editableCategory);
    const sendingDrata = configList.find((con) => con.id === configId);
    settingsApi.updConfig({
      config_id: configId,
      data: sendingDrata.data,
      business: false,
    });
  };

  const handleDeleteCategory = (categoryId) => {
    console.log("Deleting category:", categoryId);
    setConfigList((prevConfigs) =>
      prevConfigs.map((config) =>
        config.name === "category"
          ? {
            ...config,
            data: config.data.filter(
              (category) => category.category !== categoryId
            ),
          }
          : config
      )
    );
  };

  const handleDeleteOperation = (categoryId, operation) => {
    console.log("Deleting operation:", categoryId, operation);
    console.log("configList", configList);
    setConfigList((prevConfigs) =>
      prevConfigs.map((config) =>
        config.name === "category"
          ? {
            ...config,
            data: config.data.map((category) =>
              category.category === categoryId
                ? {
                  ...category,
                  operations: category.operations.filter(
                    (op) => op.toString() !== operation.toString()
                  ),
                }
                : category
            ),
          }
          : config
      )
    );
  };

  const handleOperationChange = (event) => {
    setSelectedOperation(event.target.value);
  };

  const handleAddOperation = (categoryId, config_id) => {
    if (!selectedOperation === "") return;
    const sendingData = {
      config_id,
      data: {
        category: categoryId,
        operations: [
          ...configList
            .find((config) => config.id === config_id)
            .data.find((item) => item.category === categoryId).operations,
          Number(selectedOperation),
        ],
      },
      business: true,
    };
    settingsApi.updConfig(sendingData).then((res) => {
      console.log("res", res);
    });
    console.log("Adding operation:", selectedOperation);
    console.log("categoryID:", categoryId);
    setConfigList((prevConfig) =>
      prevConfig.map((config) => {
        if (config.name === "category") {
          return {
            ...config,
            data: config.data.map((item) =>
              item.category === categoryId
                ? {
                  ...item,
                  operations: [...item.operations, selectedOperation],
                }
                : item
            ),
          };
        }
        return config;
      })
    );
    setSelectedOperation(null);
  };

  // Render styles
  const renderConfigStyles = (config) => ({
    border: "1px solid #ccc",
    padding: "15px",
    marginBottom: "10px",
    backgroundColor: config.id === editMode ? "#f9f9f9" : "#fff",
  });

  const renderConfigsGroupStyles = () => ({
    border: "1px solid #ccc",
    padding: "15px",
    marginBottom: "10px",
    backgroundColor: "#fff",
  });

  const renderResponsabilityConfigs = (source) => (
    <div>
      <div key="1as" style={renderConfigsGroupStyles()}>
        <div className="category-header-expand">
          <h3 style={{ marginBottom: "16px", color: "#37474f" }}>
            Ответственные
          </h3>
          <Button
            onClick={() =>
              handleToggleExpandResponsabilities(!expandResponsabilities)
            }
          >
            {expandResponsabilities ? "Скрыть" : "Развернуть"}
          </Button>
        </div>
        {expandResponsabilities && (
          <div>
            <div>{renderConfigDetails("responsibility")}</div>
            {source.map((config) => (
              <div key={config.id} style={renderConfigStyles(config)}>
                <div className="category-header-expand">
                  <strong>
                    {configNamesInRussian[config.name]}{" "}
                    {findLabelByID(config.data.location, locations)}
                  </strong>
                </div>

                <div>
                  <div>
                    <label>Локация:</label>
                    {editMode === config.id ? (
                      <Select
                        value={config.data.location}
                        onChange={(e) =>
                          handleConfigChange(
                            config.id,
                            "location",
                            e.target.value
                          )
                        }
                        size="small"
                        sx={{
                          minWidth: 80,
                          height: 30,
                          fontSize: 14,
                          padding: "4px 8px",
                        }}
                      >
                        {locations.map((loc) => (
                          <MenuItem key={loc.value} value={loc.value}>
                            {loc.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <span>
                        {findLabelByID(config.data.location, locations)}
                      </span>
                    )}
                  </div>
                  <div>
                    <label>Пользователь:</label>
                    {editMode === config.id ? (
                      <Select
                        value={config.data.user}
                        onChange={(e) =>
                          handleConfigChange(config.id, "user", e.target.value)
                        }
                        size="small"
                        sx={{
                          minWidth: 80,
                          height: 30,
                          fontSize: 14,
                          padding: "4px 8px",
                        }}
                      >
                        {activeUsersSelection(users, activeUsers)}
                      </Select>
                    ) : (
                      <span>{findLabelByID(config.data.user, users)}</span>
                    )}
                  </div>
                  <Button onClick={() => handleEditToggle(config.id)}>
                    {editMode === config.id ? (
                      <span
                        onClick={() => {
                          handleSaveCategory(config.id);
                        }}
                      >
                        Сохранить
                      </span>
                    ) : (
                      "Редактировать"
                    )}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );

  const renderSupportConfigs = (supportConfig) => {
    console.log("supportConfig", supportConfig);
    return (
      <div>
        <div key="1as" style={renderConfigsGroupStyles()}>
          <div className="category-header-expand">
            <h3 style={{ marginBottom: "16px", color: "#37474f" }}>
              Ответственные за задачи из техподдержи
            </h3>
            <Button onClick={() => handleToggleExpandSupports(!expandSupports)}>
              {expandSupports ? "Скрыть" : "Развернуть"}
            </Button>
          </div>
          {expandSupports && (
            <div>
              <div>{renderConfigDetails("support")}</div>
              {supportConfig.map((config) => (
                <div key={config.id} style={renderConfigStyles(config)}>
                  <div className="category-header-expand">
                    <strong>
                      {configNamesInRussian[config.name]}{" "}
                      {findLabelByID(config.data.location, locations)}
                    </strong>
                  </div>

                  <div>
                    <div>
                      <label>Локация:</label>
                      {editMode === config.id ? (
                        <Select
                          value={config.data.location}
                          onChange={(e) =>
                            handleConfigChange(
                              config.id,
                              "location",
                              e.target.value
                            )
                          }
                          size="small"
                          sx={{
                            minWidth: 80,
                            height: 30,
                            fontSize: 14,
                            padding: "4px 8px",
                          }}
                        >
                          {locations.map((loc) => (
                            <MenuItem key={loc.value} value={loc.value}>
                              {loc.label}
                            </MenuItem>
                          ))}
                        </Select>
                      ) : (
                        <span>
                          {findLabelByID(config.data.location, locations)}
                        </span>
                      )}
                    </div>
                    <div>
                      <label>Пользователь:</label>
                      {editMode === config.id ? (
                        <Select
                          value={config.data.user_id}
                          onChange={(e) =>
                            handleConfigChange(
                              config.id,
                              "user_id",
                              e.target.value
                            )
                          }
                          size="small"
                          sx={{
                            minWidth: 80,
                            height: 30,
                            fontSize: 14,
                            padding: "4px 8px",
                          }}
                        >
                          {activeUsersSelection(users, activeUsers)}
                        </Select>
                      ) : (
                        <span>{findLabelByID(config.data.user_id, users)}</span>
                      )}
                    </div>
                    <Button onClick={() => handleEditToggle(config.id)}>
                      {editMode === config.id ? (
                        <span
                          onClick={() => {
                            handleSaveCategory(config.id);
                          }}
                        >
                          Сохранить
                        </span>
                      ) : (
                        "Редактировать"
                      )}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderCategoryConfig = (config) => (
    <div key={config.id} style={renderConfigStyles(config)}>
      <div className="category-header-expand">
        <h3>{configNamesInRussian[config.name]}</h3>
        <Button onClick={() => handleToggleExpand(config.id)}>
          {expandedConfig === config.id ? "Скрыть" : "Развернуть"}
        </Button>
      </div>
      {expandedConfig === config.id && (
        <div>
          <div>{renderConfigDetails("category")}</div>
          <div>
            {config.data.map((category) => (
              <div key={category.category}>
                <div className="configs__category_delete">
                  <strong>
                    {findLabelByID(category.category, categories)}
                  </strong>
                  {editableCategory?.category === category?.category && (
                    <span
                      onClick={() => {
                        handleDeleteCategory(category.category);
                      }}
                    >
                      <Delete />
                    </span>
                  )}
                </div>

                <div>
                  Операции:
                  <div style={{ display: "flex", gap: 1, flexWrap: "wrap" }}>
                    {category.operations.map((operation, idx) => (
                      <>
                        <Typography
                          key={idx}
                          variant="body1"
                          sx={{
                            backgroundColor: "#e0f7fa",
                            padding: "4px 8px",
                            borderRadius: "4px",
                            fontSize: "0.875rem",
                          }}
                        >
                          {findLabelByID(operation, operations)}
                        </Typography>
                        {editableCategory?.category === category?.category && (
                          <span
                            onClick={() => {
                              handleDeleteOperation(
                                category.category,
                                operation
                              );
                            }}
                          >
                            <Delete />
                          </span>
                        )}
                      </>
                    ))}
                  </div>
                </div>
                {editableCategory &&
                  editableCategory.category === category.category ? (
                  <div>
                    <Select
                      value={selectedOperation || ""}
                      onChange={handleOperationChange}
                      size="small"
                      displayEmpty
                      sx={{
                        minWidth: 80,
                        height: 30,
                        fontSize: 14,
                        padding: "4px 8px",
                      }}
                    >
                      <MenuItem value="" disabled>
                        Выберите операцию
                      </MenuItem>
                      {operations.map((op) => (
                        <MenuItem key={op.value} value={op.value}>
                          {op.label}
                        </MenuItem>
                      ))}
                    </Select>
                    <Button
                      onClick={() =>
                        handleAddOperation(category.category, config.id)
                      }
                      disabled={!selectedOperation}
                    >
                      Добавить операцию
                    </Button>
                  </div>
                ) : (
                  <Button onClick={() => handleEditCategory(category)}>
                    Редактировать категорию
                  </Button>
                )}
                <Divider sx={{ marginTop: 2, marginBottom: 2 }} />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );

  const renderAdministarationConfig = (config) => (
    <div key={config.id} style={renderConfigStyles(config)}>
      <div className="category-header-expand">
        <h3>{configNamesInRussian[config.name]}</h3>{" "}
        <Button onClick={() => handleToggleExpand(config.id)}>
          {expandedConfig === config.id ? "Скрыть" : "Развернуть"}
        </Button>
      </div>
      {expandedConfig === config.id && (
        <div>
          <div>
            {Object.keys(config.data).map((key) => (
              <div className="config-item" key={key}>
                <strong>{findLabelByID(key, locations)}:</strong>
                {editMode === config.id ? (
                  <Select
                    size="small"
                    value={config.data[key]}
                    onChange={(e) =>
                      handleConfigChange(config.id, key, e.target.value)
                    }
                    sx={{
                      minWidth: 80,
                      height: 30,
                      fontSize: 14,
                      padding: "4px 8px",
                    }}
                  >
                    {activeUsersSelection(users, activeUsers)}
                  </Select>
                ) : (
                  <span>{findLabelByID(config.data[key], users)}</span>
                )}
              </div>
            ))}
            <Button onClick={() => handleEditToggle(config.id)}>
              {editMode === config.id ? (
                <span
                  onClick={() => {
                    handleSaveCategory(config.id);
                  }}
                >
                  Сохранить
                </span>
              ) : (
                "Редактировать"
              )}
            </Button>
          </div>
          <div>{renderConfigDetails("administration")}</div>
        </div>
      )}
    </div>
  );

  const renderDefaultTeacherConfig = (config) => (
    <div key={config.id} style={renderConfigStyles(config)}>
      <div className="category-header-expand">
        <h3>{configNamesInRussian[config.name]}</h3>{" "}
        <Button onClick={() => handleToggleExpand(config.id)}>
          {expandedConfig === config.id ? "Скрыть" : "Развернуть"}
        </Button>
      </div>
      {expandedConfig === config.id && (
        <div>
          <div>
            <div className="config-item">
              {editMode === config.id ? (
                <Select
                  size="small"
                  value={config.data.user}
                  onChange={(e) =>
                    handleConfigChange(config.data.type, "key", e.target.value)
                  }
                  sx={{
                    minWidth: 80,
                    height: 30,
                    fontSize: 14,
                    padding: "4px 8px",
                  }}
                >
                  {activeUsersSelection(users, activeUsers)}
                </Select>
              ) : (
                <span>{findLabelByID(config.data.user, users)}</span>
              )}
            </div>

            <Button onClick={() => handleEditToggle(config.id)}>
              {editMode === config.id ? (
                <span
                  onClick={() => {
                    handleSaveCategory(config.id);
                  }}
                >
                  Сохранить
                </span>
              ) : (
                "Редактировать"
              )}
            </Button>
          </div>
          <div>{renderConfigDetails("default_teacher")}</div>
        </div>
      )}
    </div>
  );

  // const responsibilityConfigToRender = responsabilityConfigs.length > 0 ? responsabilityConfigs[0] : null;
  // console.log("respConf", responsabilityConfigs)

  // const [responsabilityConfigs, setResponsabilityConfigs] = React.useState([])

  // code will now render updated responsibility configurations correctly,
  // as responsabilityConfigs will always reflect the latest changes in configList!!!!!

  // React.useEffect(() => {
  //   const finded = configList.filter(
  //     (item) => item.name === "responsibility"
  //   );
  //   setResponsabilityConfigs(finded)
  // }, [configList])

  return (
    <div>
      {responsabilityConfigs.length > 0 &&
        renderResponsabilityConfigs(responsabilityConfigs)}
      {supportConfigs.length > 0 && renderSupportConfigs(supportConfigs)}
      {/* {holidaysConfigs.length > 0 && renderHolidaysConfigs(holidaysConfigs)} */}
      <RenderHolidaysConfigs
        holidayConfig={holidaysConfigs}
        handleConfigChange={handleConfigChange}
        editMode={editMode}
        handleEditToggle={handleEditToggle}
        handleSaveCategory={handleSaveCategory}
        locations={locations}
        configNamesInRussian={configNamesInRussian}
        entities={entities}
      />
      {configList
        .filter((config) => config.name !== "responsibility")
        .map((config) => {
          switch (config.name) {
            case "category":
              return renderCategoryConfig(config);
            case "default_teacher":
              return renderDefaultTeacherConfig(config);
            case "administration":
              return renderAdministarationConfig(config);
            default:
              return null;
          }
        })}
    </div>
  );
};

export default ConfigsView;
