// function creates a simple column
// for correct operation of filters and sorting
// in a column in addition to valueFormatter you need to specify a valueGetter
// entitieArray - array from entitiesType

import { MenuItem, Select } from "@mui/material";
import React from "react";
import { getNameFromArrayId } from "./entityNameById";

// eslint-disable-next-line import/prefer-default-export
export const createTextColumn = (
  field,
  headerName,
  entitieArray,
  editable,
  ...additionalProps
) => ({
  field,
  headerName,
  editable,
  ...additionalProps.reduce((acc, prop) => ({ ...acc, ...prop }), {}),
  valueGetter: (params) => {
    const currentName = entitieArray.find(
      (item) => item.value === params.value
    );
    return currentName?.label;
  },
  valueFormatter: (params) => {
    const currentName = entitieArray.find(
      (item) => item.value === params.value
    );
    return currentName?.label;
  },
  valueSetter: (params, value) => {
    console.log("value", value);
    const currentName = entitieArray.find((item) => item.label === value);
    if (currentName) {
      return currentName.value;
    }
    return params.value;
  },
});

export const createMultipleSelectColumn = (
  field,
  headerName,
  entitieArray,
  editable,
  ...additionalProps
) => ({
  field,
  headerName,
  editable,
  ...additionalProps.reduce((acc, prop) => ({ ...acc, ...prop }), {}),
  valueGetter: (params) => {
    if (Array.isArray(params.value)) {
      const locationArray = params.value.map(
        (label) => entitieArray.find((item) => item.value === label)?.label
      );
      // console.log("params.value", params.value);
      return locationArray.filter((v) => v !== undefined);
    }
    return [];
  },
  valueSetter: (params, value) => {
    const numericValues = params.value.map(
      (label) => entitieArray.find((item) => item.label === label)?.value
    );
    const updatedRowData = { ...params.row, [field]: numericValues };
    return updatedRowData;
  },
  renderEditCell: (params) => 
     (
      <Select
        multiple
        fullWidth
        value={params.value}
        onChange={(e) =>
          params.api.setEditCellValue({ ...params, value: e.target.value })
        }
      >
        {entitieArray.map((option) => (
          <MenuItem key={option.value} value={option.label}>
            {option.label}
          </MenuItem>
        ))}
      </Select>
    )
  ,
});
