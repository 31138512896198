import React from "react";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { Button, MenuItem, Select, TextField } from "@mui/material";
import dayjs from "dayjs";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import renderConfigDetails from "./ConfigDetails";

const RenderHolidaysConfigs = ({
  holidayConfig,
  handleConfigChange,
  editMode,
  handleEditToggle,
  handleSaveCategory,
  configNamesInRussian,
  entities,
}) => {
  const { location: locations, requisites } = { ...entities };

  const [expandHolidays, setExpandHolidays] = React.useState(false);

  const handleToggleExpandHolidays = (isExpanded) => {
    setExpandHolidays(isExpanded);
  };

  const renderConfigStyles = (config) => ({
    border: "1px solid #ccc",
    padding: "15px",
    marginBottom: "10px",
    backgroundColor: config.id === editMode ? "#f9f9f9" : "#fff",
  });

  const renderConfigsGroupStyles = () => ({
    border: "1px solid #ccc",
    padding: "15px",
    marginBottom: "10px",
    backgroundColor: "#fff",
  });

  const trancateRequisites = (array) => {
    const truncateText = (text, maxLength) =>
      text.length > maxLength ? `${text.slice(0, maxLength)}...` : text;
    const truncatedArray = array.map((item) => truncateText(item, 50));
    return truncatedArray;
  };

  return (
    <div>
      <div key="holiday-config-group" style={renderConfigsGroupStyles()}>
        <div className="category-header-expand">
          <h3 style={{ marginBottom: "16px", color: "#37474f" }}>
            Конфигурация праздничных и выходных дней
          </h3>
          <Button onClick={() => handleToggleExpandHolidays(!expandHolidays)}>
            {expandHolidays ? "Скрыть" : "Развернуть"}
          </Button>
        </div>
        {expandHolidays && (
          <div>
            <div>{renderConfigDetails("holidays")}</div>
            {holidayConfig.map((config) => (
              <div key={config.id} style={renderConfigStyles(config)}>
                <div className="category-header-expand">
                  <strong>{configNamesInRussian[config.name]}</strong>
                </div>
                <div>
                  {editMode === config.id ? (
                    <div>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        {config.data.holidays.map((date, index) => (
                          <div
                            style={{
                              display: "flex",
                              alignItems: "center",
                              gap: "8px",
                            }}
                          >
                            <span>{index + 1}</span>
                            <DatePicker
                              key={index}
                              value={dayjs(date)} // Convert the string to a Dayjs object
                              onChange={(newDate) => {
                                const updatedHolidays = [
                                  ...config.data.holidays,
                                ];
                                updatedHolidays[index] =
                                  newDate.format("YYYY-MM-DD"); // Update the specific date
                                handleConfigChange(
                                  config.id,
                                  "holidays",
                                  updatedHolidays
                                );
                              }}
                              renderInput={(params) => (
                                <TextField
                                  {...params}
                                  size="small"
                                  sx={{
                                    width: "100%",
                                    fontSize: 14,
                                    marginBottom: "8px", // Add spacing between date pickers
                                  }}
                                />
                              )}
                            />
                            <Button
                              size="small"
                              color="error"
                              onClick={() => {
                                const updatedHolidays =
                                  config.data.holidays.filter(
                                    (_, i) => i !== index
                                  );
                                handleConfigChange(
                                  config.id,
                                  "holidays",
                                  updatedHolidays
                                );
                              }}
                            >
                              Удалить
                            </Button>
                          </div>
                        ))}
                        <Button
                          variant="outlined"
                          size="small"
                          onClick={() => {
                            const updatedHolidays = [
                              ...config.data.holidays,
                              dayjs().format("YYYY-MM-DD"),
                            ];
                            handleConfigChange(
                              config.id,
                              "holidays",
                              updatedHolidays
                            ); // Add today's date as a new entry
                          }}
                          sx={{ marginTop: "8px" }}
                        >
                          Добавить дату
                        </Button>
                      </LocalizationProvider>
                    </div>
                  ) : (
                    <span>
                      {JSON.stringify(config.data.holidays.join("/"))}
                    </span>
                  )}

                  {/* Handling location instead of requisites */}
                  <div>
                    <strong>Реквизиты:</strong>
                    {editMode === config.id ? (
                      <Select
                        value={config.data.requisites}
                        onChange={(e) =>
                          handleConfigChange(
                            config.id,
                            "requisites",
                            e.target.value
                          )
                        }
                        size="small"
                        sx={{
                          maxWidth: 300,
                          fontSize: 14,
                          padding: "4px 8px",
                        }}
                        multiple
                      >
                        {requisites.map((req) => (
                          <MenuItem key={req.value} value={req.value}>
                            {req.label}
                          </MenuItem>
                        ))}
                      </Select>
                    ) : (
                      <>
                        <span>
                          {config.data.requisites.map((item) => (
                            <>
                              <div>{findLabelByID(item, requisites)}</div>
                              <br />
                            </>
                          ))}
                        </span>
                      </>
                    )}
                  </div>

                  <Button onClick={() => handleEditToggle(config.id)}>
                    {editMode === config.id ? (
                      <span
                        onClick={() => {
                          handleSaveCategory(config.id);
                        }}
                      >
                        Сохранить
                      </span>
                    ) : (
                      "Редактировать"
                    )}
                  </Button>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default RenderHolidaysConfigs;
