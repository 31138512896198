import React, { useEffect, useState } from "react";
import Button from "@mui/material/Button";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import { v4 as uuid } from "uuid";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import {
  Backdrop,
  Box,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import { useDispatch } from "react-redux";
import CloseIcon from "@mui/icons-material/Close";

import "./StudentsKanban.scss";
import { Link } from "react-router-dom";

import Tooltip from "@mui/material/Tooltip";
// import { Box } from "@mui/material/node_modules/@mui/system";

import { modalClose } from "../../redux/actions/studentsKanbanAction";

const StudentsKanban = ({
  groups,
  loading,
  getConfirmData,
  confirmData,
  confirmDataTransfer,
  updStudents,
  modal,
}) => {
  const dispatch = useDispatch();
  const groupsCopy = JSON.parse(JSON.stringify(groups));

  groupsCopy.sort((a, b) => (a.short_name > b.short_name ? 1 : -1));

  const initialState = [];
  const [boards, setBoards] = useState(initialState);
  const [currentBoard, setCurrentBoard] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);

  // first response from api
  // const [confirm, setConfirm] = useState(confirmData);

  // const location = {
  //   pathname: `contracts/${item.id}`,
  // }

  useEffect(() => {
    setBoards(confirmData);
  }, [confirmData]);

  const [open, setOpen] = React.useState(modal);
  const handleClose = () => {
    setOpen(false);
    dispatch(modalClose());
  };

  // -----------select
  const [value, setValue] = React.useState("");
  const findSelectedObject = (selectedvalue) =>
    groupsCopy.find((item) => item.short_name === selectedvalue);
  const handleChange = (event) => {
    setValue(event.target.value);
    setBoards((old) => [...old, findSelectedObject(event.target.value)]);
  };

  // ----------- END select

  // -----------cleaning board dialog window actions
  const [openConfirm, setOpenConfirm] = React.useState(false);

  const handleOpenConfirm = () => {
    setOpenConfirm(true);
  };
  const handleCloseConfirm = () => {
    setOpenConfirm(false);
  };
  const handleCleanBoard = () => {
    setBoards([]);
    setValue("");
    setOpenConfirm(false);
  };
  // ----------- END cleaning board dialog window actions
  // -----------cancel the transfer of students dialog window actions
  const [openResetStudents, setOpenReset] = React.useState(false);

  const handleOpenReset = () => {
    setOpenReset(true);
  };
  const handleCloseReset = () => {
    setOpenReset(false);
  };
  const handleResetStudents = () => {
    const oldValues = [];
    for (let i = 0; i < groupsCopy.length; i++) {
      for (let g = 0; g < boards.length; g++) {
        if (groupsCopy[i].id === boards[g].id) {
          oldValues.push(groupsCopy[i]);
        }
      }
    }
    setBoards(oldValues);
    setValue("");
    handleCloseReset();
  };
  // ----------- END cancel the transfer of students dialog window actions

  const onDragOverHandler = (e) => {
    e.preventDefault();
    if (e.target.className === "item") {
      e.target.style.boxShadow = "0 4px 3px gray";
    }
  };

  const onDragLeaveHandler = (e) => {
    e.target.style.boxShadow = "4px 4px 4px -3px rgba(34, 60, 80, 0.2)";
  };

  const onDragStartHandler = (e, board, item) => {
    setCurrentBoard(board);
    setCurrentItem(item);
    e.target.style.opacity = "0.5";
  };

  const onDragEndHandler = (e) => {
    e.target.style.boxShadow = "4px 4px 4px -3px rgba(34, 60, 80, 0.2)";
    e.target.style.opacity = "1";
  };

  const onDragDropHandler = (e, board, item) => {
    e.preventDefault();
    const currentIndex = currentBoard.students.indexOf(currentItem);
    currentBoard.students.splice(currentIndex, 1);

    const dropIndex = board.students.indexOf(item);
    board.students.splice(dropIndex + 1, 0, currentItem);

    setBoards(
      boards.map((b) => {
        if (b.id === board.id) {
          return board;
        }
        if (b.id === currentBoard.id) {
          return currentBoard;
        }
        return b;
      })
    );
    e.target.style.boxShadow = "4px 4px 4px -3px rgba(34, 60, 80, 0.2)";
  };

  const onDropCardHandler = (e, board) => {
    if (e.target.className === "item") {
      return;
    }
    board.students.push(currentItem);
    const currentIndex = currentBoard.students.indexOf(currentItem);
    currentBoard.students.splice(currentIndex, 1);
    setBoards(
      boards.map((b) => {
        if (b.id === board.id) {
          return board;
        }
        if (b.id === currentBoard.id) {
          return currentBoard;
        }
        return b;
      })
    );

    e.target.style.boxShadow = "none";
  };

  const resetBoards = () => {
    handleOpenReset();
  };

  const submitGroupsData = () => {
    // setConfirm(null);
    const boardsData = [];
    for (let i = 0; i < boards.length; i++) {
      const { id, students, short_name: name } = boards[i];
      boardsData.push({ show: "preview", id, students, name });
    }
    const submitData = {
      students_update: [...boardsData],
    };
    getConfirmData(submitData);
  };

  const confirmGroupsData = () => {
    // setConfirm(null);
    const boardsData = [];
    for (let i = 0; i < boards.length; i++) {
      const { id, students, name } = boards[i];
      boardsData.push({ show: "commit", id, students, name });
    }
    const submitData = {
      students_update: [...boardsData],
    };

    handleClose();
    updStudents(submitData);
    boards.length = 0;
  };

  const closeModal = () => {
    handleClose();
    setBoards([]);
  };

  const clearBoards = () => {
    handleOpenConfirm(true);
    // setBoards([]);
  };

  // scroll on Dialog window
  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <div className="groups__control">
        <div>
          <Button
            disabled={boards.length === 0}
            variant="contained"
            size="small"
            onClick={submitGroupsData}
            sx={{ marginRight: "10px" }}
          >
            Отправить
          </Button>
          <Button
            variant="contained"
            size="small"
            onClick={resetBoards}
            sx={{ marginRight: "10px" }}
          >
            Отменить текущие изменения
          </Button>
          <Button variant="contained" size="small" onClick={clearBoards}>
            Закрыть группы
          </Button>
        </div>
        <div className="select__helper">
          Выберите нужные Вам группы из выпадающего списка ниже:
        </div>
        <div className="border__header">
          <div>
            <FormControl size="small" sx={{ width: "250px" }}>
              <InputLabel variant="outlined">Группы</InputLabel>
              <Select value={value} label="группа" onChange={handleChange}>
                {groupsCopy.map(({ id, short_name }) => (
                  <MenuItem key={id} value={short_name}>
                    <span key={uuid()} />
                    {short_name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>

          <div className="border_choosen_box">
            {boards.map((board) => (
              <span key={uuid()} className="border_choosen">
                {" "}
                {board.short_name || board.name}{" "}
              </span>
            ))}
          </div>
        </div>
      </div>

      {boards.length !== 0 ? null : (
        <div className="empty__groups">Группы не выбраны</div>
      )}

      <div className="main">
        {boards.map((board) =>
          board == null ? null : (
            <div
              key={board.id}
              className="board"
              onDragOver={(e) => {
                onDragOverHandler(e);
              }}
              onDrop={(e) => {
                onDropCardHandler(e, board);
              }}
            >
              <div className="board__title">
                {board.short_name || board.name}
              </div>
              <div className="items_box">
                {board.students.map((item) => (
                  <Tooltip
                    key={item.id}
                    TransitionProps={{ timeout: 800 }}
                    title={
                      <>
                        <Typography color="inherit">
                          {item.name}
                          <br />{" "}
                          <Link
                            to={{
                              pathname: `contracts/${item.id}`,
                              state: "studentsKanban",
                            }}
                            style={{ color: "inherit" }}
                          >
                            <u>Перейти на Договор</u>
                          </Link>
                        </Typography>
                      </>
                    }
                    arrow
                    placement="top-end"
                  >
                    <Box>
                      <div
                        id={item.id}
                        key={item.id}
                        className="item"
                        onDragOver={(e) => {
                          onDragOverHandler(e);
                        }}
                        onDragLeave={(e) => {
                          onDragLeaveHandler(e);
                        }}
                        onDragStart={(e) => {
                          onDragStartHandler(e, board, item);
                        }}
                        onDragEnd={(e) => {
                          onDragEndHandler(e);
                        }}
                        onDrop={(e) => {
                          onDragDropHandler(e, board, item);
                        }}
                        draggable
                        // title={item.name}
                      >
                        {item.name}
                      </div>
                    </Box>
                  </Tooltip>
                ))}
              </div>
              <Button
                variant="contained"
                size="small"
                color="primary"
                sx={{
                  color: "#fff",
                  margin: "10px 0 ",
                  padding: "0 5px",
                }}
              >
                {" "}
                <Link
                  to={{
                    pathname: `attendance-reports/${board.id}`,
                    state: "studentsKanban",
                  }}
                  style={{ color: "#fff" }}
                >
                  перейти на посещаемость
                </Link>
              </Button>
            </div>
          )
        )}
        <div>
          <Dialog
            open={open}
            onClose={closeModal}
            scroll="paper"
            aria-labelledby="scroll-dialog-title"
            aria-describedby="scroll-dialog-description"
            maxWidth="auto"
          >
            <DialogTitle id="scroll-dialog-title">
              Изменить группы:
              <div
                style={{ float: "right", cursor: "pointer" }}
                title="зыкрыть"
                onClick={closeModal}
              >
                <CloseIcon />
              </div>
            </DialogTitle>
            <DialogContent dividers>
              <DialogContentText
                component="div"
                id="scroll-dialog-description"
                ref={descriptionElementRef}
                tabIndex={-1}
              >
                {confirmDataTransfer ? (
                  <div className="confirm__container">
                    {confirmDataTransfer.map((item) => (
                      <div key={uuid()} className="pupil__info">
                        Ученик {item.student_name} переведен
                        <div className="groups__wrapper">
                          <div className="group__box left">
                            из группы:{" "}
                            <div className="group_name">
                              {item.old_group_name}
                            </div>
                          </div>{" "}
                          <div className="group__box middle">
                            {" "}
                            <ArrowRightAltIcon />{" "}
                          </div>
                          <div className="group__box right">
                            в группу:{" "}
                            <div className="group_name">{item.name_group}</div>
                          </div>{" "}
                        </div>
                      </div>
                    ))}
                  </div>
                ) : (
                  <Backdrop open={loading}>
                    <CircularProgress color="inherit" />
                  </Backdrop>
                )}
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <div className="confirm__buttons">
                <Button
                  onClick={confirmGroupsData}
                  variant="contained"
                  sx={{ marginRight: "20px" }}
                >
                  подтвердить
                </Button>
                <Button onClick={closeModal} variant="contained" color="grey">
                  отменить изменения
                </Button>
              </div>
            </DialogActions>
          </Dialog>
        </div>
        <div className="confirm__board_clear">
          <Dialog
            open={openConfirm}
            onClose={handleCloseConfirm}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              Вы действительно хотите закрыть текущие группы?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Все текущие изменения в группах будут отменены. Группы будут
                закрыты.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="grey"
                onClick={handleCloseConfirm}
              >
                Отмена
              </Button>
              <Button variant="contained" onClick={handleCleanBoard}>
                Закрыть группы
              </Button>
              {/* <Button onClick={handleClose} autoFocus> */}
              {/* </Button> */}
            </DialogActions>
          </Dialog>
        </div>
        <div className="confirm__reset_students">
          <Dialog
            open={openResetStudents}
            onClose={handleCloseReset}
            aria-labelledby="alert-reset_students"
            aria-describedby="alert-reset_students"
          >
            <DialogTitle id="alert-reset_students">
              Вы действительно хотите отменить изменения в открытых группах?
            </DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                Все текущие изменения в группах будут отменены. Группы
                остануться открыты.
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                variant="contained"
                color="grey"
                onClick={handleCloseReset}
              >
                Отмена
              </Button>
              <Button variant="contained" onClick={handleResetStudents}>
                Отменить изменения в группах
              </Button>
              {/* <Button onClick={handleClose} autoFocus> */}
              {/* </Button> */}
            </DialogActions>
          </Dialog>
        </div>
      </div>
    </>
  );
};

export default StudentsKanban;
