/* eslint-disable jsx-a11y/media-has-caption */
import React from "react";
import { Badge, Tooltip } from "@material-ui/core";
import { Notifications as NotificationsIcon } from "@material-ui/icons";
import "./UserNotifications.scss";
import { useDispatch, useSelector } from "react-redux";
import { Chip, Divider, Stack } from "@mui/material";
import dayjs from "dayjs";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import {
  getDefaultUser,
  getPermissions,
} from "../../utils/helpers/getDefaultUser";
import TaskModalUpdate from "../TaskModal/TaskModalUpdate";
import {
  setUserPersonalTasks,
  updateTaskStatus,
} from "../../redux/actions/tasksActions";
// import audioFile from "../../assets/notification.mp3";
import ParentForLeadsAndContact from "../ModalsForSalesWithAgreementsAndLeads/ParentForLeadsAndContact";
import { setUserPersonalLeads } from "../../redux/actions/leadsActions";

const UserNotifications = ({ numNotifications, ...props }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [users, setUsers] = React.useState([]);
  const employees = useSelector(({ entitiesTypes }) => entitiesTypes.user);
  const customers = useSelector(({ entitiesTypes }) => entitiesTypes.customers);

  const dispatch = useDispatch();
  const dropdownRef = React.useRef(null);

  const tasks = useSelector((state) => state.tasks.payload);
  const leads = useSelector((state) => state.leads.payload);
  const [openTask, isOpenTask] = React.useState(false);
  const user = getDefaultUser();
  const permissions = getPermissions();

  const findTasksForThisClient = (t, u) => {
    const filtered = t?.filter((task) => task.executor === u.id.toString());
    return filtered || [];
  };

  const findLeadsForThisClient = (l, u) => {
    const filtered = l?.filter((lead) => lead.manager === u.id.toString());
    return filtered || [];
  };

  const dateCompare = (date) => {
    const nowDateObject = dayjs();
    const dateDueObject = dayjs(date);
    if (nowDateObject < dateDueObject) {
      return false;
    }
    if (nowDateObject > dateDueObject) {
      return true;
    }
    return false;
  };

  // request to receive tasks and leads for the current user.
  // but if the user is on the /crm page, then he has a complete list of leads
  // and set personal ones in redax does not make sense.
  // if he is on the /tasks page he has a complete list of tasks and a set of leads is not needed

  const userLocationCheck = () => {
    if (
      permissions.includes("tasks.view") &&
      permissions.includes("leads.view") &&
      !window.location.pathname.includes("/crm") &&
      !window.location.pathname.includes("/tasks")
    ) {
      dispatch(setUserPersonalTasks());
      dispatch(setUserPersonalLeads());
    }
    if (
      permissions.includes("tasks.view") &&
      permissions.includes("leads.view") &&
      window.location.pathname.includes("/crm")
    ) {
      dispatch(setUserPersonalTasks());
    }
    if (
      permissions.includes("tasks.view") &&
      permissions.includes("leads.view") &&
      window.location.pathname.includes("/tasks")
    ) {
      dispatch(setUserPersonalLeads());
    }
  };

  const [clientTasks, setClientTasks] = React.useState([]);
  const [clientLeads, setClientLeads] = React.useState([]);

  React.useEffect(() => {
    userLocationCheck();
  }, []);

  React.useEffect(() => {
    setClientTasks(findTasksForThisClient(tasks, user));
  }, [tasks]);

  React.useEffect(() => {
    setClientLeads(findLeadsForThisClient(leads, user));
  }, [leads]);
  React.useEffect(() => {
    if (employees && customers) {
      setUsers([...employees, ...customers]);
    }
  }, [employees, customers]);

  const newTasksNotificationsNumber =
    clientTasks?.filter((task) => task.status === "new").length || 0;
  const newLeadsNotificationsNumber =
    clientLeads?.filter((lead) => lead.status === "new").length || 0;
  const expiredNotificationsNumber =
    clientTasks?.filter(
      (task) =>
        dateCompare(task.date_due) &&
        task.status !== "closed" &&
        task.status !== "denied_execution" &&
        task.status !== "completed"
    ).length || 0;

  const badgeContent = (
    <div style={{ display: "flex", alignItems: "center" }}>
      <span>{newTasksNotificationsNumber + newLeadsNotificationsNumber}</span>
    </div>
  );

  const [infoModal, setInfoModal] = React.useState([]);

  const handleOpenTask = (task) => {
    setInfoModal(task);
    isOpenTask(true);
    setIsOpen(false);
  };

  const handleAcceptTask = (task) => {
    dispatch(updateTaskStatus({ id_task: task.id_task, status: "in_work" }));
    task.status = "in_work";
  };

  const [isLeadOpen, setIsLeadOpen] = React.useState(false);
  const handleLeadClose = () => {
    setIsLeadOpen(false);
  };
  const [openingLead, setLead] = React.useState([]);
  const handleLeadOpen = (l) => {
    setIsLeadOpen(true);
    setLead(l);
  };

  // const [audio, setAudio] = React.useState(new Audio(audioFile));
  // const playAudio = () => {
  //   audio.play();
  // };

  // React.useEffect(() => {
  //   // Trigger audio playback on user interaction
  //   document.addEventListener('click', playAudio);

  //   // Cleanup the event listener on component unmount
  //   return () => {
  //     document.removeEventListener('click', playAudio);
  //   };
  // }, [ tasks, leads]);

  React.useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const toggleDropdown = (e) => {
    e.preventDefault();
    e.stopPropagation();
    // console.log("e", e.target);
    setIsOpen((prev) => !prev);
  };
  return (
    <>
      <Tooltip title="Уведомления">
        <div style={{ marginRight: "30px" }} onClick={toggleDropdown}>
          <Badge
            badgeContent={badgeContent}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            color="primary"
            overlap="rectangular"
          >
            <NotificationsIcon
              style={{
                marginRight: "10px",
                animation:
                  expiredNotificationsNumber > 0
                    ? "blinking 1s infinite"
                    : "none",
              }}
            />
          </Badge>
          <Badge
            badgeContent={expiredNotificationsNumber}
            color="error"
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            overlap="rectangular"
          />
        </div>
      </Tooltip>

      {isOpen && (
        <div className="notifications__dropdown_wrapper" ref={dropdownRef}>
          <span
            className="notifications__close_btn"
            onClick={() => {
              setIsOpen(false);
            }}
          >
            X
          </span>
          <div className="notifications__dropdown_header">
            <h5 style={{ margin: "5px 0px" }}>Уведомления</h5>
            {/* <div className="notifications__header_controls">
              <span>непрочитанные</span>
              <span>все уведомления</span>
            </div> */}
          </div>

          <ul className="notifications__list">
            {newTasksNotificationsNumber > 0 && (
              <Divider style={{ fontSize: "12px", color: "#3f51b5" }}>
                {" "}
                новые задачи ({newTasksNotificationsNumber}){" "}
              </Divider>
            )}
            {clientTasks
              .filter((task) => task.status === "new")
              .map((task) => (
                <li className="notifications__row" key={task.id_task}>
                  <span className="notifications__row_author">
                    <strong>
                      {findLabelByID(task.author || task.autor, users)}
                    </strong>{" "}
                    назначает Вам новую задачу ,<>{task.title}</>
                  </span>{" "}
                  <span className="notifications__row_buttons">
                    <Stack
                      direction="row"
                      spacing={1}
                      className="notifications__row_buttons_stack"
                    >
                      <Chip
                        label="В работу"
                        color="primary"
                        size="small"
                        disabled={task.status !== "new"}
                        onClick={() => {
                          handleAcceptTask(task);
                        }}
                      />
                      <Chip
                        label="Открыть"
                        color="success"
                        size="small"
                        onClick={() => {
                          handleOpenTask(task);
                        }}
                      />
                    </Stack>
                  </span>
                </li>
              ))}
          </ul>
          <Divider />
          <Divider />
          <ul className="notifications__list">
            {newLeadsNotificationsNumber > 0 && (
              <Divider style={{ fontSize: "12px", color: "#3f51b5" }}>
                {" "}
                новые лиды ({newLeadsNotificationsNumber}){" "}
              </Divider>
            )}
            {clientLeads
              .filter((lead) => lead.status === "new")
              .map((lead) => (
                <li className="notifications__row" key={lead.id_task}>
                  <span className="notifications__row_author">
                    на вас пришел новый ЛИД <strong>{lead.name}</strong>
                  </span>{" "}
                  <span className="notifications__row_buttons">
                    <Stack
                      direction="row"
                      spacing={1}
                      className="notifications__row_buttons_stack"
                    >
                      <Chip
                        label="Открыть"
                        color="success"
                        size="small"
                        onClick={() => {
                          handleLeadOpen(lead);
                        }}
                      />
                    </Stack>
                  </span>
                </li>
              ))}
          </ul>
          <ul className="notifications__list">
            {expiredNotificationsNumber > 0 && (
              <Divider style={{ fontSize: "12px", color: "red" }}>
                просроченные задачи ({expiredNotificationsNumber})
              </Divider>
            )}
            {clientTasks
              .filter(
                (task) =>
                  dateCompare(task.date_due) &&
                  task.status !== "closed" &&
                  task.status !== "denied_execution" &&
                  task.status !== "completed"
              )
              .map((task) => (
                <li className="notifications__row" key={task.id_task}>
                  <span className="notifications__row_author">
                    <strong>просрочена</strong> ,<>{task.title}</>
                  </span>{" "}
                  <span className="notifications__row_buttons">
                    <Stack
                      direction="row"
                      spacing={1}
                      className="notifications__row_buttons_stack"
                    >
                      <Chip
                        label="В работу"
                        color="primary"
                        size="small"
                        disabled={task.status !== "new"}
                        onClick={() => {
                          handleAcceptTask(task);
                        }}
                      />
                      <Chip
                        label="Открыть"
                        color="success"
                        size="small"
                        onClick={() => {
                          handleOpenTask(task);
                        }}
                      />
                    </Stack>
                  </span>
                </li>
              ))}
          </ul>
        </div>
      )}
      {openTask && (
        <TaskModalUpdate
          task={infoModal}
          onClose={() => {
            isOpenTask(false);
          }}
          {...props}
        />
      )}
      <ParentForLeadsAndContact
        open={isLeadOpen}
        onClose={handleLeadClose}
        lead={openingLead}
        type="lead"
      />
    </>
  );
};

export default UserNotifications;
