import axios from "../../config/axiosConfig";

export default {
	new: {
		get: () => axios.get("/report/new/get"),
	},
	ongoing: {
		get: () => axios.get("/report/ongoing/get"),
	},
	payByDayAndByCity: {
		get: () => axios.get("/report/pay_by_day_and_by_city/get"),
	},
	camp: {
		get: () => axios.get("/report/visit/students"),
	},
	firstVisit: {
		get: () => axios.get("/report/first_visit/get"),
	},
};
