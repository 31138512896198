/* eslint-disable no-restricted-globals */
import { Box, Button, FormControl, MenuItem, TextField } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import DataGrid from "../DataGrid/DataGrid";
import {
  getPermissions,
  getUserLocation,
} from "../../utils/helpers/getDefaultUser";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import { postCost } from "../../redux/actions/costsActions";
import { addNewEntity } from "../../redux/actions/entitiesTypesActions";

dayjs.locale("ru");

const AddingEntities = ({
  entitiesTypes,
  permissions,
  data,
  uniqueTypes,
  updEntity,
  removeEntity,
  ...props
}) => {
  const initialCostsState = {
    type: "",
    value: "",
  };
  console.log('props', props);

  const userPermissions = getPermissions();
  const userLocations = getUserLocation();
  const dispatch = useDispatch();

  const [entitiesState, setCostsState] = React.useState(initialCostsState);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [filteredOperations, setFilteredOperations] = React.useState([]);
  const [avaliableLocations, setAvaliableLocations] = React.useState([]);
  // const [data, setData] = React.useState([]);
  const {
    category: categories,
    operation: operations,
    select_rec: requisites,
    location: locations,
  } = { ...entitiesTypes };

  const checkAvailableLocations = () => {
    const values = locations.map((loc) => loc.value);
    const filteredLocations = values.filter((value) =>
      userLocations.includes(value)
    );
    setAvaliableLocations(filteredLocations);
  };

  const dateSetterFunc = (value) => {
    if (value) {
      return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
    }
    const today = new Date();
    return dayjs(today).format("YYYY-MM-DD HH:mm:ss");
  };

  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    setCostsState({
      ...entitiesState,
      [name]: value,
    });
  };

  const handleSendNewEntity = () => {
    if (!entitiesState.type || !entitiesState.value) {
      alert("заполните все поля");
      return;
    }
    dispatch(addNewEntity(entitiesState));
    console.log("entitiesState", entitiesState);
  };

  const columns = [
    {
      field: "id",
      hide: true,
      flex: 1,
    },
    {
      field: "type",
      headerName: "Вид",
      flex: 1,
      editable: true,
    },
    {
      field: "value",
      headerName: "Значение",
      editable: true,
      flex: 1,
    },
  ];

  if (
    !userPermissions.includes("transactions.view") ||
    !userPermissions.includes("transactions.add")
  ) {
    return <div>У вас нет прав для просмотра страницы</div>;
  }

  return (
    <div>
      <div className="sales_view" style={{ paddingTop: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start-end",
            flexWrap: "wrap",
            margin: "10px 20px 10px 20px",
          }}
          component="div"
          noValidate
        >
          <FormControl>
            <TextField
              id="outlined-select-user"
              select
              name="type"
              label="Вид сущности"
              size="small"
              value={entitiesState.type}
              onChange={handleInputsChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "fit-content",
                minWidth: "250px",
                margin: "5px",
              }}
            >
              {uniqueTypes.map((cat) => (
                <MenuItem key={cat} value={cat}>
                  {cat}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <FormControl>
            <TextField
              name="value"
              size="small"
              id="outlined-group_id"
              selectedCategory
              value={entitiesState.value}
              label="Значение"
              // eslint-disable-next-line jsx-a11y/aria-proptypes
              aria-sort="label"
              onChange={handleInputsChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "fit-content",
                minWidth: "150px",
                margin: "5px",
              }}
            />
          </FormControl>

          <Button
            variant="contained"
            onClick={handleSendNewEntity}
            sx={{
              margin: "5px",
              height: "40px",
            }}
          >
            Отправить
          </Button>
        </Box>
      </div>
      <div style={{ paddingLeft: "20px" }}> общая сумма : {totalAmount}</div>
      <DataGrid
        // onEditCellPropsChange={(event, row) =>
        //   handleCellPropsChange(event, row)
        // }
        columns={columns}
        entityName="entity"
        dataGridName="entity_table"
        getRowId={(row) => `${row.id}`}
        data={data}
        globalState={{
          loading: false,
        }}
        permissions={permissions}
        updateData={updEntity}
        removeData={removeEntity}
      />
    </div>
  );
};

export default AddingEntities;
