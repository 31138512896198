import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
} from "@mui/material";

const ConfirmationDialogForTask = ({
  open,
  onClose,
  onConfirm,
  title,
  content,
}) => {
  const [reasonValue, setReasonValue] = React.useState("");
  const [disableConfirmBtn , setDisableConfirmBtn] = React.useState(true)
  const handleReasonChange = (event) => {
    setReasonValue(event.target.value);
    if(event.target.value.length > 5) {
      setDisableConfirmBtn(false);
    }
    else setDisableConfirmBtn(true)
  };
  const hanleCloseDialog = () => {
    setReasonValue("");
    onClose();
  };
  const handleConfirm = () => {
    onConfirm(reasonValue);
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent  >{content}</DialogContent>
      <DialogActions style={{display:"flex", flexDirection: "column", justifyContent: "flex-start", alignItems:"flex-start"}} >
        <TextField
          label="Не менее 6 символов"
          multiline
          rows={4}
          fullWidth
          value={reasonValue}
          name="reason"
          onChange={handleReasonChange}
        />
        <div style={{display:"flex", flexDirection:"row", gap: "10px", marginTop: "20px"}} >
        <Button onClick={hanleCloseDialog}  variant="contained" size="small" >
          Отмена
        </Button>
        <Button onClick={handleConfirm}  variant="contained" size="small" autoFocus disabled = {disableConfirmBtn}>
          Подтвердить
        </Button>
        </div>

      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationDialogForTask;
