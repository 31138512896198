import { workingOutApi } from '../../utils/api';
import notification from '../../utils/notification/notification';

export const getWorkingOut = (data) => ({
	type: 'GET_WORKING_OUT',
	payload: data,
	loading: false,
});

export const loadingWorkingOut = () => ({
	type: 'LOADING_WORKING_OUT',
	loading: true,
});

export const setUpdatedWorkingOut = (data) => ({
	type: 'UPDATE_WORKING_OUT',
	payload: data,
	loading: false,
});

export const deleteWorkingOut = (id_temporary_arrangement) => ({
	type: 'REMOVE_WORKING_OUT',
	payload: id_temporary_arrangement,
	loading: false,
});

export const clearWorkingOut = () => ({
	type: 'CLEAR_WORKING_OUT',
});

export const fetchWorkingOut = () => (dispatch) => {
	dispatch(loadingWorkingOut());
	workingOutApi.get().then(({ data }) => dispatch(getWorkingOut(data)));
};

export const updateWorkingOut = (obj) => (dispatch) => {
	dispatch(loadingWorkingOut());
	return workingOutApi.post(obj).then(({ data }) => {
		notification.success('Группа для отработки изменена');
		dispatch(setUpdatedWorkingOut(data));
	});
};

export const removeWorkingOut = (idObj) => (dispatch) => {
	console.log('removeWorkingOut', idObj);
	dispatch(loadingWorkingOut());
	return workingOutApi.remove(idObj).then(() => {
		notification.success('Отработка удалена');
		dispatch(deleteWorkingOut(idObj));
	});
};
