/* eslint-disable array-callback-return */
/* eslint-disable consistent-return */
/* eslint-disable react/button-has-type */
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Button } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import "./TaskModal.scss";
import { ReusableTextField } from "../ReusableInputs/ReusableInputs";
import SimpleConfirmModal from "../ReusableSimpleConfirmatin/SimpleConfirmModal";
import { supportApi } from "../../utils/api";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import { silentSupportHistory } from "../../redux/actions/journalActions";

const TaskModalSupport = ({ open, onClose, task }) => {
  const defaultUser = useSelector(({ user }) => user.info);
  const managers = useSelector(({ entitiesTypes }) => entitiesTypes.user);
  const dispatch = useDispatch();

  const initialMessageState = {
    message: "",
  };
  const [messageValue, setMessageValue] = React.useState(initialMessageState);

  const handleModalClose = () => {
    setMessageValue(initialMessageState);
    onClose(false);
  };

  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    setMessageValue((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  // simpleModal Notification
  const [isNotificationOpen, setNotificationOpen] = useState(false);

  const openNotificationModal = () => {
    setNotificationOpen(true);
  };
  const closeNotificationModal = () => {
    setNotificationOpen(false);
  };
  const hanleConfirm = () => {
    closeNotificationModal();
    handleModalClose();
  };

  const [dedicatedManager, setDedicatedManager] = useState(null);

  const handleMessageSubmit = () => {
    // dispatch(addNewTask(submit));
    if (!messageValue.message) return;
    supportApi.support(messageValue).then((res) => {
      if (res.status === 200) {
        const id = res.data?.toString();
        dispatch(silentSupportHistory());
        setDedicatedManager(findLabelByID(id, managers));
        openNotificationModal();
      }
    });
  };

  const handleMessageCancel = () => {
    handleModalClose();
  };

  if (!open) return null;
  return (
    <>
      <div className="popup__wrapper">
        <div className="popup-overlay" />
        <div className="task__popup_support">
          <div className="popup__container_support">
            <CloseIcon
              style={{
                position: "absolute",
                right: "0%",
                top: "0%",
                cursor: "pointer",
              }}
              onClick={handleModalClose}
            />
            <div className="popup__main_support">
              <div>
                <ReusableTextField
                  label="Сообщение:"
                  multiline
                  fullWidth
                  rows={6}
                  value={messageValue.message}
                  name="message"
                  onChange={handleInputsChange}
                />
              </div>
            </div>
          </div>

          <div className="popup__footer">
            <div className="wrapper__support_buttons">
              <Button onClick={handleMessageSubmit} variant="contained">
                Отправить
              </Button>
              <Button onClick={handleMessageCancel} variant="contained">
                Отмена
              </Button>
            </div>
          </div>
        </div>
      </div>
      <SimpleConfirmModal
        isOpen={isNotificationOpen}
        onClose={closeNotificationModal}
        onConfirm={hanleConfirm}
        content={` Ваше сообщение отправлено в техподдержку менеджеру ${dedicatedManager}`}
      />
    </>
  );
};

export default TaskModalSupport;
