import { BrowserRouter, Route, Switch } from "react-router-dom";
import React from "react";
import Auth from "./Auth";
import Home from "../pages/Home";
import LoginForm from "../pages/Auth/containers/LoginForm";
import { RouteChangeLogger } from "../utils/helpers/tracker";

function Routes() {
  return (
    <BrowserRouter>
      <RouteChangeLogger />
      <Switch>
        <Route path="/app">
          <Home />
        </Route>
        <Route path="/login" component={LoginForm} />
        <Route path="">
          <Auth />
        </Route>
      </Switch>
    </BrowserRouter>
  );
}
export default Routes;
