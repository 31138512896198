/* eslint-disable react/no-array-index-key */
import React from "react";
import "./FoodDebt.scss";
import { Button } from "@mui/material";
import { debtApi } from "../../utils/api";
import { sortByField } from "../../utils/helpers/sorting";
import SimpleConfirmModal from "../../components/ReusableSimpleConfirmatin/SimpleConfirmModal";

const FoodDebt = ({
  contractInfo,
  data,
  closeDialog,
  updateContract,
  ...props
}) => {
  const [isEditable, setIsEditable] = React.useState(true);
  const handleSetContract = (number) => {
    updateContract(number);
  };
  const initialState = [
    {
      date: "",
      breakfast: "",
      lunch: "",
      snack: "",
      extension: "",
      electives: "",
    },
    {
      date: "",
      breakfast: "",
      lunch: "",
      snack: "",
      extension: "",
      electives: "",
    },
    {
      date: "",
      breakfast: "",
      lunch: "",
      snack: "",
      extension: "",
      electives: "",
    },
    {
      date: "",
      breakfast: "",
      lunch: "",
      snack: "",
      extension: "",
      electives: "",
    },
    {
      date: "",
      breakfast: "",
      lunch: "",
      snack: "",
      extension: "",
      electives: "",
    },
    {
      date: "",
      breakfast: "",
      lunch: "",
      snack: "",
      extension: "",
      electives: "",
    },
    {
      date: "",
      breakfast: "",
      lunch: "",
      snack: "",
      extension: "",
      electives: "",
    },
    {
      date: "",
      breakfast: "",
      lunch: "",
      snack: "",
      extension: "",
      electives: "",
    },
    {
      date: "",
      breakfast: "",
      lunch: "",
      snack: "",
      extension: "",
      electives: "",
    },
    {
      date: "",
      breakfast: "",
      lunch: "",
      snack: "",
      extension: "",
      electives: "",
    },
  ];
  const [tableData, setTableData] = React.useState(initialState);

  const handleDataChange = (index, field, value) => {
    const updatedData = [...tableData];
    updatedData[index][field] = value;
    setTableData(updatedData);
  };

  const [isConfirmDialogOpen, setConfirmDialogOpen] = React.useState(false);

  const handleOpenConfirmDialog = () => {
    setConfirmDialogOpen(true);
  };

  const handleCloseConfirmDialog = () => {
    setConfirmDialogOpen(false);
  };

  const handleConfirmAction = () => {
    setConfirmDialogOpen(false);
  };

  const handleCloseDialog = () => {
    setTableData(initialState);
    closeDialog();
  };
  const handleSubmitFoodDebt = () => {
    const receivable = [];
    let validationError = false;

    tableData.forEach((row, rowIndex) => {
      const date = row.date || "0";

      // Check if any field besides date is filled
      const isOtherFieldsFilled = [
        "breakfast",
        "lunch",
        "snack",
        "extension",
        "electives",
      ].some((field) => row[field] !== "");

      // If other fields are filled but date is empty, throw a validation error
      if ((isOtherFieldsFilled && date === "0") || date === "") {
        validationError = true;
        alert(`Вы не заполнили дату в строке ${rowIndex + 1}`);
        // setRequiredDateRow(rowIndex + 1);
        return;
      }
      if (date !== "0") {
        const types = ["breakfast", "lunch", "snack", "extension", "electives"];
        types.forEach((type) => {
          const amount = row[type] || 0;
          receivable.push({ amount, type, date });
        });
      }
    });

    if (validationError) {
      return;
    }

    const outputData = { number: contractInfo.number, receivable };

    debtApi.postFoodDebt(outputData).then((response) => {
      if (response.status === 200) {
        handleSetContract(contractInfo.number);
        handleOpenConfirmDialog();
      }
    });
  };

  React.useEffect(() => {
    if (data && data.length) {
      const updatedTableData = [...tableData];

      data.forEach((item) => {
        if (item.category !== "main") {
          // Find the index of the row with the same date
          const rowIndex = updatedTableData.findIndex(
            (row) => row.date === item.date
          );
          if (rowIndex !== -1) {
            // Update the specific category field
            updatedTableData[rowIndex][item.category] = item.amount;
          } else {
            // If date not found, create a new row for that date
            const newRow = {
              date: item.date,
              breakfast: "",
              lunch: "",
              snack: "",
              extension: "",
              electives: "",
            };
            if (item.amount !== "0.0") {
              newRow[item.category] = item.amount;
              updatedTableData.unshift(newRow);
            }
          }
        }
      });
      // Update the table data with the new values
      // const sliced = updatedTableData.slice(0, 10);
      console.log("updatedTableData", updatedTableData);
      setTableData(updatedTableData.sort(sortByField("date")).reverse());
    }
  }, [data, contractInfo]);

  React.useEffect(() => {
    if (
      data &&
      data.some((item) => item.amount !== "" && item.category !== "main")
    ) {
      setIsEditable(false);
    }
  }, [data]);

  return (
    <>
      <table
        className="meal-plan-table"
        style={{ width: "100%", borderCollapse: "collapse" }}
      >
        <thead>
          <tr>
            <th style={{ border: "1px solid black", padding: "8px" }}>Дата</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Завтрак
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>Обед</th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Полдник
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Продленка
            </th>
            <th style={{ border: "1px solid black", padding: "8px" }}>
              Факультатив
            </th>
          </tr>
        </thead>
        <tbody>
          {tableData.map((row, index) => (
            <tr key={index}>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                <input
                  type="date"
                  value={row.date}
                  format="YYYY-MM-DD"
                  onChange={(e) => {
                    handleDataChange(index, "date", e.target.value);
                  }}
                  // readOnly={!isEditable}
                  style={{ width: "100%" }}
                />
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                <input
                  type="number"
                  value={row.breakfast}
                  onChange={(e) =>
                    handleDataChange(index, "breakfast", e.target.value)
                  }
                  // readOnly={!isEditable}
                  style={{ width: "100%" }}
                />
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                <input
                  type="number"
                  value={row.lunch}
                  onChange={(e) =>
                    handleDataChange(index, "lunch", e.target.value)
                  }
                  // readOnly={!isEditable}
                  style={{ width: "100%" }}
                />
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                <input
                  type="number"
                  value={row.snack}
                  onChange={(e) =>
                    handleDataChange(index, "snack", e.target.value)
                  }
                  // readOnly={!isEditable}
                  style={{ width: "100%" }}
                />
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                <input
                  type="number"
                  value={row.extension}
                  onChange={(e) =>
                    handleDataChange(index, "extension", e.target.value)
                  }
                  // readOnly={!isEditable}
                  style={{ width: "100%" }}
                />
              </td>
              <td style={{ border: "1px solid black", padding: "8px" }}>
                <input
                  type="number"
                  value={row.electives}
                  onChange={(e) =>
                    handleDataChange(index, "electives", e.target.value)
                  }
                  // readOnly={!isEditable}
                  style={{ width: "100%" }}
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="meal-plan-actions">
        <Button
          onClick={handleCloseDialog}
          variant="contained"
          // disabled={!isEditable}
        >
          Закрыть
        </Button>
        <Button
          onClick={handleSubmitFoodDebt}
          variant="contained"
          // disabled={!isEditable}
        >
          Отправить
        </Button>
      </div>
      <SimpleConfirmModal
        isOpen={isConfirmDialogOpen}
        onConfirm={handleConfirmAction}
        content="Данные успешно отправлены"
      />
    </>
  );
};

export default FoodDebt;
