/* eslint-disable consistent-return */
/* eslint-disable array-callback-return */
import { MenuItem, Select, Typography } from "@mui/material";
import React, { useState } from "react";

import { connect } from "react-redux";
import DataGrid from "../../components/DataGrid/DataGrid";
import {
  clearWorkingOut,
  fetchWorkingOut,
  removeWorkingOut,
  updateWorkingOut,
} from "../../redux/actions/workingOutActions";
import { setPageTitle } from "../../redux/actions/globalActions";

const mapStateToProps = (state) => ({
  globalState: state.globalState,
  data: state.temporary_arrangement.payload,
  loading: state.temporary_arrangement.loading,
  entitiesTypes: state.entitiesTypes,
  permissions: {
    add: false,
    modify: true,
    remove: true,
    view: false,
  },
});

const mapDispatchToProps = (dispatch) => ({
  getData: () => dispatch(fetchWorkingOut()),
  updateData: (obj) => dispatch(updateWorkingOut(obj)),
  removeData: (id) =>
    dispatch(removeWorkingOut(id)).catch(() => dispatch(clearWorkingOut())),
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

const WorkingOutPage = ({
  title,
  permissions,
  data,
  loading,
  setTitle,
  ...props
}) => {
  console.log("data WorkingOutPage", data);
  const nameOfGroup = props.entitiesTypes.group;
  const [value, setValue] = useState("");

  const handleSelectChange = (event) => {
    setValue(event.target.value);
  };

  React.useEffect(() => {
    setTitle(title);
  }, []);

  const columns = [
    {
      field: "name",
      headerName: "ФИО",
      flex: 1,
      editable: false,
    },
    {
      field: "number",
      headerName: "Договор",
      type: "number",
      flex: 1,
      editable: false,
      editableWithEmptyValue: false,
    },
    {
      field: "current_group",
      headerName: "Из какой группы",
      flex: 1,
      editable: false,
      valueGetter: (params) => {
        const currentGroup = nameOfGroup.find(
          (item) => item.value === params.value
        );
        return currentGroup?.label;
      },
    },
    {
      field: "group_for_otrabotka",
      headerName: "В какую группу",
      editable: true,
      valueGetter: (params) => {
        const currentGroup = nameOfGroup.find(
          (item) => item.value === params.value
        );
        return currentGroup?.label;
      },
      renderEditCell: (params) => (
        <Select
          fullWidth
          labelId="demo-simple-select-label"
          id="demo-simple-select"
          onChange={handleSelectChange}
          value={value}
        >
          {params.row.list_group.map((item) => (
            <MenuItem
              key={item.group_for_temporary_arrangement}
              value={item.group_for_temporary_arrangement}
            >
              {item.name_group}
            </MenuItem>
          ))}
        </Select>
      ),
      valueSetter: (params) => {
        if (value) {
          return {
            ...params.row,
            // group_for_otrabotka: params.value.toString(),
            // group_for_otrabotka: idGroup[0].value,
            group_for_otrabotka: value,
          };
        }
        if (!value) {
          return {
            group_for_otrabotka: params.value.toString(),
          };
        }
      },
      flex: 1,
    },
    {
      field: "date",
      headerName: "Дата",
      type: "singleSelect",
      valueOptions: (params) => {
        const newParams = JSON.parse(JSON.stringify(params));
        const search = newParams.row.list_group.filter(
          (item) =>
            // item.group_for_temporary_arrangement ===
            // newParams.row.group_for_otrabotka
            item.group_for_temporary_arrangement === value
        );

        if (search[0]?.date === undefined) {
          return [];
        }
        const forSelect = [];
        search[0].date.map((item) => {
          forSelect.push({ label: item, value: item });
        });
        return forSelect;
      },
      valueGetter: (params) => params.value,
      flex: 1,
      editable: true,
    },
    {
      field: "status",
      headerName: "Статус",
      flex: 1,
      editable: false,
      valueFormatter: (params) => {
        if (params.value === "new") {
          return "Назначено";
        }
        if (params.value === "done") {
          return "Отработано";
        }
        if (params.value === "gap") {
          return "Пропущено";
        }
        return params.value;
      },
    },
  ];

  return (
    <div className="content-component__wrapper">
      <Typography variant="h4" component="h4" className="page__title">
        {title}
      </Typography>
      <DataGrid
        sx={{ padding: "0 30px 20px 30px" }}
        columns={columns}
        entityName="temporary_arrangement"
        dataGridName="working_out"
        getRowId={(row) => `${row.id_temporary_arrangement}`}
        permissions={permissions}
        {...props}
        data={data}
        loading={loading}
      />
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(WorkingOutPage);
