/* eslint-disable no-restricted-globals */
import { Box, Button, FormControl, MenuItem, TextField } from "@mui/material";
import React from "react";
import dayjs from "dayjs";
import { useDispatch } from "react-redux";
import DataGrid from "../DataGrid/DataGrid";
import {
  getPermissions,
  getUserLocation,
} from "../../utils/helpers/getDefaultUser";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import { postCost } from "../../redux/actions/costsActions";

dayjs.locale("ru");

const CostsView = ({ entitiesTypes, permissions, ...props }) => {
  const initialCostsState = {
    date_transaction: "",
    category: "",
    operation: "",
    type: "",
    location_id: "",
    amount: "",
  };

  const userPermissions = getPermissions();
  const userLocations = getUserLocation();
  const dispatch = useDispatch();

  const [costsState, setCostsState] = React.useState(initialCostsState);
  const [totalAmount, setTotalAmount] = React.useState(0);
  const [filteredOperations, setFilteredOperations] = React.useState([]);
  const [avaliableLocations, setAvaliableLocations] = React.useState([]);
  const [data, setData] = React.useState([]);
  const {
    category: categories,
    operation: operations,
    select_rec: requisites,
    location: locations,
  } = { ...entitiesTypes };
  const { config, transactions } = props.data;

  const checkAvailableLocations = () => {
    const values = locations.map((loc) => loc.value);
    const filteredLocations = values.filter((value) =>
      userLocations.includes(value)
    );
    setAvaliableLocations(filteredLocations);
  };

  const dateSetterFunc = (value) => {
    if (value) {
      return dayjs(value).format("YYYY-MM-DD HH:mm:ss");
    }
    const today = new Date();
    return dayjs(today).format("YYYY-MM-DD HH:mm:ss");
  };

  const handleInputsChange = (event) => {
    const { name, value } = event.target;
    setCostsState({
      ...costsState,
      [name]: value,
    });
  };

  const handleCategoryChange = (e) => {
    const selectedCategoryValue = e.target.value;
    setCostsState({ ...costsState, category: e.target.value });
    // Find the operations based on the selected category
    const categoryConfig = config.find(
      (cat) => cat.category === parseInt(selectedCategoryValue, 10)
    );

    if (categoryConfig) {
      const filteredOps = operations.filter((op) =>
        categoryConfig.operations.includes(parseInt(op.value, 10))
      );
      setFilteredOperations(filteredOps);
      setCostsState({
        ...costsState,
        category: selectedCategoryValue,
        operation: "",
      });
    } else {
      setFilteredOperations([]);
      setCostsState({
        ...costsState,
        category: selectedCategoryValue,
        operation: "",
      });
    }
  };

  const handleSendCosts = () => {
    if (
      !costsState.date_transaction ||
      !costsState.category ||
      !costsState.operation ||
      !costsState.amount ||
      !costsState.location_id
    ) {
      alert("заполните все поля");
      return;
    }
    if (isNaN(costsState.amount)) {
      alert("Сумма должна быть числом");
      return;
    }
    dispatch(postCost(costsState));
  };


  const getFilteredOperations = (category) => {
    // eslint-disable-next-line eqeqeq
    const categoryConfig = config.find((c) => c.category == category);
    return categoryConfig
      ? operations.filter((op) => categoryConfig.operations.includes(Number(op.value)))
      : [];

  };

  const handleCellPropsChange = (params) => {
    const { id, field } = params;
    const value = params.props.value || params.value;

    setData((prevRows) =>
      prevRows.map((row) => {
        // eslint-disable-next-line eqeqeq
        if (row.id == id) {
          const updatedRow = { ...row, [field]: value };
          if (field === "category") {
            const targetOperations = getFilteredOperations(value);
            updatedRow.operation = targetOperations.length > 0 ? targetOperations[0].value.toString() : '';
          }
          return updatedRow;
        }
        return row;
      })
    );
  };


  const calculateTotal = (trans) => {
    const total = trans.reduce(
      (acc, transaction) => acc + parseFloat(transaction.amount || 0),
      0
    );
    setTotalAmount(total);
  };

  React.useEffect(() => {
    setData(transactions);
  }, []);
  React.useEffect(() => {
    checkAvailableLocations();
  }, []);
  React.useEffect(() => {
    calculateTotal(transactions);
  }, [transactions]);

  const columns = [
    {
      field: "id",
      hide: true,
      flex: 1,
    },
    {
      field: "date_transaction",
      headerName: "Дата",
      type: "date",
      flex: 1,
      editable: true,
      valueSetter: (params) => ({
        ...params.row,
        date_transaction: dateSetterFunc(params.value),
      }),
    },
    {
      field: "category",
      headerName: "Категория",
      valueFormatter: (params) => findLabelByID(params.value, categories),
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: categories,
    },
    {
      field: "operation",
      headerName: "Операция",
      valueFormatter: (params) => findLabelByID(params.value, operations),
      editable: true,
      type: "singleSelect",
      valueOptions: (params) => {
        const options = getFilteredOperations(params.row.category);
        return options.length > 0 ? options : [];
      },
      flex: 1,
    },
    {
      field: "amount",
      headerName: "Сумма",
      editable: true,
      flex: 1,

    },
    {
      field: "type",
      headerName: "Вид(реквизиты)",
      valueFormatter: (params) => findLabelByID(params.value, requisites),
      flex: 1,
      editable: true,
      type: "singleSelect",
      valueOptions: requisites,
    },
  ];

  if (
    !userPermissions.includes("transactions.view") ||
    !userPermissions.includes("transactions.add")
  ) {
    return <div>У вас нет прав для просмотра страницы</div>;
  }



  return (
    <div>
      <div className="sales_view" style={{ paddingTop: "20px" }}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "start-end",
            flexWrap: "wrap",
            margin: "10px 20px 10px 20px",
          }}
          component="div"
          noValidate
        >
          <TextField
            id="outlined-data"
            label="Дата транзакции"
            name="date_transaction"
            size="small"
            type="date"
            onChange={handleInputsChange}
            value={costsState.date_transaction}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              margin: "5px",
            }}
          />
          <FormControl>
            <TextField
              id="outlined-select-user"
              select
              name="category"
              label="Категория"
              size="small"
              value={costsState.category}
              onChange={handleCategoryChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "fit-content",
                minWidth: "250px",
                margin: "5px",
              }}
            >
              {categories.map((cat) => (
                <MenuItem key={cat.value} value={cat.value}>
                  {cat.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <FormControl>
            <TextField
              name="operation"
              select
              size="small"
              id="outlined-group_id"
              selectedCategory
              value={costsState.operation}
              disabled={!filteredOperations.length}
              label="Операция"
              // eslint-disable-next-line jsx-a11y/aria-proptypes
              aria-sort="label"
              onChange={handleInputsChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "fit-content",
                minWidth: "150px",
                margin: "5px",
              }}
            >
              {filteredOperations.map((op) => (
                <MenuItem key={op.value} value={op.value}>
                  {op.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <TextField
            id="outlined-techer"
            label="Сумма"
            name="amount"
            size="small"
            type="text"
            onChange={handleInputsChange}
            value={costsState.amount}
            InputLabelProps={{
              shrink: true,
            }}
            sx={{
              width: "150px",
              margin: "5px",
            }}
          />

          <FormControl>
            <TextField
              id="outlined-select-type"
              select
              name="type"
              label="Вид(реквизиты)"
              size="small"
              value={costsState.subject}
              onChange={handleInputsChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "fit-content",
                minWidth: "150px",
                margin: "5px",
              }}
            >
              {requisites.map((op) => (
                <MenuItem key={op.value} value={op.value}>
                  {op.label}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <FormControl>
            <TextField
              id="outlined-select-location"
              select
              name="location_id"
              label="Локация"
              size="small"
              value={costsState.location_id}
              onChange={handleInputsChange}
              InputLabelProps={{
                shrink: true,
              }}
              sx={{
                width: "fit-content",
                minWidth: "150px",
                margin: "5px",
              }}
            >
              {avaliableLocations?.map((value) => (
                <MenuItem key={value} value={value}>
                  {findLabelByID(value, locations)}
                </MenuItem>
              ))}
            </TextField>
          </FormControl>

          <Button
            variant="contained"
            onClick={handleSendCosts}
            sx={{
              margin: "5px",
              height: "40px",
            }}
          >
            Отправить
          </Button>
        </Box>
      </div>
      <div style={{ paddingLeft: "20px" }}> общая сумма : {totalAmount}</div>
      <DataGrid
        onEditCellPropsChange={(event, row) => handleCellPropsChange(event, row)}
        columns={columns}
        entityName="cost"
        dataGridName="costs_table"
        getRowId={(row) => `${row.id}`}
        data={data}
        globalState={{
          loading: false,
        }}
        permissions={permissions}
        updateData={
          props.updateData
        }
        removeData={
          props.removeData
        }
      />
    </div>
  );
};

export default CostsView;