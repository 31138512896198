import React from "react";
import { Backdrop, CircularProgress } from "@mui/material";
import { connect } from "react-redux";
import TasksKanban from "../../components/SalesView/TasksKanban";

import "../../scss/index.scss";
import { tasksApi } from "../../utils/api";
import { fetchTasks, updateTask } from "../../redux/actions/tasksActions";
import { setPageTitle } from "../../redux/actions/globalActions";

const mapStateToProps = (state) => ({
  globalState: state.globalState,
  entitiesTypes: state.entitiesTypes,
  tasks: state.tasks.payload,
});

const mapDispatchToProps = (dispatch) => ({
  getTasks: async (data) => {
    await dispatch(fetchTasks(data));
  },
  // getConfirmData: async (data) => {
  //   await dispatch(getConfirmData(data));
  // },
  updTask: (data) => {
    dispatch(updateTask(data));
  },
  setTitle: (title) => {
    dispatch(setPageTitle(title));
  },
});

function TasksKanbanPage({ getTasks, tasks, setTitle, ...props }) {
  const { globalState, title } = props;
  const backdropStyles = {
    bgcolor: "#fff",
    zIndex: 2,
    position: "relative",
    height: "800px",
    borderRadius: "10px",
  };

  const [users, setUsers] = React.useState([]);

  React.useEffect(() => {
    getTasks();
  }, []);

  React.useEffect(() => {
    setTitle(title);
  }, []);

  React.useEffect(() => {
    tasksApi.roles().then((data) => setUsers(data.data));
  }, []);

  return (
    <>
      <h4 className="table__title">{title}</h4>
      {globalState.loading || !tasks.length ? (
        <Backdrop open={!globalState.loading} sx={backdropStyles}>
          <CircularProgress />
        </Backdrop>
      ) : (
        <TasksKanban tasksData={tasks} users={users} {...props} />
      )}
    </>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(TasksKanbanPage);
