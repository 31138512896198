/* eslint-disable consistent-return */
import React, { useState, useEffect } from "react";
import "./TopicsAndDatesView.css";
import "../../scss/index.scss";
import { v4 as uuidv4 } from "uuid";
import {
  Box,
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
  dateFormatterFunc,
  dateGetterFunc,
  dateSetterFunc,
} from "../../utils/helpers/dataGridFormatters";
import DataGrid from "../DataGrid/DataGrid";
import {
  clearSchedules,
  sendTopic,
  showTeacherPage,
} from "../../redux/actions/topicsAndDatesActions";
import { findLabelByID } from "../../utils/helpers/entitiesHelpers";
import activeUsersSelection from "../../utils/helpers/activeUserSelection";

function TopicsAndDatesView({ ...props }) {
  const {
    days,
    // loading,
    schedules,
    getSchedules,
    // isMethodist,
    permissions,
    entitiesTypes,
  } = props;

  console.log("responseSortedByDates", schedules);
  // console.log("days", days);

  const styles = {
    subtitle: {
      paddingBottom: "15px",
    },
    span: {
      fontWeight: 400,
    },
  };

  const dispatch = useDispatch();

  const initialTopicValues = {
    user: "",
    date: "",
    group_id: "",
    short_name: "", // topic
    subject: "",
  };

  const [methodist, setMethodist] = useState(false);

  const checkMethodist = () => {
    if (!permissions.modify) {
      setMethodist(false);
    }
    if (permissions.modify) {
      setMethodist(true);
    }
  };

  useEffect(() => {
    checkMethodist();
  }, []);

  const viewTeacherPage = useSelector(
    (state) => state.topicsAndDates.teacherPage
  );
  const activeTeachers = entitiesTypes.active_users;

  const [errorMessage, setErrorMessage] = useState("");
  const [newTopicState, setTopicState] = React.useState(initialTopicValues);

  const handleTopicInput = (e) => {
    setTopicState({
      ...newTopicState,
      [e.target.name]: e.target.value,
    });
    if (e.target.value) {
      setErrorMessage("");
    }
  };

  const handleViewTeacher = () => {
    // setTeacherPage(true);
    setTopicState(initialTopicValues);
    setErrorMessage("");
    dispatch(showTeacherPage(true));
  };

  const handleviewTopic = () => {
    // setTeacherPage(false);
    setTopicState(initialTopicValues);
    setErrorMessage("");
    dispatch(showTeacherPage(false));
    dispatch(clearSchedules());
  };

  const detailsInitState = {
    teacher: "",
    topic: "",
    group: "",
    date: "",
  };

  const [details, setRowDetails] = useState(detailsInitState);

  // get global data
  const locations = useSelector((state) => state.entitiesTypes.location);

  const teachers = entitiesTypes?.user?.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );
  const groupsGlobal = entitiesTypes?.group?.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );

  const subjects = entitiesTypes?.subject?.sort((a, b) =>
    a.label > b.label ? 1 : -1
  );

  const getTeacherFromEntities = (teacherId) => {
    if (!teacherId) {
      return undefined;
    }
    if (teachers) {
      const teacherName = teachers.find((item) => item.value === teacherId);
      return teacherName?.label || "UNKNOWN/DELETED";
    }
  };

  const getGroupFromEntities = (groupId) => {
    if (!groupId) {
      return;
    }
    if (groupsGlobal) {
      const groupName = groupsGlobal.find((item) => item.value === groupId);
      return groupName ? groupName.label : groupId;
    }
  };

  const getSubjectFromEntities = (value) => {
    const subjectName = subjects?.find((item) => item.value === value);
    return subjectName ? subjectName.label : value;
  };

  const handleRowDetails = (row) => {
    setRowDetails({
      topic: row.row.short_name,
      teacher: getTeacherFromEntities(row.row.user),
      group: getGroupFromEntities(row.row.group_id),
      date: row.row.date,
      subject: getSubjectFromEntities(row.row.subject),
    });
  };

  // single selects states.teacher page
  const [teacher, setTeacher] = React.useState("");
  const [location, setlocation] = React.useState("");
  const [week, setWeek] = React.useState("");

  const handleTeacherChange = (event) => {
    setTeacher(event.target.value);
  };
  const handleLocationChange = (event) => {
    setlocation(event.target.value);
  };
  const handleWeekChange = (event) => {
    setWeek(event.target.value);
  };

  const handleSubmit = () => {
    const submitData = {
      week,
      location,
      teacher,
    };
    console.log("submitData", submitData);
    getSchedules(submitData);
  };

  const handleTopicSubmit = () => {
    if (!newTopicState.group_id || !newTopicState.short_name) {
      setErrorMessage("необходимо выбрать группу и заполнить поле 'тема': ");
      return;
    }

    dispatch(sendTopic(newTopicState));
    dispatch(showTeacherPage(false));
  };

  const daySwitcher = (day) => {
    switch (day) {
      case 1:
        return "Понедельник";
      case 2:
        return "Вторник";
      case 3:
        return "Среда";
      case 4:
        return "Четверг";
      case 5:
        return "Пятница";
      case 6:
        return "Суббота";
      case 0:
        return "Воскресенье";
      default:
        return "нет такого дня";
    }
  };

  const getDay = (date) => {
    const currentDate = new Date(date);
    return daySwitcher(currentDate.getDay());
  };

  // /* sort by date. sort {"date1": {}, "date5": {}..."date3": {} } by date */
  const sortedDays = {};
  Object.keys(days)
    .sort()
    .forEach((key) => {
      sortedDays[key] = days[key];
    });

  const columns = [
    {
      field: "id",
      hide: true,
    },
    {
      field: "date",
      headerName: "Дата",
      type: "date",
      flex: 0.1,
      editable: true,
      valueGetter: dateGetterFunc,
      valueSetter: (params) => dateSetterFunc(params, "date"),
      valueFormatter: dateFormatterFunc,
    },
    {
      field: "group_id",
      headerName: "Группа",
      flex: 0.1,
      // editable: true,
      valueGetter: (params) => getGroupFromEntities(params.value),
    },
    {
      field: "user",
      headerName: "Преподаватель",
      flex: 0.2,
      editable: true,
      type: "singleSelect",
      valueOptions: entitiesTypes.user,
      valueFormatter: (params) => findLabelByID(params.value, teachers),
      renderEditCell: (params) => (
        <Select
          value={params.value}
          onChange={(e) =>
            params.api.setEditCellValue({ ...params, value: e.target.value })
          }
        >
          {activeUsersSelection(teachers, activeTeachers)}
        </Select>
      ),
    },
    {
      field: "short_name",
      headerName: "Тема занятия",
      editable: true,
      flex: 0.6,
    },
    {
      field: "subject",
      headerName: "Предмет",
      flex: 0.2,
      // editable: true,
      valueGetter: (params) => getSubjectFromEntities(params.value),
    },
  ];
  return methodist ? (
    <>
      <div className="methodist__actions">
        <Button
          sx={{ marginRight: "20px" }}
          disabled={!permissions.modify}
          size="small"
          variant="contained"
          onClick={handleviewTopic}
        >
          {" "}
          Добавить / Изменить тему{" "}
        </Button>
        <Button
          disabled={!permissions.modify || viewTeacherPage}
          size="small"
          variant="contained"
          onClick={handleViewTeacher}
        >
          {" "}
          Назад{" "}
        </Button>
      </div>

      {viewTeacherPage ? (
        <>
          <div>
            <Box component="div" className="selection__wrapper">
              <div className="input_box">
                <InputLabel id="demo-simple-select-label">
                  Преподаватели
                </InputLabel>
                <Select
                  name="teacher"
                  size="small"
                  fullWidth
                  labelId="teacher"
                  id="teacher"
                  value={teacher}
                  label="location"
                  onChange={handleTeacherChange}
                >
                  {activeUsersSelection(teachers, activeTeachers)}
                  <MenuItem value="all">все преподаватели</MenuItem>
                </Select>
              </div>

              <div className="input_box">
                <InputLabel id="demo-simple-select-label">Филиал</InputLabel>
                <Select
                  name="location"
                  size="small"
                  fullWidth
                  labelId="location"
                  id="location"
                  value={location}
                  label="location"
                  onChange={handleLocationChange}
                >
                  {locations?.map((item) => (
                    <MenuItem key={uuidv4()} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={locations}>все филиалы</MenuItem> */}
                </Select>
              </div>

              <div className="input_box">
                <InputLabel id="demo-simple-select-label">Неделя</InputLabel>
                <Select
                  name="week"
                  size="small"
                  fullWidth
                  labelId="week"
                  id="week"
                  value={week}
                  label="location"
                  onChange={handleWeekChange}
                >
                  <MenuItem value="prev">Прошлая неделя</MenuItem>
                  <MenuItem value="current">Текущая неделя</MenuItem>
                  <MenuItem value="next">Следующая неделя</MenuItem>
                </Select>
              </div>

              <div className="submit_btn_view">
                <Button
                  onClick={handleSubmit}
                  sx={{ height: "65%" }}
                  size="small"
                  variant="contained"
                >
                  отправить
                </Button>
              </div>
            </Box>
          </div>

          <div className="table__wrapper">
            {/* {badRequest ? (
							<div
								className="error"
								style={{
									display: "flex",
									justifyContent: "center",
									alignItems: "center",
									marginTop: "100px",
								}}
							>
								{" "}
								Вы отправили неверные данные. Попробуйте еще раз.{" "}
							</div>
						) : null} */}
            {/* {schedules.length ? null : (
							<div className="empty__fields">нет данных для отображения</div>
						)} */}
            <table rules="cols" className="schedule__results">
              {Object.keys(schedules).map((key) => {
                const item = schedules[key];
                return (
                  <tbody key={uuidv4()}>
                    <tr>
                      <th
                        colSpan={4}
                        style={{
                          background: "#e9f5fb",
                          color: "#000",
                          borderCollapse: "separate",
                          borderSpacing: "5px",
                          borderTop: "solid 30px #fff",
                        }}
                      >
                        {" "}
                        {getDay(key)} {key.slice(0, -9)}
                      </th>
                    </tr>

                    <tr style={{ fontStyle: "italic" }}>
                      <td>Преподаватель</td>
                      <td>Название группы</td>
                      <td>Предмет</td>
                      <td>Тема</td>
                    </tr>

                    {item?.map((group) => (
                      <tr key={uuidv4()}>
                        <td>{getTeacherFromEntities(group.user)}</td>
                        <td>{getGroupFromEntities(group.group_id)}</td>
                        <td>{getSubjectFromEntities(group.subject)}</td>
                        <td>{group.short_name}</td>
                      </tr>
                    ))}
                    <tr colSpan={4}>
                      <td style={{ borderRight: "none" }}> {null} </td>
                    </tr>
                  </tbody>
                );
              })}
            </table>
          </div>
        </>
      ) : (
        <>
          <Box sx={{ margin: "0 30px 0 30px" }}>
            <Stack>
              <Typography variant="h6" component="span" sx={styles.subtitle}>
                Преподаватель:{" "}
                <span style={styles.span}>{details?.teacher}</span>
              </Typography>
              <Typography variant="h6" component="span" sx={styles.subtitle}>
                Группа: <span style={styles.span}>{details?.group}</span>
              </Typography>
              <Typography variant="h6" component="span" sx={styles.subtitle}>
                Тема: <span style={styles.span}>{details?.topic}</span>
              </Typography>
              <Typography variant="h6" component="span" sx={styles.subtitle}>
                Дата: <span style={styles.span}>{details?.date}</span>
              </Typography>
              <Typography
                variant="h6"
                component="span"
                color="error"
                sx={{ paddingBottom: 0 }}
              >
                {errorMessage}
              </Typography>
            </Stack>
          </Box>
          <div className="topic__update">
            <Box
              sx={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "start-end",
                flexWrap: "wrap",
                margin: "10px 25px 10px 25px",
              }}
              component="div"
              // onSubmit={handleSubmit}
              noValidate
            >
              <TextField
                id="outlined-data"
                label="Дата"
                name="date"
                size="small"
                type="date"
                onChange={handleTopicInput}
                value={newTopicState.date}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  margin: "5px",
                }}
              />
              <FormControl>
                <TextField
                  id="outlined-select-user"
                  select
                  name="user"
                  label="Преподаватели"
                  size="small"
                  value={newTopicState.user}
                  onChange={handleTopicInput}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: "fit-content",
                    minWidth: "150px",
                    margin: "5px",
                  }}
                >
                  {activeUsersSelection(teachers, activeTeachers)}
                </TextField>
              </FormControl>

              <FormControl>
                <TextField
                  name="group_id"
                  select
                  size="small"
                  id="outlined-group_id"
                  value={newTopicState.group_id}
                  label="Группа"
                  // eslint-disable-next-line jsx-a11y/aria-proptypes
                  aria-sort="label"
                  onChange={handleTopicInput}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: "fit-content",
                    minWidth: "150px",
                    margin: "5px",
                  }}
                >
                  {groupsGlobal?.map((item) => (
                    <MenuItem key={uuidv4()} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                  {/* <MenuItem value={locations}>все филиалы</MenuItem> */}
                </TextField>
              </FormControl>

              <TextField
                id="outlined-techer"
                label="Тема"
                name="short_name"
                size="small"
                type="text"
                onChange={handleTopicInput}
                value={newTopicState.short_name}
                InputLabelProps={{
                  shrink: true,
                }}
                sx={{
                  width: "150px",
                  margin: "5px",
                }}
              />

              <FormControl>
                <TextField
                  id="outlined-select-user"
                  select
                  name="subject"
                  label="Предметы"
                  size="small"
                  value={newTopicState.subject}
                  onChange={handleTopicInput}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  sx={{
                    width: "fit-content",
                    minWidth: "150px",
                    margin: "5px",
                  }}
                >
                  {subjects?.map((item) => (
                    <MenuItem key={item.value} value={item.value}>
                      {item.label}
                    </MenuItem>
                  ))}
                </TextField>
              </FormControl>

              <Button
                variant="contained"
                onClick={handleTopicSubmit}
                sx={{
                  margin: "5px",
                  height: "40px",
                }}
              >
                Отправить
              </Button>
            </Box>
          </div>

          <DataGrid
            onCellClick={(event, row) => handleRowDetails(event, row)}
            columns={columns}
            entityName="topic"
            dataGridName="topics_dates_of_classes"
            getRowId={(row) => `${row.id}`}
            permissions={permissions}
            {...props}
          />
        </>
      )}
    </>
  ) : (
    <>
      <div>
        <Box component="div" className="selection__wrapper">
          <div className="input_box">
            <InputLabel id="demo-simple-select-label">Преподаватели</InputLabel>
            <Select
              name="teacher"
              size="small"
              fullWidth
              labelId="teacher"
              id="teacher"
              value={teacher}
              label="location"
              onChange={handleTeacherChange}
            >
              {teachers?.map((item) => (
                <MenuItem key={uuidv4()} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
              <MenuItem value="all">все преподаватели</MenuItem>
            </Select>
          </div>

          <div className="input_box">
            <InputLabel id="demo-simple-select-label">Филиал</InputLabel>
            <Select
              name="location"
              size="small"
              fullWidth
              labelId="location"
              id="location"
              value={location}
              label="location"
              onChange={handleLocationChange}
            >
              {locations?.map((item) => (
                <MenuItem key={uuidv4()} value={item.value}>
                  {item.label}
                </MenuItem>
              ))}
              {/* <MenuItem value={locations}>все филиалы</MenuItem> */}
            </Select>
          </div>

          <div className="input_box">
            <InputLabel id="demo-simple-select-label">Неделя</InputLabel>
            <Select
              name="week"
              size="small"
              fullWidth
              labelId="week"
              id="week"
              value={week}
              label="location"
              onChange={handleWeekChange}
            >
              <MenuItem value="prev">Прошлая неделя</MenuItem>
              <MenuItem value="current">Текущая неделя</MenuItem>
              <MenuItem value="next">Следующая неделя</MenuItem>
            </Select>
          </div>

          <div className="submit_btn_view">
            <Button
              onClick={handleSubmit}
              sx={{ height: "65%" }}
              size="small"
              variant="contained"
            >
              отправить
            </Button>
          </div>
        </Box>
      </div>

      <div className="table__wrapper">
        {/* {badRequest ? (
					<div
						className="error"
						style={{
							display: "flex",
							justifyContent: "center",
							alignItems: "center",
							marginTop: "100px",
						}}
					>
						{" "}
						Вы отправили неверные данные. Попробуйте еще раз.{" "}
					</div>
				) : null} */}
        {/* {schedules.length ? null : (
					<div className="empty__fields">нет данных для отображения</div>
				)} */}
        <table rules="cols" className="schedule__results">
          {Object.keys(schedules).map((key) => {
            const item = schedules[key];
            return (
              <tbody key={uuidv4()}>
                <tr>
                  <th
                    colSpan={4}
                    style={{
                      background: "#e9f5fb",
                      color: "#000",
                      borderCollapse: "separate",
                      borderSpacing: "5px",
                      borderTop: "solid 30px #fff",
                    }}
                  >
                    {" "}
                    {getDay(key)} {key}
                  </th>
                </tr>

                <tr style={{ fontStyle: "italic" }}>
                  <td>Преподаватель</td>
                  <td>Название группы</td>
                  <td>Предмет</td>
                  <td>Тема</td>
                </tr>

                {item.map((group) => (
                  <tr key={uuidv4()}>
                    <td>{getTeacherFromEntities(group.user)}</td>
                    <td>{getGroupFromEntities(group.group_id)}</td>
                    <td>{getSubjectFromEntities(group.subject)}</td>
                    <td>{group.short_name}</td>
                  </tr>
                ))}
                <tr colSpan={4}>
                  <td style={{ borderRight: "none" }}> {null} </td>
                </tr>
              </tbody>
            );
          })}
        </table>
      </div>
    </>
  );
}

export default TopicsAndDatesView;
