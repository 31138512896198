import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import React from "react";

const ModalActivate = ({ open, handleClose, activateConfirmed }) => {
  const onCloseHandler = () => {
    handleClose();
  };

  const confirmHandler = () => {
    activateConfirmed();
    handleClose();
  };

  return (
    <div>
      <Dialog open={open} onClose={onCloseHandler}>
        <DialogTitle>Активировать пользователя</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Вы действительно хотите активировать пользователя?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onCloseHandler}>Назад</Button>
          <Button onClick={confirmHandler} color="error">
            активировать
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default ModalActivate;
