import * as React from "react";
import "./TimePicker.css";
import dayjs from "dayjs";
import "dayjs/locale/ru";
import { TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TextField } from "@mui/material";

const TimePickerCell = ({ group, params }) => {
  const [value, setValue] = React.useState(
    params.value ? dayjs(`2000-01-01T${params.value}:00`) : null
  );

  const handleChange = (newValue) => {
    // const newTime = newValue ? newValue.format("HH:mm:ss") : "";
    setValue(newValue);
    console.log("newValue", dayjs(newValue).format("HH:mm:ss"));
    console.log("params", params);
    group.students.map((item) => {
      if (item.id === params.id && params.field === "from") {
        item.from = dayjs(newValue).format("HH:mm:ss");
      }
      if (item.id === params.id && params.field === "to") {
        item.to = dayjs(newValue).format("HH:mm:ss");
      }
      return group.students;
    });
  };

  return (
    <div className="pupils__table_time_picker" style={{ fontSize: "10px" }}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={dayjs.locale("ru")}
      >
        <TimePicker
          value={value}
          onChange={handleChange}
          renderInput={(par) => <TextField {...par} />}
          ampm={false}
        />
      </LocalizationProvider>
    </div>
  );
};

export default TimePickerCell;
