/* eslint-disable consistent-return */
import React from "react";
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { Typography } from "@mui/material";
import DataGrid from "../../components/DataGrid/DataGrid";
import { fetchDebt } from "../../redux/actions/debtActions";

const mapStateToProps = (state) => ({
	globalState: state.globalState,
	data: state.debt.payload,
	loading: state.debt.loading,
	permissions: {
		modify: false,
	},
});

const mapDispatchToProps = (dispatch) => ({
	getData: () => dispatch(fetchDebt()),
});

function ReportAcademicYear({ title, ...props }) {
	const locations = useSelector(({ entitiesTypes }) => entitiesTypes.location);

	const changeLocations = (params) => {
		locations.map((item) => {
			if (item.value === params.value) {
				params.value = item.label;
			}
			return params.value;
		});
		return params.value;
	};

	const columns = [
		{
			field: "full_name",
			headerName: "ФИО",
			flex: 1.2,
			renderCell: (params) => (
				<Link to={`/app/contracts/${params.row.contract_id}`}>
					{params.value}
				</Link>
			),
		},
		{
			field: "number",
			headerName: "Договор",
			flex: 1,
			renderCell: (params) => (
				<a
					href={`${params.row.contract_url}`}
					style={{ textDecoration: "underline" }}
				>
					{params.value}
				</a>
			),
		},
		{
			field: "phone",
			headerName: "Телефон",
			flex: 1,
		},
		{
			field: "debt",
			headerName: "Дебет",
			flex: 1,
			valueFormatter: (params) => `${params.value}р.`,
		},
		{
			field: "credit",
			headerName: "Кредит",
			flex: 1,
			valueFormatter: (params) => `${params.value}р.`,
		},
		{
			field: "balance",
			headerName: "Сумма",
			flex: 1,
			renderCell: ({ value }) => {
				if (value < 0) {
					return (
						<span style={{ color: "red" }}>
							{value.toString().replace("-", "")}р.
						</span>
					);
				}
				return `${value}р.`;
			},
		},
		{
			field: "contract_url",
			hide: true,
		},
		{
			field: "location_id",
			headerName: "Филиал",
			flex: 1.2,
			valueGetter: (params) => changeLocations(params),
		},
	];
	return (
		<div className="content-component__wrapper">
			<Typography variant="h4" component="h4" className="page__title">
				{title}
			</Typography>
			<DataGrid
				columns={columns}
				getRowId={(row) => `${row.number}${row.contract_id}`}
				{...props}
			/>
		</div>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ReportAcademicYear);

// import React from "react";

// const ReportAcademicYear = ({ permissions, data, ...props }) => {
// 	console.log();
// 	const columns = [
// 		{
// 			field: "date",
// 			headerName: "Дата",
// 			flex: 1,
// 			editable: true,
// 			valueGetter: (params) => params.value,
// 		},
// 	];

// 	return (
// 		<div className='content-component__wrapper'>
// 			<DataGridPro
// 				sx={{ padding: "0 30px 20px 30px" }}
// 				columns={columns}
// 				entityName='reportAcademicYear'
// 				getRowId={(row) => `${row.id}`}
// 				permissions={permissions}
// 				{...props}
// 				data={["15.05.2022", "17.10.2022", "19.10.2022"]}
// 			/>
// 		</div>
// 	);
// };

// export default ReportAcademicYear;
