import "./ReusableSimpleYesNo.scss";
import { Button } from "@mui/material";
import React from "react";

const SimpleYesNo = ({ isOpen, onConfirm, content, onClose }) => (
  <>
    {isOpen && (
      <div className="simple__modal-overlay" style={{ zIndex: "10000" }}>
        <div className="simple__modal-content">
          <p>{content}</p>
          <div className="simple__modal-btns">
            <Button variant="contained" onClick={onConfirm}>
              Да
            </Button>
            <Button variant="contained" onClick={onClose}>
              Нет
            </Button>
          </div>
        </div>
      </div>
    )}
  </>
);

export default SimpleYesNo;
