import React from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import SaveIcon from "@mui/icons-material/Save";
import ClearIcon from "@mui/icons-material/Clear";
import FilterListIcon from "@mui/icons-material/FilterList";
import { GridToolbarContainer } from "../../dataGridLib/x-data-grid-pro";
import AlertDialog from "./AlertDialog";

function GridToolbar(props) {
  const {
    apiRef,
    gridState,
    setGridState,
    loading,
    handleCommitRow,
    handleRemoveRow,
    permissions,
  } = props;

  const [openDialog, setOpenDialog] = React.useState(false);

  const handleAddClick = () => {
    const id = String(Math.floor(10000 + Math.random() * -10000000));
    const { field } = apiRef.current.getAllColumns()[0];

    setGridState({
      ...gridState,
      editMode: true,
      selectedRow: id,
    });
    apiRef.current.updateRows([
      {
        id,
        isNew: true,
      },
    ]);
    apiRef.current.setRowMode(id, "edit");
    setTimeout(() => {
      apiRef.current.scrollToIndexes({
        rowIndex: apiRef.current.getRowsCount() - 1,
      });
    });
    // apiRef.current.selectRow(id);
    apiRef.current.setCellFocus(id, field);
  };

  const handleEditClick = () => {
    const id = gridState.selectedRow;
    const { field } = apiRef.current.getAllColumns()[0];
    apiRef.current.setRowMode(id, "edit");
    apiRef.current.selectRow(id);
    apiRef.current.setCellFocus(id, field);
    setGridState({
      ...gridState,
      editMode: true,
    });
  };

  const handleCancelClick = (event) => {
    event.stopPropagation();
    const editRowModel = apiRef.current.getEditRowsModel();
    const id = String(Object.keys(editRowModel)[0]);
    apiRef.current.setRowMode(id, "view");
    const row = apiRef.current.getRow(id);
    if (row.isNew) {
      apiRef.current.updateRows([
        {
          id,
          _action: "delete",
        },
      ]);
    }
    setGridState({
      ...gridState,
      selectedRow: "",
      editMode: false,
    });
  };

  // display alert dialog
  const handleDeleteClick = (event) => {
    event.stopPropagation();
    setOpenDialog(true);
  };
  // deletion after confirmation
  const handleConfirmedRemove = () => {
    setOpenDialog(false);
    const id = gridState.selectedRow;
    handleRemoveRow(id);
    apiRef.current.updateRows([
      {
        id,
        _action: "delete",
      },
    ]);
    setGridState({
      ...gridState,
      selectedRow: "",
      editMode: false,
    });
  };

  const handleSaveClick = async (event) => {
    event.stopPropagation();
    const id = gridState.selectedRow;
    const isValid = await apiRef.current.commitRowChange(id);
    if (isValid) {
      console.log(apiRef.current);
      handleCommitRow(id);
      apiRef.current?.setRowMode(id, "view");
      apiRef.current?.updateRows([
        {
          id,
          isNew: false,
        },
      ]);
      setGridState({
        ...gridState,
        selectedRow: "",
        editMode: false,
      });
    }
  };

  return (
    <>
      <GridToolbarContainer
        sx={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div>
          {permissions.add && (
            <Button
              startIcon={<AddIcon />}
              disabled={gridState.editMode || loading}
              onClick={handleAddClick}
              color="primary"
              sx={{ fontWeight: 700 }}
            >
              Добавить
            </Button>
          )}
          {gridState.selectedRow && !gridState.editMode && (
            <>
              {permissions.modify && (
                <Button
                  startIcon={<EditIcon />}
                  disabled={loading}
                  onClick={handleEditClick}
                  color="primary"
                  sx={{ fontWeight: 700 }}
                >
                  Редактировать
                </Button>
              )}
              {permissions.remove && (
                <Button
                  startIcon={<DeleteIcon />}
                  disabled={loading}
                  onClick={handleDeleteClick}
                  color="primary"
                  sx={{ fontWeight: 700 }}
                >
                  Удалить
                </Button>
              )}
            </>
          )}
          <Button
            startIcon={<FilterListIcon />}
            onClick={apiRef.current.showFilterPanel}
            disabled={loading || gridState.editMode}
            style={loading ? { pointerEvents: "none" } : {}}
            sx={{ fontWeight: 700 }}
          >
            Фильтры
          </Button>
        </div>
        {gridState.editMode && (
          <div>
            <Button
              startIcon={<SaveIcon />}
              disabled={loading}
              onClick={handleSaveClick}
              color="primary"
              sx={{ fontWeight: 700 }}
            >
              Сохранить
            </Button>
            <Button
              startIcon={<ClearIcon />}
              disabled={loading}
              onClick={handleCancelClick}
              color="primary"
              sx={{ fontWeight: 700 }}
            >
              Отменить
            </Button>
          </div>
        )}
      </GridToolbarContainer>
      <AlertDialog
        open={openDialog}
        setOpen={setOpenDialog}
        confirm={handleConfirmedRemove}
      />
    </>
  );
}

export default GridToolbar;
